<template>
<body class="style-simple button-stroke layout-full-width if-overlay if-border-hide no-content-padding no-shadows header-classic minimalist-header-no sticky-tb-color ab-hide subheader-both-center menu-line-below-80-1 menuo-sub-active menuo-no-borders menuo-right logo-valign-top logo-no-margin mobile-tb-hide mobile-side-slide mobile-mini-mr-ll mobile-tr-header tr-header tr-menu">
    <div id="Wrapper">
        <div id="Header_wrapper">
            <Header />
        </div>
        <!--Page Title-->
        <section class="page-title top-bg-img">
            <div class="auto-container clearfix">
                <h1>About Us</h1>
                <ul class="bread-crumb clearfix">
                    <li><router-link to="/">Home</router-link></li>
                    <li>About Us</li>
                </ul>
            </div>
        </section>
        <!--End Page Title-->

        <!-- About Us -->
        <section class="about-us">
            <div class="auto-container">
                <div class="row clearfix">
                    <!-- Content Column -->
                    <div class="content-column col-lg-6 col-md-12 col-sm-12">
                        <div class="inner-column">
                            <div class="sec-title">
                                <h2>About Us</h2>
                            </div>
                            <div class="about-years"><span class="years">35</span>
                                <h3>Years Of Experience</h3>
                            </div>
                            <div class="text-box" v-html="aboutData.pbody">

                            </div>

                        </div>
                    </div>

                    <div class="image-column col-lg-6 col-md-12 col-sm-12 hidden-sm">
                        <div class="inner-column">
                            <div class="video-box wow fadeIn animated" style="visibility: visible; animation-name: fadeIn;">
                                <figure class="image">
                                    <img :src="$root.Images_URL+'pages/'+aboutData.image" alt="">
                                </figure>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End About Us -->

        <!-- Call To Action -->
        <section class="call-to-action alternate call-action-bg-img" style="">
            <div class="auto-container">
                <div class="content-box">
                    <h2>Provide You The Highest Quality Work <br>That Meets Your Expectation.</h2>
                    <div class="link-box"><a href="contact.html" class="theme-btn btn-style-three"><span class="btn-title">Get a Quote</span><span></span> <span></span> <span></span>
                            <span></span>
                            <span></span></a></div>
                </div>

                <!-- Fact Counter -->
                <div class="fact-counter">
                    <div class="row clearfix">
                        <!--Column-->
                        <div class="counter-column col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                            <div class="inner-column">
                                <div class="count-box"><span class="count-text" data-speed="3000" data-stop="30">200</span>+
                                </div>
                                <h4 class="counter-title">Years of Carpenting <br>Experience</h4>
                            </div>
                        </div>

                        <!--Column-->
                        <!-- <div class="counter-column col-lg-3 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="300ms">
                            <div class="inner-column">
                                <div class="count-box"><span class="count-text" data-speed="3000" data-stop="58">350</span>+
                                </div>
                                <h4 class="counter-title">Local Branches in <br>City New York</h4>
                            </div>
                        </div> -->

                        <!--Column-->
                        <div class="counter-column col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="600ms">
                            <div class="inner-column">
                                <div class="count-box"><span class="count-text" data-speed="3000" data-stop="400">500+</span>
                                </div>
                                <h4 class="counter-title">Happy Customer <br>with our work</h4>
                            </div>
                        </div>

                        <!--Column-->
                        <div class="counter-column col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="900ms">
                            <div class="inner-column">
                                <div class="count-box"><span class="count-text" data-speed="3000" data-stop="80">300</span>+
                                </div>
                                <h4 class="counter-title">Best teams with <br>dedicat carpenter</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--End Call To Action -->

        <!--Fluid Section One-->
        <section class="fluid-section-one hidden-sm">
            <div class="outer-container clearfix">
                <!--Content Column-->
                <div class="content-column">
                    <div class="inner-column">
                        <div class="sec-title">
                            <h2>Why Choose Us?</h2>
                        </div>
                        <!-- <div class="text-box">
                            <p>Dolor sit amet consectetur elit sed do eiusmod tempor incd idunt labore et dolore magna
                                aliqua enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip
                                exea
                                commodo consequat.duis aute irure dolor in reprehen derit in voluptate velit esse cillum
                                dolore eu fugiat nulla pariatur cepteur sint occaecat.</p>
                        </div> -->
                        <div class="row">
                            <!-- Feature Block -->
                            <div class="feature-block-two col-lg-4 col-md-4">
                                <div class="inner-box">
                                    <span class="icon flaticon-24-hours"></span>
                                    <i class="fa fa-list-alt fnt-40 theme-clr" aria-hidden="true"></i>
                                    <h4>PROFFESSIONAL SERVICES</h4>
                                    
                                </div>
                            </div>

                            <!-- Feature Block -->
                            <div class="feature-block-two col-lg-4 col-md-4">
                                <div class="inner-box">
                                    <span class="icon flaticon-artificial-intelligence"></span>
                                    <i class="fa fa-thumbs-up fnt-40 theme-clr" aria-hidden="true"></i>
                                    <h4>QUALITY CHECK & ASSURANCE</h4>
                                    
                                </div>
                            </div>

                            <!-- Feature Block -->
                            <div class="feature-block-two col-lg-4 col-md-4">
                                <div class="inner-box">
                                    <span class="icon flaticon-handshake"></span>
                                    <i class="fa fa-truck fnt-40 theme-clr" aria-hidden="true"></i>
                                    <h4>PROMPT DELIVERY</h4>
                                    
                                </div>
                            </div>

                            <!-- Feature Block -->
                            
                        </div>
                    </div>
                </div>

                <!--Image Column-->
                <div class="image-column why-bg-img" style="">
                    <figure class="image-box"><img src="../assets/images/image-2.jpeg" alt=""></figure>
                </div>
            </div>
        </section>
        <!-- End Fluid Section -->

        <!-- Testimonial Section Two -->
        <section class="testimonial-section-two style-two">
            <div class="auto-container">
                <div class="sec-title text-center">
                    <h2>What Clients Says</h2>
                    <div class="text">You read our clients review <br>check this now</div>
                </div>

                <!-- Testimonial Carousel -->
                <div class="testimonial-carousel-two ">
                    <carousel :autoplay='true' :perPageCustom="[[400,1],[500, 1],[768, 1], [1024, 1]]">
                        <template v-for="(tdata,index) in testimonialData">
                            <slide :key="tdata.id">
                                <div class="testimonial-block-two">
                                    <div class="inner-box">
                                        <div class="info-box">
                                            <div class="thumb"><img :src="$root.Images_URL+'Content/'+tdata.image" alt=""></div>
                                            <h5 class="name">{{tdata.heading}}</h5>
                                            <span class="address hide">Los Angeles</span>
                                        </div>
                                        <div class="text" v-html="tdata.description"></div>
                                    </div>
                                </div>
                            </slide>
                        </template>
                    </carousel>        
                </div>
            </div>
        </section>
        <!--End Testimonial Section -->

        <!-- Call Back Section -->
        <section class="call-back-section contact-bg-img" style="">
            <div class="auto-container">
                <div class="outer-box">
                    <div class="row no-gutters">
                        <!-- Text Column -->
                        <div class="text-column col-xl-8 col-lg-12 col-md-12 col-sm-12 order-2">
                            <div class="inner-column">
                                <div class="inner">
                                    <div class="message"><strong>20 Years of Experience </strong><br> in Woodworks
                                        Business
                                        Services</div>
                                    <div class="text">Send us a email and we’ll get in touch shortly, or phone between
                                        9:00AM to 5:00PM Monday to Friday - We would be delighted to speak.</div>

                                    <div class="row">
                                        <!-- Info Block -->
                                        <div class="info-block col-lg-6 col-md-6 col-sm-12">
                                            <div class="inner-box">
                                                <div class="icon flaticon-phone"></div>
                                                <ul>
                                                    <li><strong>Phone</strong></li>
                                                    <li><a href="#">{{settingData.mobile}}</a></li>
                                                </ul>
                                            </div>
                                        </div>

                                        <!-- Info Block -->
                                        <div class="info-block col-lg-6 col-md-6 col-sm-12">
                                            <div class="inner-box">
                                                <div class="icon flaticon-email"></div>
                                                <ul>
                                                    <li><strong>Email</strong></li>
                                                    <li><a :href="'mailto:'+settingData.email">{{settingData.email}}</a></li>
                                                </ul>
                                            </div>
                                        </div>

                                        <!-- Info Block -->
                                        <div class="info-block col-lg-12 col-md-12 col-sm-12">
                                            <div class="inner-box">
                                                <div class="icon flaticon-worldwide"></div>
                                                <ul>
                                                    <li><strong>Location</strong></li>
                                                    <li>{{settingData.address}}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Form Column -->
                        <div class="form-column col-xl-4 col-lg-12 col-md-12 col-sm-12">
                            <div class="inner-column">
                                <div class="request-form">
                                    <h3>Request A Quote</h3>

                                    <!--Comment Form-->
                                        <div class="row">
                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <input type="text" name="username" v-model="name" placeholder="Name" required="">
                                                </div>

                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <input type="email" name="email" v-model="email" placeholder="Email" required="">
                                                </div>

                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <input type="text" name="phone" v-model="mobile" placeholder="Phone" required="">
                                                </div>

                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <textarea name="message" v-model="msg" placeholder="Message"></textarea>
                                                </div>

                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <button class="theme-btn btn-style-four" type="submit" @click="saveContact" name="submit-form">
                                                    <span class="btn-title">Submit Now</span>
                                                        <span></span> <span></span> <span></span> <span></span>
                                                        <span></span></button>
                                                </div>
                                            </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--End Call Back Section -->

        <!--Clients Section-->
        <section class="clients-section">
            <div class="auto-container">
                <!--Sponsors Carousel-->
                <ul class="sponsors-carousel">
                        <carousel :autoplay='true' :perPageCustom="[[400,1],[500, 1],[768, 4], [1024, 5]]">
                            
                            <slide>
                                <li>
                                    <figure class="image-box"><a href="#"><img src="../assets/images/Client/1.png" alt=""></a>
                                    </figure>
                                </li>
                            </slide>
                            <slide>    
                                <li>
                                    <figure class="image-box"><a href="#"><img src="../assets/images/Client/2.png" alt=""></a>
                                    </figure>
                                </li>
                            </slide>
                            <slide>    
                                <li>
                                    <figure class="image-box"><a href="#"><img src="../assets/images/Client/3.png" alt=""></a>
                                    </figure>
                                </li>
                            </slide>
                            <slide>    
                                <li>
                                    <figure class="image-box"><a href="#"><img src="../assets/images/Client/4.png" alt=""></a>
                                    </figure>
                                </li>
                            </slide>
                            <slide>    
                                <li>
                                    <figure class="image-box"><a href="#"><img src="../assets/images/Client/5.png" alt=""></a>
                                    </figure>
                                </li>
                            </slide>
                            <slide>    
                                <li>
                                    <figure class="image-box"><a href="#"><img src="../assets/images/Client/1.png" alt=""></a>
                                    </figure>
                                </li>
                            </slide>
                            <slide>    
                                <li>
                                    <figure class="image-box"><a href="#"><img src="../assets/images/Client/2.png" alt=""></a>
                                    </figure>
                                </li>
                            </slide>
                            <slide>    
                                <li>
                                    <figure class="image-box"><a href="#"><img src="../assets/images/Client/3.png" alt=""></a>
                                    </figure>
                                </li>
                            </slide>
                            <slide>    
                                <li>
                                    <figure class="image-box"><a href="#"><img src="../assets/images/Client/4.png" alt=""></a>
                                    </figure>
                                </li>
                            </slide>
                            <slide>    
                                <li>
                                    <figure class="image-box"><a href="#"><img src="../assets/images/Client/5.png" alt=""></a>
                                    </figure>
                                </li>
                            </slide>
                        </carousel>

                </ul>
            </div>
        </section>
        <!--End Clients Section-->

        <!-- Main Footer -->
        <Footer />
        <!-- End Main Footer -->
    </div>

</body>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header_3.vue";
import Footer from "@/components/Footer.vue";
import axios from 'axios';

import {
    Carousel,
    Slide
} from 'vue-carousel';

export default {
    name: "AboutUsView",
    components: {
        Header,
        Footer,
        Carousel,
        Slide
    },
    data() {
        return {
            aboutData: [],
            testimonialData:[],
            settingData:[],
            name:null,
            email:null,
            mobile:null,
            msg:null,
        }
    },
    mounted() {
        this.getAbout();
        this.getTestimonial();
        setTimeout(() => {
            window.scrollTo(0,0);
           
        }, 1000);
        this.getSetting();
        
    },
    methods: {
        getAbout() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getAboutus',
                }
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;
                ev.aboutData = data;

            })

        },
        getTestimonial() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'testimonial',
                }
            }).then(function (response) {
                console.log(response.data);
                var data = response.data;
                ev.testimonialData = data.data;

            })

        },
        getSetting(){
            var ev=this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT+'api.php',
                data: {
                    type: 'site_setting',
                }
            }).then(function (response){
                console.log(response.data);
                var data=response.data;
              
                    ev.settingData=data
                    console.log(ev.settingData);
                    
                
            })
        },
    },
    saveContact(){
            var ev=this;
            if(this.name==null || this.name==''){
                ev.$toasted.global.error({
                        message: 'PLease enter your name'
                });
            }else if(this.email==null || this.email==''){
                ev.$toasted.global.error({
                        message: 'PLease enter your email'
                });
            }else if(this.mobile==null || this.mobile==''){
                ev.$toasted.global.error({
                        message: 'PLease enter your mobile no.'
                });
            }else if(this.msg==null || this.msg==''){
                ev.$toasted.global.error({
                        message: 'PLease enter message'
                });
            }else{
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT+'api.php',
                    data: {
                        type: 'contact_submit',
                        name: this.name,
                        email: this.email,
                        mobile: this.mobile,
                        msg: this.msg,
                    }
                }).then(function (response){
                    console.log(response.data);
                    var data=response.data;
                    if(data.status=='success'){
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.name=null;
                        ev.email=null;
                        ev.mobile=null;
                        ev.msg=null;
                    }else{
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }
                })
            }    
        },

};
</script>
