<template>

    <body
        class="style-simple button-stroke layout-full-width if-overlay if-border-hide no-content-padding no-shadows header-classic minimalist-header-no sticky-tb-color ab-hide subheader-both-center menu-line-below-80-1 menuo-sub-active menuo-no-borders menuo-right logo-valign-top logo-no-margin mobile-tb-hide mobile-side-slide mobile-mini-mr-ll mobile-tr-header tr-header tr-menu">
        <div id="Wrapper">
            <div id="Header_wrapper">
                <Header3 />
            </div>

            <section class="whites-bg mt-5">
                <div class="container">
                    <div class="row">
                        <div class="product-range-left col-sm-3">

                            <div class="input-group custom-search-form">
                                <input type="text" class="form-control search-input" placeholder="Search...">
                                <span class="input-group-btn">
                                    <button class="btn btn-default search-btn" type="button">
                                        <span class="fa fa-search"></span>
                                    </button>
                                </span>
                            </div>
                            <!-- /input-group -->

                            <div class="product-range-left-wrap">

                                <div class="category">
                                    <h3>Categories </h3>
                                    <div class="cate-accordian">
                                        <ul>
                                            <template v-for="(sdata, index) in subCategoryData">
                                                <li :key="index">
                                                    <label data="6" class="">
                                                        <span>
                                                            <router-link :to="'/category/' + sdata.id">{{ sdata.heading }}
                                                            </router-link>
                                                        </span>
                                                    </label>
                                                </li>
                                            </template>

                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div class="slide-left-menu"></div>

                            <div class="slide-left-menu"></div>
                            <div class="product-range-left-wrap" style="display: none">

                                <div class="category">
                                    <h3>Popular Products <span><img
                                                src="https://www.centuryply.com/eshop/images/cate-icon.png"></span></h3>
                                    <div class="cate-accordian">
                                        <div class="list-product">
                                            <div class="list-p-img">
                                                <img src="https://www.centuryply.com/eshop/image/1171566211-1651792580-Club-Prime-Plywood.jpg"
                                                    width="100" />
                                            </div>
                                            <div class="list-p-content">
                                                <h3>PRODUCT NAME</h3>
                                                <p> <i class="fa fa-inr" aria-hidden="true"></i> 1854/-
                                                    <del>2000/-</del><span>30%</span> </p>
                                                <span>TYPE-PLYWOOD</span>
                                            </div>
                                        </div>

                                        <div class="list-product">
                                            <div class="list-p-img">
                                                <img src="https://www.centuryply.com/eshop/image/1171566211-1651792580-Club-Prime-Plywood.jpg"
                                                    width="100" />
                                            </div>
                                            <div class="list-p-content">
                                                <h3>PRODUCT NAME</h3>
                                                <p> <i class="fa fa-inr" aria-hidden="true"></i> 1854/-
                                                    <del>2000/-</del><span>30%</span> </p>
                                                <span>TYPE-PLYWOOD</span>
                                            </div>
                                        </div>

                                        <div class="list-product">
                                            <div class="list-p-img">
                                                <img src="https://www.centuryply.com/eshop/image/1171566211-1651792580-Club-Prime-Plywood.jpg"
                                                    width="100" />
                                            </div>
                                            <div class="list-p-content">
                                                <h3>PRODUCT NAME</h3>
                                                <p> <i class="fa fa-inr" aria-hidden="true"></i> 1854/-
                                                    <del>2000/-</del><span>30%</span> </p>
                                                <span>TYPE-PLYWOOD</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="slide-left-menu"></div>
                        </div>
                        <div class="col-sm-9 product-range-right product-range-list">
                            <span id="posts_content">

                                <ul id="products-listing" class="row listings" v-if="checkData">
                                    <template v-for="(pdata, index) in productData">
                                        <li class="col-sm-4" :key="pdata.id">
                                            <div class="theme-border m-text-center bg-white">
                                                <div class="pro-img flip-box">

                                                    <router-link :to="'/productdetails/' + pdata.id">
                                                        <div class="flip-box-inner">
                                                            <div class="flip-box-front">
                                                                <img :src="$root.Product + '/' + pdata.front_image"
                                                                    class="change-hover-img" :alt="pdata.name"
                                                                    :title="pdata.name">
                                                            </div>
                                                            <div class="flip-box-back">
                                                                <img :src="$root.Product + '/' + pdata.front_image"
                                                                    class="change-hover-img" :alt="pdata.name"
                                                                    :title="pdata.name">
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </div>

                                                <div class="prod-code text-center">
                                                    <h3>
                                                        <router-link :to="'/productdetails/' + pdata.id">{{ pdata.name }}
                                                        </router-link>
                                                    </h3>

                                                </div>
                                                <input type="hidden" :class="'cart_product_id' + pdata.id"
                                                    :value="pdata.id">
                                                <input type="hidden" class="productrangedesc2" value="3874">
                                                <div class="prod-name text-center">
                                                    <a>{{ pdata.category_name }}
                                                    </a>
                                                </div>
                                                <div class="size-thik hidden-sm">
                                                    <!--<p>Select : {{pdata.size?'Size ':''}} {{pdata.thickness?', Thickness ':''}}

                                                    <select name="size" id="" class="size " tabindex="-1" @change="changeVariant(pdata.id,$event,index)">
                                                        <option value="" productdataid="2">Select {{pdata.size?'Size ':''}} {{pdata.thickness?', Thickness ':''}}
                                                        </option>
                                                        <template v-for="vdata in pdata.variants">

                                                            <option :value="vdata.id" v-if="vdata.size==pdata.size && vdata.thickness==pdata.thickness" selected>{{vdata.size?'size : '+vdata.size:''}}{{vdata.thickness?', Thickness : '+vdata.thickness:''}}</option>
                                                            <option :value="vdata.id" v-else>{{vdata.size?'size : '+vdata.size:''}}{{vdata.thickness?', Thickness : '+vdata.thickness:''}}</option>

                                                        </template>
                                                    </select>
                                                </p>---->


                                                    <p>Select : Thickness

                                                        <select name="size" :id="'thickness' + index" class="size "
                                                            tabindex="-1"
                                                            @change="changeVariant(pdata.id, $event, index, 'thickness')">
                                                            <option value="">Select Thickness </option>
                                                            <template v-for="sdata in pdata.thicknesslist">

                                                                <option :value="sdata.thickness"
                                                                    v-if="sdata.thickness == pdata.thickness" selected>
                                                                    {{ sdata.thickness }}</option>
                                                                <option :value="sdata.thickness" v-else>
                                                                    {{ sdata.thickness }}</option>

                                                            </template>
                                                        </select>
                                                    </p>

                                                    <p>Select : Size

                                                        <select name="size" :id="'size' + index" class="size "
                                                            tabindex="-1"
                                                            @change="changeVariant(pdata.id, $event, index, 'size')">
                                                            <option value="">Select Size </option>
                                                            <template v-for="sdata in pdata.sizelist">

                                                                <option :value="sdata.size"
                                                                    v-if="sdata.size == pdata.size" selected>
                                                                    {{ sdata.size }}</option>
                                                                <option :value="sdata.size" v-else>{{ sdata.size }}
                                                                </option>

                                                            </template>
                                                        </select>
                                                    </p>



                                                    <div class="select-error-size errorsize2" style="display:none;">
                                                        Please select size</div>

                                                </div>

                                                <div id="pr2" class="">

                                                    <div class="price-div row">
                                                        <div class="col-6" style="padding:unset;">
                                                         <div class="cut-price">
                                                                <i class="fa fa-inr"
                                                                    aria-hidden="true"></i>{{ pdata.purchese_cost }}
                                                            </div><br/>
                                                            <div class="price priceid2">
                                                                <i class=" fa fa-inr"
                                                                    aria-hidden="true"></i>{{ pdata.selling_cost }}
                                                            </div><br />
                                                             <span style="font-size:12px;">incl. GST</span>
                                                             <br />
                                                           
                                                            <span>&nbsp; <i class=" fa fa-inr"
                                                                    aria-hidden="true"></i>&nbsp;{{getWithoutGst(pdata.selling_cost)}}</span><br />

                                                             <p style="font-size:12px;margin-top:-5px;">&nbsp; excl. GST</p>
                                                             <br />
                                                        </div>

                                                        <div class="col-6" style="padding:unset;">
                                                            <div class="price priceid2">
                                                                <i class=" fa fa-inr"
                                                                    aria-hidden="true"></i>{{ pdata.per_sq_ft }} / Sq.ft
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div class="prod-price">
                                                        <div class="sav-per">you save: <span> 0(0%
                                                                Off)</span></div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-6 col-6 text-center hover hover-clr"
                                                            style="border-right: 2px solid #ffd7bb;">
                                                            <div class="add-com-p"
                                                                @click="add_to_cart(pdata.id, pdata.name, 1, pdata.selling_cost)">
                                                                <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 col-6 text-center hover hover-clr">
                                                            <div class="add-com-p" @click="whishList(pdata.id)">
                                                                <i class="fa fa-heart" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="flex justify-space">
                                                    <div class="add-com-p" @click="add_to_cart(pdata.id, pdata.name, 1, pdata.selling_cost)">
                                                        <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                                    </div>

                                                    <div class="add-com-p" @click="whishList(pdata.id)" ><i class="fa fa-heart" aria-hidden="true"></i>
                                                    </div>
                                                    <div class="add-com-p">
                                                        <i class="fa fa-eye" aria-hidden="true"></i>
                                                    </div> 
                                                </div> -->
                                                </div>
                                            </div>
                                        </li>
                                    </template>

                                </ul>

                                <div class="not-found" v-else>
                                    <h3>Product Not Found</h3>
                                </div>

                            </span>
                            <ul id=" pagination  " style="display: none">
                                <li><a class=" " href=" # "><i class=" fa fa-arrow-left " aria-hidden=" true "></i></a>
                                </li>
                                <li><a href=" # ">1</a></li>
                                <li><a href=" # " class=" active ">2</a></li>
                                <li><a href=" # ">3</a></li>
                                <li><a href=" # ">4</a></li>
                                <li><a href=" # ">5</a></li>
                                <li><a href=" # ">6</a></li>
                                <li><a href=" # ">7</a></li>
                                <li><a href=" # "><i class=" fa fa-arrow-right " aria-hidden=" true "></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class=" clear "></div>

                </div>

            </section>

            <!-- Main Footer -->
            <Footer />
            <!-- End Main Footer -->
        </div>

    </body>
</template>

<script>
// @ is an alias to /src
import Header3 from '@/components/Header_3.vue'
import Footer from '@/components/Footer.vue'
import axios from 'axios'

import {
    Carousel,
    Slide
} from 'vue-carousel';

export default {
    name: 'CategoryView',
    components: {
        Header3,
        Footer,
        Carousel,
        Slide
    },
    data() {
        return {
            productData: [],
            subCategoryData: [],
            pid: null,
            checkData: false,
            checkVariant: false,
        }
    },
    mounted() {
        this.getSubCategory();
        this.getProducts();

        this.getVariant();
        setTimeout(() => {
            window.scrollTo(0,0);
           
        }, 1000);
        


    },
    watch: {
        productData: function (vl) {
            this.productData = vl;

        },
        subCategoryData: function (vl) {
            this.subCategoryData = vl;
        },
        '$route.params.cid': function (vl) {
            this.getSubCategory();
            this.getProducts();

            this.getVariant();
        }
    },
    methods: {
        getWithoutGst(oc){
         var amt=( oc * (100/(100+18)));
         return amt.toFixed(2);
        },
        getProducts() {
            var ev = this;

            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getCategoryProduct',
                    cid: this.$route.params.cid,

                }
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;
                if (data.count > 0) {
                    ev.checkData = true;
                } else {
                    ev.checkData = false;
                }
                if (data.status == "success") {

                    ev.productData = data.data

                } else {
                    ev.$toasted.global.error({
                        message: data.msg
                    });
                }

            })

        },
        getSubCategory() {
            var ev = this;

            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getSubCategory',
                    category_id: ev.$route.params.cid,
                }
            }).then(function (response) {
                console.log(response.data);
                var data = response.data;

                if (data.status == "success") {

                    ev.subCategoryData = data.subcategory

                } else {
                    ev.$toasted.global.error({
                        message: data.msg
                    });
                }

            })

        },
        getVariant() {
            var ev = this;

            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getVariantProduct',
                    pid: this.pid,

                }
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;

                if (data.status == "success") {

                    //ev.subCategoryData = data.data

                } else {
                    ev.$toasted.global.error({
                        message: data.msg
                    });
                }

            })
        },
        changeVariant(pId, updateId, index, vname) {
            var ev = this;
            var vid = updateId.target.value;

            if (vname == 'size') {
                var size = updateId.target.value;
                var thickness = document.getElementById("thickness" + index).value
            } else {
                var size = document.getElementById("size" + index).value;
                var thickness = updateId.target.value;
            }

            //console.log(size+'--->'+thickness);

            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getVariantSingle',
                    vid: vid,
                    size: size,
                    thickness: thickness,
                    pid: pId,
                    vname: vname,

                }
            }).then(function (response) {
                console.log(response.data);
                var data = response.data;
                var newAry = [];
                if (data.status == "success") {
                    ev.checkData = false;
                    ev.productData[index] = data.data;
                    ev.checkData = true;

                } else {
                    ev.$toasted.global.error({
                        message: 'PLease Select Size'
                    });
                }

            })

        },

    }
}
</script>

<style scoped>
    .product-range-list li {
        width: 295px;
    }
    .product-range-list{
        text-align: center;
    }
</style>
