<template>

    <body
        class="style-simple button-stroke layout-full-width if-overlay if-border-hide no-content-padding no-shadows header-classic minimalist-header-no sticky-tb-color ab-hide subheader-both-center menu-line-below-80-1 menuo-sub-active menuo-no-borders menuo-right logo-valign-top logo-no-margin mobile-tb-hide mobile-side-slide mobile-mini-mr-ll mobile-tr-header tr-header tr-menu">
        <div class="frame" v-if="loader">
            <div class="center">
                <div class="loader">
                    <div class="circle1">
                        <div class="circle2">
                            <div class="circle3"></div>
                        </div>
                    </div>


                </div>
            </div>
        </div>

        <div id="Wrapper">
            <div id="Header_wrapper">
                <Header />
            </div>
            <!--Page Title-->
            <section class="page-title top-bg-img">
                <div class="auto-container clearfix">
                    <h1>Plywood Requirement</h1>
                    <ul class="bread-crumb clearfix">
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>Calculator</li>
                    </ul>
                </div>
            </section>
            <!--End Page Title-->

            <!-- About Us -->
            <section class="about-us">
                <div class="auto-container">
                    <div class="row clearfix">
                        <!-- Content Column -->
                        <div class="col-lg-12  col-md-12 col-sm-12">
                            <div class="inner-column">
                                <div class="sec-title">
                                    <h2>HOW MUCH PLYWOOD WOULD YOU NEED?</h2>
                                </div>
                                <div class="about-years">
                                    <!-- <span class="years">35</span> -->
                                    <h3>Calculate on your own.</h3>
                                </div>

                                <div class="row mb-botttom-40">
                                    <div class="col-md-6">
                                        <button :class="btnStatus=='standard'?' btn-mk btn-active':' btn-mk'" @click="activeBtn('standard')" >Standard Calculator</button>
                                    </div>

                                     <div class="col-md-6">
                                        <button  :class="btnStatus=='carpenter'?' btn-mk btn-active':' btn-mk'" @click="activeBtn('carpenter')">Carpenter Calculator</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="image-column col-lg-6  col-md-12 col-sm-12 " v-if="btnStatus=='standard'">
                            <div class="">
                                <div class="text-box">
                                    <div class="request-form">
                                    <h3>Standard Calculator</h3>
                                        <form method="post" action="#">
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <label style="color:#fff;">Furniture Type *</label>
                                                    <select type="text" name="username" v-model="furnitureType"
                                                        @change="getVarientType" placeholder="Name" required="">
                                                        <option value="">Select Furniture</option>
                                                        <option value="1">TV CABINET</option>
                                                        <option value="2">Wardrobes</option>
                                                        <option value="3">Kitchen Cabinet Solution</option>
                                                        <option value="4">Beds</option>
                                                        <option value="5">Dressing Table</option>
                                                    </select>
                                                    <span v-if="errorMsg.furniture!=''" class="errorMsg">
                                                        {{errorMsg.furniture}}
                                                    </span>
                                                </div>

                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <label style="color:#fff;">Furniture Variant*</label>
                                                    <select type="text" name="username" v-model="varientType"
                                                        placeholder="Name" required="">
                                                        <option value="">Select Variant</option>
                                                        <option :value="varient.id" v-for="(varient, key) in varientAry"
                                                            :key="key"> {{ varient.name }} </option>
                                                    </select>
                                                    <span v-if="errorMsg.varient!=''" class="errorMsg">
                                                        {{errorMsg.varient}}
                                                    </span>
                                                </div>

                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <label style="color:#fff;">Category*</label>
                                                    <select type="text" name="username" v-model="categoryType"
                                                        @change="getSubCategory" placeholder="Name" required="">
                                                        <option value="">Select Category</option>
                                                        <option :value="cat.id" v-for="(cat, key) in categoryList"
                                                            :key="key"> {{ cat.heading }} </option>
                                                    </select>
                                                    <span v-if="errorMsg.category!=''" class="errorMsg">
                                                        {{errorMsg.category}}
                                                    </span>
                                                </div>

                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group"
                                                    v-if="categoryType != ''">
                                                    <label style="color:#fff;">Sub Category*</label>
                                                    <select type="text" name="username" v-model="subCategoryType"
                                                        @change="getProduct" placeholder="Name" required="">
                                                        <option value="">Select Sub Category</option>
                                                        <option :value="cat.id" v-for="(cat, key) in subCategoryList"
                                                            :key="key"> {{ cat.heading }} </option>
                                                    </select>
                                                    <span v-if="errorMsg.subcategory!=''" class="errorMsg">
                                                        {{errorMsg.subcategory}}
                                                    </span>
                                                </div>


                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group"
                                                    v-if="subCategoryType != ''">
                                                    <label style="color:#fff;">Product*</label>
                                                    <select type="text" name="username" v-model="productId"
                                                        placeholder="Name" required="">
                                                        <option value="">Select Product</option>
                                                        <option :value="pro.id" v-for="(pro, key) in productList"
                                                            :key="key"> {{ pro.name }} </option>
                                                    </select>
                                                    <span v-if="errorMsg.product!=''" class="errorMsg">
                                                        {{errorMsg.product}}
                                                    </span>
                                                </div>


                                                <div class="col-lg-4 offset-lg-4 col-md-6 col-sm-12 form-group">
                                                    <button class="theme-btn btn-style-four" type="button"
                                                        @click="calculate(varientType)" name="submit-form"><span
                                                            class="btn-title">Calculate</span>
                                                        <span></span> <span></span> <span></span> <span></span>
                                                        <span></span></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="image-column col-lg-6  col-md-12 col-sm-12 " v-if="btnStatus=='carpenter'">
                            <div class="">
                                <div class="text-box">
                                    <div class="request-form">
                                    <h3>Carpenter Calculator</h3>
                                        <form method="post" action="#">
                                            <div class="row">
                                                

                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <label style="color:#fff;">Category*</label>
                                                    <select type="text" name="username" v-model="categoryType2"
                                                        @change="getSubCategory2" placeholder="Name" required="">
                                                        <option value="">Select Category</option>
                                                        <option :value="cat.id" v-for="(cat, key) in categoryList2"
                                                            :key="key"> {{ cat.heading }} </option>
                                                    </select>
                                                    <span v-if="errorMsg.category!=''" class="errorMsg">
                                                        {{errorMsg.category}}
                                                    </span>
                                                </div>

                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group"
                                                    v-if="categoryType2 != ''">
                                                    <label style="color:#fff;">Sub Category*</label>
                                                    <select type="text" name="username" v-model="subCategoryType2"
                                                        @change="getProduct2" placeholder="Name" required="">
                                                        <option value="">Select Sub Category</option>
                                                        <option :value="cat.id" v-for="(cat, key) in subCategoryList2"
                                                            :key="key"> {{ cat.heading }} </option>
                                                    </select>
                                                    <span v-if="errorMsg.subcategory!=''" class="errorMsg">
                                                        {{errorMsg.subcategory}}
                                                    </span>
                                                </div>


                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group"
                                                    v-if="subCategoryType2 != ''">
                                                    <label style="color:#fff;">Product*</label>
                                                    <select type="text" name="username" v-model="productId2"
                                                        placeholder="Name" required="">
                                                        <option value="">Select Product</option>
                                                        <option :value="pro.id" v-for="(pro, key) in productList2"
                                                            :key="key"> {{ pro.name }} </option>
                                                    </select>
                                                    <span v-if="errorMsg.product!=''" class="errorMsg">
                                                        {{errorMsg.product}}
                                                    </span>
                                                </div>

                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group" v-if="productId2!=''">
                                                    <label style="color:#fff;">Thickness* (6MM)</label>
                                                    <!-- <input class="form-control" type="text" name="thickness" v-model="thickness" /> -->
                                                     <select type="text" name="username" v-model="thickness"
                                                        placeholder="Name" required="">
                                                        <option value="">Select Thickness</option>
                                                        <option value="6MM"> 6MM </option>
                                                        <option value="8MM"> 8MM </option>
                                                        <option value="6MM"> 12MM </option>
                                                        <option value="18/19MM"> 18/19MM </option>
                                                    </select>
                                                </div>

                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group" v-if="thickness!=''">
                                                    <label style="color:#fff;">Size* (8X4)</label>
                                                    <!-- <input class="form-control" type="text" name="size" v-model="size" /> -->
                                                    <select type="text" name="username" v-model="size"
                                                        placeholder="Name" required="">
                                                        <option value="">Select Size</option>
                                                        <option value="7x4"> 7x4 </option>
                                                        <option value="8x4"> 8x4 </option>
                                                        
                                                    </select>
                                                </div>

                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group" v-if="size!=''">
                                                    <label style="color:#fff;">Quantity*</label>
                                                    <input class="form-control" type="number" name="qty" v-model="qty"/>
                                                </div>
                                                


                                                <div class="col-lg-4 offset-lg-4 col-md-6 col-sm-12 form-group">
                                                    <button class="theme-btn btn-style-four" type="button"
                                                        @click="getCarpentryData()" name="submit-form"><span
                                                            class="btn-title">Calculate</span>
                                                        <span></span> <span></span> <span></span> <span></span>
                                                        <span></span></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-sm-12" v-if="resultStatus && btnStatus=='carpenter'">
                            <div class="content shadow">
                                <p class="head" id="headtitle">
                                    <strong>{{carpenterData.name}}</strong>
                                </p>
                                <table class="table-result" cellpadding="0" cellspacing="0" width="100%" id="tabledata">
                                    <thead>
                                        <tr>
                                            <th class="master-heading" colspan="5">Plywood</th>
                                        </tr>
                                        <tr class="hide-mobile">
                                            <th class="column-2">Thickness</th>
                                            <th class="column-3">Quantity</th>
                                            <th class="column-4">Dimension</th>
                                            <th class="colum-5">Per Qty Price</th>
                                            <th class="colum-6">Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-label="Thickness">{{carpenterData.thickness}}</td>

                                            <td data-label="Qty">{{qty}}</td>

                                            <td data-label="Length">{{carpenterData.size}}</td>

                                            <td>₹ {{ carpenterData.per_sq_ft }}</td>

                                            <td>₹ {{ getTotalCostCarpent() }}</td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div>    
                        </div>


                      



                        <div class="col-lg-6 col-md-12 col-sm-12" v-if="varientTypes == 1 && btnStatus=='standard'">
                            <div class="content shadow">
                                <p class="head" id="headtitle">
                                    <strong>TV CABINET 8FT HEIGHT X 3FT WIDTH (243.84cm x 91.44cm)</strong>
                                </p>
                                <table class="table-result" cellpadding="0" cellspacing="0" width="100%" id="tabledata">
                                    <thead>
                                        <tr>
                                            <th class="master-heading" colspan="5">Plywood</th>
                                        </tr>
                                        <tr class="hide-mobile">
                                            <th class="column-2">Thickness</th>
                                            <th class="column-3">Quantity</th>
                                            <th class="column-4">Dimension</th>
                                            <th class="colum-5">Per Qty Price</th>
                                            <th class="colum-6">Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-label="Thickness">19mm</td>

                                            <td data-label="Qty">2</td>

                                            <td data-label="Length">8ft x 3ft</td>

                                            <td>{{ getPerQtyPrice1('18/19MM') }}₹ {{ per1 }}</td>

                                            <td>₹ {{ getTotalCost1(8, 3, 2) }}</td>
                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">12mm</td>

                                            <td data-label="Qty">3</td>

                                            <td data-label="Length">8ft x 3ft</td>

                                            <td>{{ getPerQtyPrice2('12MM') }}₹ {{ per2 }}</td>

                                            <td>₹ {{ getTotalCost2(8, 3, 3) }}</td>

                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">6mm</td>

                                            <td data-label="Qty">2</td>

                                            <td data-label="Length">8ft x 3ft</td>

                                            <td>{{ getPerQtyPrice3('6MM') }}₹ {{ per3 }}</td>

                                            <td>₹ {{ getTotalCost3(8, 3, 2) }}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12" v-if="varientTypes == 2 && btnStatus=='standard'">
                            <div class="content shadow">
                                <p class="head" id="headtitle">
                                    <strong>WARDROBE 8 FT HEIGHT X 4FT WIDTH (243.84cm x 121.92cm)</strong>
                                </p>
                                <table class="table-result" cellpadding="0" cellspacing="0" width="100%" id="tabledata">
                                    <thead>
                                        <tr>
                                            <th class="master-heading" colspan="5">Plywood</th>
                                        </tr>
                                        <tr class="hide-mobile">
                                            <th class="column-4">Thickness</th>

                                            <th class="column-3">Quantity</th>

                                            <th class="column-2">Dimension</th>

                                            <th class="colum-5">Per Qty Price</th>
                                            <th class="colum-6">Total Price</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-label="Thickness">19mm</td>
                                            <td data-label="Qty">5</td>
                                            <td data-label="Length">8ft x 4ft</td>
                                            <td>{{ getPerQtyPrice1('18/19MM') }}₹ {{ per1 }}</td>

                                            <td>₹ {{ getTotalCost1(8, 4, 5) }}</td>

                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">12mm</td>
                                            <td data-label="Qty">1</td>
                                            <td data-label="Length">8ft x 4ft</td>
                                            <td>{{ getPerQtyPrice2('12MM') }}₹ {{ per2 }}</td>

                                            <td>₹ {{ getTotalCost2(8, 4, 1) }}</td>

                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">8mm</td>
                                            <td data-label="Qty">1</td>

                                            <td data-label="Length">8ft x 4ft</td>

                                            <td>{{ getPerQtyPrice3('8MM') }}₹ {{ per3 }}</td>

                                            <td>₹ {{ getTotalCost3(8, 4, 1) }}</td>
                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">6mm</td>

                                            <td data-label="Qty">1</td>
                                            <td data-label="Length">8ft x 4ft</td>

                                            <td>{{ getPerQtyPrice4('6MM') }}₹ {{ per4 }}</td>

                                            <td>₹ {{ getTotalCost4(8, 4, 1) }}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!--  -->
                        <div class="col-lg-6 col-md-12 col-sm-12" v-if="varientTypes == 3 && btnStatus=='standard'">
                            <div class="content">
                                <p class="head" id="headtitle"><strong>WWARDROBE 8 FT HEIGHT X 8FT WIDTH (243.84cm x
                                        243.84cm)</strong></p>
                                <table class="table-result" cellpadding="0" cellspacing="0" width="100%" id="tabledata">
                                    <thead>
                                        <tr>
                                            <th class="master-heading" colspan="5">Plywood</th>
                                        </tr>
                                        <tr class="hide-mobile">
                                            <th class="column-4">Thickness</th>
                                            <th class="column-3">Quantity</th>

                                            <th class="column-2">Dimension</th>
                                            <th class="colum-5">Per Qty Price</th>
                                            <th class="colum-6">Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-label="Thickness">19mm</td>
                                            <td data-label="Qty">10</td>

                                            <td data-label="Length">8ft x 8ft</td>

                                            <td>{{ getPerQtyPrice1('18/19MM') }}₹ {{ per1 }}</td>

                                            <td>₹ {{ getTotalCost1(8, 8, 10) }}</td>
                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">12mm</td>
                                            <td data-label="Qty">2</td>

                                            <td data-label="Length">8ft x 8ft</td>

                                            <td>{{ getPerQtyPrice2('12MM') }}₹ {{ per2 }}</td>

                                            <td>₹ {{ getTotalCost2(8, 8, 2) }}</td>

                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">8mm</td>
                                            <td data-label="Qty">2</td>

                                            <td data-label="Length">8ft x 8ft</td>

                                            <td>{{ getPerQtyPrice3('8MM') }}₹ {{ per3 }}</td>

                                            <td>₹ {{ getTotalCost3(8, 8, 2) }}</td>
                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">6mm</td>
                                            <td data-label="Qty">2</td>

                                            <td data-label="Length">8ft x 8ft</td>

                                            <td>{{ getPerQtyPrice4('6MM') }}₹ {{ per4 }}</td>

                                            <td>₹ {{ getTotalCost4(8, 8, 2) }}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!--  -->
                        <!--  -->
                        <div class="col-lg-6 col-md-12 col-sm-12" v-if="varientTypes == 4 && btnStatus=='standard'">
                            <div class="content">
                                <p class="head" id="headtitle"><strong>WARDROBE 10 FT HEIGHT X 4FT WIDTH (304.8cm x
                                        121.92cm)</strong></p>
                                <table class="table-result" cellpadding="0" cellspacing="0" width="100%" id="tabledata">
                                    <thead>
                                        <tr>
                                            <th class="master-heading" colspan="5">Plywood</th>
                                        </tr>
                                        <tr class="hide-mobile">
                                            <th class="column-4">Thickness</th>
                                            <th class="column-3">Quantity</th>

                                            <th class="column-2">Dimension</th>
                                            <th class="colum-5">Per Qty Price</th>
                                            <th class="colum-6">Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-label="Thickness">19mm</td>
                                            <td data-label="Qty">8</td>

                                            <td data-label="Length">10ft x 4ft</td>

                                            <td>{{ getPerQtyPrice1('18/19MM') }}₹ {{ per1 }}</td>

                                            <td>₹ {{ getTotalCost1(10, 4, 8) }}</td>

                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">12mm</td>
                                            <td data-label="Qty">3</td>

                                            <td data-label="Length">10ft x 4ft</td>

                                            <td>{{ getPerQtyPrice2('12MM') }}₹ {{ per2 }}</td>

                                            <td>₹ {{ getTotalCost2(10, 4, 3) }}</td>
                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">8mm</td>
                                            <td data-label="Qty">2</td>

                                            <td data-label="Length">10ft x 4ft</td>

                                            <td>{{ getPerQtyPrice3('8MM') }}₹ {{ per3 }}</td>

                                            <td>₹ {{ getTotalCost3(10, 4, 2) }}</td>
                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">6mm</td>
                                            <td data-label="Qty">4</td>
                                            <td data-label="Length">10ft x 4ft</td>

                                            <td>{{ getPerQtyPrice4('6MM') }}₹ {{ per4 }}</td>

                                            <td>₹ {{ getTotalCost4(10, 4, 4) }}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!--  -->
                        <!--  -->
                        <div class="col-lg-6 col-md-12 col-sm-12" v-if="varientTypes == 5 && btnStatus=='standard'">
                            <div class="content">
                                <p class="head" id="headtitle"><strong>WARDROBE 10 FT HEIGHT X 8FT WIDTH (304.8cm x
                                        243.84cm)</strong></p>
                                <table class="table-result" cellpadding="0" cellspacing="0" width="100%" id="tabledata">
                                    <thead>
                                        <tr>
                                            <th class="master-heading" colspan="5">Plywood</th>
                                        </tr>
                                        <tr class="hide-mobile">
                                            <th class="column-4">Thickness</th>
                                            <th class="column-3">Quantity</th>
                                            <th class="column-2">Dimension</th>
                                            <th class="colum-5">Per Qty Price</th>
                                            <th class="colum-6">Total Price</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-label="Thickness">19mm</td>
                                            <td data-label="Qty">12</td>
                                            <td data-label="Length">10ft x 8ft</td>

                                            <td>{{ getPerQtyPrice1('18/19MM') }}₹ {{ per1 }}</td>

                                            <td>₹ {{ getTotalCost1(10, 8, 12) }}</td>

                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">12mm</td>
                                            <td data-label="Qty">4</td>
                                            <td data-label="Length">10ft x 8ft</td>

                                            <td>{{ getPerQtyPrice2('12MM') }}₹ {{ per2 }}</td>

                                            <td>₹ {{ getTotalCost2(10, 8, 4) }}</td>

                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">8mm</td>
                                            <td data-label="Qty">4</td>
                                            <td data-label="Length">10ft x 8ft</td>

                                            <td>{{ getPerQtyPrice3('8MM') }}₹ {{ per3 }}</td>

                                            <td>₹ {{ getTotalCost3(10, 8, 4) }}</td>

                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">6mm</td>
                                            <td data-label="Qty">4</td>
                                            <td data-label="Length">10ft x 8ft</td>

                                            <td>{{ getPerQtyPrice4('6MM') }}₹ {{ per4 }}</td>

                                            <td>₹ {{ getTotalCost4(10, 8, 4) }}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!--  -->
                        <!--  -->
                        <div class="col-lg-6 col-md-12 col-sm-12" v-if="varientTypes == 6 && btnStatus=='standard'">
                            <div class="content">
                                <p class="head" id="headtitle"><strong>WARDROBE 10 FT HEIGHT X 12FT WIDTH (304.8cm x
                                        365.76cm)</strong></p>
                                <table class="table-result" cellpadding="0" cellspacing="0" width="100%" id="tabledata">
                                    <thead>
                                        <tr>
                                            <th class="master-heading" colspan="5">Plywood</th>
                                        </tr>
                                        <tr class="hide-mobile">
                                            <th class="column-4">Thickness</th>
                                            <th class="column-3">Quantity</th>

                                            <th class="column-2">Dimension</th>
                                            <th class="colum-5">Per Qty Price</th>
                                            <th class="colum-6">Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-label="Thickness">19mm</td>
                                            <td data-label="Qty">20</td>
                                            <td data-label="Length">10ft x 12ft</td>

                                            <td>{{ getPerQtyPrice1('18/19MM') }}₹ {{ per1 }}</td>

                                            <td>₹ {{ getTotalCost1(10, 12, 20) }}</td>

                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">12mm</td>
                                            <td data-label="Qty">9</td>
                                            <td data-label="Length">10ft x 12ft</td>

                                            <td>{{ getPerQtyPrice2('12MM') }}₹ {{ per2 }}</td>

                                            <td>₹ {{ getTotalCost2(10, 12, 9) }}</td>

                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">8mm</td>
                                            <td data-label="Qty">6</td>
                                            <td data-label="Length">10ft x 12ft</td>

                                            <td>{{ getPerQtyPrice3('8MM') }}₹ {{ per3 }}</td>

                                            <td>₹ {{ getTotalCost3(10, 12, 6) }}</td>

                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">6mm</td>
                                            <td data-label="Qty">8</td>

                                            <td data-label="Length">10ft x 12ft</td>

                                            <td>{{ getPerQtyPrice4('18/19MM') }}₹ {{ per4 }}</td>

                                            <td>₹ {{ getTotalCost4(10, 12, 8) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!--  -->
                        <!--  -->
                        <div class="col-lg-6 col-md-12 col-sm-12" v-if="varientTypes == 7 && btnStatus=='standard'">
                            <div class="content">
                                <p class="head" id="headtitle"><strong>WARDROBE 12 FT HEIGHT X 4FT WIDTH (365.76cm x
                                        121.92)</strong></p>
                                <table class="table-result" cellpadding="0" cellspacing="0" width="100%" id="tabledata">
                                    <thead>
                                        <tr>
                                            <th class="master-heading" colspan="5">Plywood</th>
                                        </tr>
                                        <tr class="hide-mobile">
                                            <th class="column-4">Thickness</th>
                                            <th class="column-3">Quantity</th>
                                            <th class="column-2">Dimension</th>
                                            <th class="colum-5">Per Qty Price</th>
                                            <th class="colum-6">Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-label="Thickness">19mm</td>
                                            <td data-label="Qty">8</td>
                                            <td data-label="Length">12ft x 4ft</td>
                                            <td>{{ getPerQtyPrice1('18/19MM') }}₹ {{ per1 }}</td>

                                            <td>₹ {{ getTotalCost1(12, 4, 8) }}</td>

                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">12mm</td>
                                            <td data-label="Qty">3</td>
                                            <td data-label="Length">12ft x 4ft</td>

                                            <td>{{ getPerQtyPrice2('12MM') }}₹ {{ per2 }}</td>

                                            <td>₹ {{ getTotalCost2(12, 4, 3) }}</td>


                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">8mm</td>
                                            <td data-label="Qty">2</td>
                                            <td data-label="Length">12ft x 4ft</td>

                                            <td>{{ getPerQtyPrice3('8MM') }}₹ {{ per3 }}</td>

                                            <td>₹ {{ getTotalCost3(12, 4, 2) }}</td>


                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">6mm</td>
                                            <td data-label="Qty">4</td>
                                            <td data-label="Length">12ft x 4ft</td>

                                            <td>{{ getPerQtyPrice4('6MM') }}₹ {{ per4 }}</td>

                                            <td>₹ {{ getTotalCost4(12, 4, 4) }}</td>


                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!--  -->
                        <!--  -->
                        <div class="col-lg-6 col-md-12 col-sm-12" v-if="varientTypes == 8 && btnStatus=='standard'">
                            <div class="content">
                                <p class="head" id="headtitle"><strong>WARDROBE 12 FT HEIGHT X 8FT WIDTH (365.76cm x
                                        243.84)</strong></p>
                                <table class="table-result" cellpadding="0" cellspacing="0" width="100%" id="tabledata">
                                    <thead>
                                        <tr>
                                            <th class="master-heading" colspan="5">Plywood</th>
                                        </tr>
                                        <tr class="hide-mobile">
                                            <th class="column-4">Thickness</th>
                                            <th class="column-3">Quantity</th>
                                            <th class="column-2">Dimension</th>
                                            <th class="colum-5">Per Qty Price</th>
                                            <th class="colum-6">Total Price</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-label="Thickness">19mm</td>
                                            <td data-label="Qty">16</td>
                                            <td data-label="Length">12ft x 8ft</td>

                                            <td>{{ getPerQtyPrice1('18/19MM') }}₹ {{ per1 }}</td>

                                            <td>₹ {{ getTotalCost1(12, 8, 16) }}</td>


                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">12mm</td>
                                            <td data-label="Qty">6</td>

                                            <td data-label="Length">12ft x 8ft</td>

                                            <td>{{ getPerQtyPrice2('12MM') }}₹ {{ per2 }}</td>

                                            <td>₹ {{ getTotalCost2(12, 8, 6) }}</td>

                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">8mm</td>
                                            <td data-label="Qty">4</td>
                                            <td data-label="Length">12ft x 8ft</td>

                                            <td>{{ getPerQtyPrice3('8MM') }}₹ {{ per3 }}</td>

                                            <td>₹ {{ getTotalCost3(12, 8, 4) }}</td>

                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">6mm</td>
                                            <td data-label="Qty">8</td>
                                            <td data-label="Length">12ft x 8ft</td>

                                            <td>{{ getPerQtyPrice4('6MM') }}₹ {{ per4 }}</td>

                                            <td>₹ {{ getTotalCost4(12, 8, 8) }}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!--  -->
                        <!--  -->
                        <div class="col-lg-6 col-md-12 col-sm-12" v-if="varientTypes == 9 && btnStatus=='standard'">
                            <div class="content">
                                <p class="head" id="headtitle"><strong>WARDROBE 10 FT HEIGHT (365.76cm x
                                        365.76cm)</strong></p>
                                <table class="table-result" cellpadding="0" cellspacing="0" width="100%" id="tabledata">
                                    <thead>
                                        <tr>
                                            <th class="master-heading" colspan="5">Plywood</th>
                                        </tr>
                                        <tr class="hide-mobile">
                                            <th class="column-4">Thickness</th>
                                            <th class="column-3">Quantity</th>
                                            <th class="column-2">Dimension</th>
                                            <th class="colum-5">Per Qty Price</th>
                                            <th class="colum-6">Total Price</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-label="Thickness">19mm</td>
                                            <td data-label="Qty">24</td>
                                            <td data-label="Length">365.76cm x 365.76cm</td>



                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">12mm</td>
                                            <td data-label="Qty">9</td>
                                            <td data-label="Length">365.76cm x 365.76cm</td>

                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">8mm</td>
                                            <td data-label="Qty">6</td>
                                            <td data-label="Length">365.76cm x 365.76cm</td>

                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">6mm</td>
                                            <td data-label="Qty">12</td>
                                            <td data-label="Length">365.76cm x 365.76cm</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!--  -->
                        <!--  -->
                        <div class="col-lg-6 col-md-12 col-sm-12" v-if="varientTypes == 10 && btnStatus=='standard'">
                            <div class="content">
                                <p class="head" id="headtitle"><strong>KITCHEN CABINET (2 of 8FTx3FT + 2 of 8FTx2FT + 1
                                        of 3FTx3FT)</strong></p>
                                <table class="table-result" cellpadding="0" cellspacing="0" width="100%" id="tabledata">
                                    <thead>
                                        <tr>
                                            <th class="master-heading" colspan="5">Plywood</th>
                                        </tr>
                                        <tr class="hide-mobile">
                                            <th class="column-4">Thickness</th>
                                            <th class="column-3">Quantity</th>
                                            <th class="column-2">Dimension</th>
                                            <th class="colum-5">Per Qty Price</th>
                                            <th class="colum-6">Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-label="Thickness">19mm</td>
                                            <td data-label="Qty">20</td>

                                            <td data-label="Length">8ft x 4ft</td>

                                            <td>{{ getPerQtyPrice1('18/19MM') }}₹ {{ per1 }}</td>

                                            <td>₹ {{ getTotalCost1(8, 4, 20) }}</td>
                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">12mm</td>
                                            <td data-label="Qty">15</td>

                                            <td data-label="Length">8ft x 4ft</td>

                                            <td>{{ getPerQtyPrice2('12MM') }}₹ {{ per2 }}</td>

                                            <td>₹ {{ getTotalCost2(8, 4, 15) }}</td>

                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">8mm</td>
                                            <td data-label="Qty">5</td>
                                            <td data-label="Length">8ft x 4ft</td>

                                            <td>{{ getPerQtyPrice3('8MM') }}₹ {{ per3 }}</td>

                                            <td>₹ {{ getTotalCost3(8, 4, 5) }}</td>

                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">6mm</td>
                                            <td data-label="Qty">10</td>
                                            <td data-label="Length">8ft x 4ft</td>

                                            <td>{{ getPerQtyPrice4('6MM') }}₹ {{ per4 }}</td>

                                            <td>₹ {{ getTotalCost4(8, 4, 10) }}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!--  -->

                        <!--  -->
                        <div class="col-lg-6 col-md-12 col-sm-12" v-if="varientTypes == 11 && btnStatus=='standard'">
                            <div class="content">
                                <p class="head" id="headtitle"><strong>QUEEN SIZE BED 5ft WIDTH X 6ft LENGTH (152.4cm x
                                        182.88cm)</strong></p>
                                <table class="table-result" cellpadding="0" cellspacing="0" width="100%" id="tabledata">
                                    <thead>
                                        <tr>
                                            <th class="master-heading" colspan="5">Plywood</th>
                                        </tr>
                                        <tr class="hide-mobile">
                                            <th class="column-4">Thickness</th>
                                            <th class="column-3">Quantity</th>
                                            <th class="column-2">Dimension</th>
                                            <th class="colum-5">Per Qty Price</th>
                                            <th class="colum-6">Total Price</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-label="Thickness">19mm</td>
                                            <td data-label="Qty">3</td>

                                            <td data-label="Length">5ft x 6ft</td>

                                            <td>{{ getPerQtyPrice1('18/19MM') }}₹ {{ per1 }}</td>

                                            <td>₹ {{ getTotalCost1(5, 6, 3) }}</td>
                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">12mm</td>
                                            <td data-label="Qty">2</td>

                                            <td data-label="Length">5ft x 6ft</td>

                                            <td>{{ getPerQtyPrice2('12MM') }}₹ {{ per2 }}</td>

                                            <td>₹ {{ getTotalCost2(5, 6, 2) }}</td>

                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">6mm</td>
                                            <td data-label="Qty">2</td>

                                            <td data-label="Length">5ft x 6ft</td>

                                            <td>{{ getPerQtyPrice3('6MM') }}₹ {{ per3 }}</td>

                                            <td>₹ {{ getTotalCost3(5, 6, 2) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!--  -->

                        <!--  -->
                        <div class="col-lg-6 col-md-12 col-sm-12" v-if="varientTypes == 12 && btnStatus=='standard'">
                            <div class="content">
                                <p class="head" id="headtitle"><strong>KING SIZE BED 6FT WIDTH X 6.6FT LENGTH (182.88cm
                                        x 198.12cm)</strong></p>
                                <table class="table-result" cellpadding="0" cellspacing="0" width="100%" id="tabledata">
                                    <thead>
                                        <tr>
                                            <th class="master-heading" colspan="5">Plywood</th>
                                        </tr>
                                        <tr class="hide-mobile">
                                            <th class="column-4">Thickness</th>
                                            <th class="column-3">Quantity</th>

                                            <th class="column-2">Dimension</th>
                                            <th class="colum-5">Per Qty Price</th>
                                            <th class="colum-6">Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-label="Thickness">19mm</td>
                                            <td data-label="Qty">5</td>
                                            <td data-label="Length">6ft x 6.5ft</td>

                                            <td>{{ getPerQtyPrice1('18/19MM') }}₹ {{ per1 }}</td>

                                            <td>₹ {{ getTotalCost1(6, 6.5, 5) }}</td>

                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">12mm</td>
                                            <td data-label="Qty">3</td>

                                            <td data-label="Length">6ft x 6.5ft</td>

                                            <td>{{ getPerQtyPrice2('12MM') }}₹ {{ per2 }}</td>

                                            <td>₹ {{ getTotalCost2(6, 6.5, 3) }}</td>

                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">6mm</td>
                                            <td data-label="Qty">2</td>
                                            <td data-label="Length">6ft x 6.5ft</td>

                                            <td>{{ getPerQtyPrice3('6MM') }}₹ {{ per3 }}</td>

                                            <td>₹ {{ getTotalCost3(6, 6.5, 2) }}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!--  -->

                        <!--  -->
                        <div class="col-lg-6 col-md-12 col-sm-12" v-if="varientTypes == 13 && btnStatus=='standard'">
                            <div class="content">
                                <p class="head" id="headtitle"><strong>SINGLE BED 2.5FT WIDTH X 6FT LENGTH (76.2cm x
                                        182.88cm)</strong></p>
                                <table class="table-result" cellpadding="0" cellspacing="0" width="100%" id="tabledata">
                                    <thead>
                                        <tr>
                                            <th class="master-heading" colspan="5">Plywood</th>
                                        </tr>
                                        <tr class="hide-mobile">
                                            <th class="column-4">Thickness</th>
                                            <th class="column-3">Quantity</th>
                                            <th class="column-2">Dimension</th>
                                            <th class="colum-5">Per Qty Price</th>
                                            <th class="colum-6">Total Price</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-label="Thickness">19mm</td>
                                            <td data-label="Qty">1</td>

                                            <td data-label="Length">2.5ft x 6ft</td>

                                            <td>{{ getPerQtyPrice1('18/19MM') }}₹ {{ per1 }}</td>

                                            <td>₹ {{ getTotalCost1(2.5, 6, 1) }}</td>
                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">12mm</td>
                                            <td data-label="Qty">1</td>

                                            <td data-label="Length">2.5ft x 6ft</td>

                                            <td>{{ getPerQtyPrice2('12MM') }}₹ {{ per2 }}</td>

                                            <td>₹ {{ getTotalCost2(2.5, 6, 1) }}</td>
                                        </tr>
                                        <tr>
                                            <td data-label="Thickness">6mm</td>
                                            <td data-label="Qty">2</td>

                                            <td data-label="Length">2.5ft x 6ft</td>

                                            <td>{{ getPerQtyPrice3('6MM') }}₹ {{ per3 }}</td>

                                            <td>₹ {{ getTotalCost3(2.5, 6, 2) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!--  -->

                        <!--  -->
                        <div class="col-lg-6 col-md-12 col-sm-12" v-if="varientTypes == 14 && btnStatus=='standard'">
                            <div class="content">
                                <p class="head" id="headtitle"><strong>Dressing Table 10 FT WIDTH X 4 FT LENGTH (304.8cm
                                        x 121.92cm)</strong></p>
                                <table class="table-result" cellpadding="0" cellspacing="0" width="100%" id="tabledata">
                                    <thead>
                                        <tr>
                                            <th class="master-heading" colspan="5">Plywood</th>
                                        </tr>
                                        <tr class="hide-mobile">

                                            <th class="column-4">Thickness</th>
                                            <th class="column-3">Quantity</th>

                                            <th class="column-2">Dimension</th>
                                            <th class="colum-5">Per Qty Price</th>
                                            <th class="colum-6">Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-label="Thickness">12mm</td>
                                            <td data-label="Qty">2</td>
                                            <td data-label="Length">10ft x 4ft</td>

                                            <td>{{ getPerQtyPrice1('12MM') }}₹ {{ per1 }}</td>

                                            <td>₹ {{ getTotalCost1(10, 4, 2) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!--  -->
                    </div>
                </div>
            </section>
            <!-- End About Us -->
            <!-- Main Footer -->
            <Footer />
            <!-- End Main Footer -->
        </div>
    </body>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header_3.vue";
import Footer from "@/components/Footer.vue";
import axios from 'axios';

import {
    Carousel,
    Slide
} from 'vue-carousel';

export default {
    name: "AboutUsView",
    components: {
        Header,
        Footer,
        Carousel,
        Slide
    },
    data() {
        return {
            varientTypes: 0,
            aboutData: [],
            testimonialData: [],
            furnitureType: '',
            varientType: '',
            varientAry: [],
            loader: false,
            categoryList: [],
            subCategoryList: [],
            productList: [],
            categoryType: '',
            subCategoryType: '',
            productId: '',
            per1: 0,
            per2: 0,
            per3: 0,
            per4: 0,
            errorMsg: {
                furniture: '',
                varient: '',
                category: '',
                subcategory: '',
                product: '',
            },
            btnStatus:'standard',
            thickness:'',
            size: '',
            qty:0,
             categoryList2: [],
            subCategoryList2: [],
            productList2: [],
            categoryType2: '',
            subCategoryType2: '',
            productId2: '',
            carpenterData:[],
            resultStatus: false,
            resultNotFound:false,
        }
    },
    mounted() {
        this.getAbout();
        this.getTestimonial();
        setTimeout(() => {
            window.scrollTo(0, 0);

        }, 1000);
        //console.log(this.errorMsg)

        this.getPlyCategory();
        this.getPlyCategory2();
    },
    watch() {

    },
    methods: {
        activeBtn(vl){
            this.btnStatus=vl;

        },
        calculate(val) {
            // this.errorMsg.furniture='';
            // this.errorMsg.varient=''; 
            // this.errorMsg.category='';
            // this.errorMsg.subcategory='';
            // this.errorMsg.product='';
            // if (this.furnitureType == '' || this.furnitureType == null) {
            //     this.errorMsg.furniture = 'Please select furniture type';
            // } else if (this.varientType == '' || this.varientType == null) {
            //     this.errorMsg.varient = 'Please select varient type';
            // } else if (this.categoryType == '' || this.categoryType == null) {
            //     this.errorMsg.category = 'Please select category ';
            // } else if (this.subCategoryType == '' || this.subCategoryType == null) {
            //     this.errorMsg.subcategory = 'Please select sub category ';
            // } else if (productId == '' || productId == null) {
            //     this.errorMsg.product = 'Please select product'
            // } else {
                this.loader = true

                setTimeout(() => {
                    this.varientTypes = val
                    this.loader = false
                }, 2000);
                this.resultStatus=false;

            //}


        },

        getTotalCost1(len, wid, qty) {

            var totalArea = (len * wid);
            var perSqr = this.per1;
            return (totalArea * perSqr) * qty;

        },
        getTotalCost2(len, wid, qty) {
            var totalArea = (len * wid);
            var perSqr = this.per2;
            return (totalArea * perSqr) * qty;
        },
        getTotalCost3(len, wid, qty) {
            var totalArea = (len * wid);
            var perSqr = this.per3;
            return (totalArea * perSqr) * qty;
        },
        getTotalCost4(len, wid, qty) {
            var totalArea = (len * wid);
            var perSqr = this.per4;
            return (totalArea * perSqr) * qty;
        },
        getTotalCostCarpent() {
            //console.log(this.carpenterData)
            var expl= this.carpenterData.size.split("x");
            //console.log(expl)
             var totalArea = (expl[0] * expl[1]);
             var perSqr = this.carpenterData.per_sq_ft;
             //console.log('per sq->'+perSqr+' , totalArea->'+totalArea)
             return (totalArea * perSqr) * this.qty;
        },
        getPerQtyPrice1(size) {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getPerQtyPrice',
                    size: size,
                    pid: ev.productId,
                }
            }).then(function (response) {
                //console.log(response.data)
                var data = response.data
                ev.per1 = data.per_sq_ft;
            })

        },
        getPerQtyPrice2(size) {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getPerQtyPrice',
                    size: size,
                    pid: ev.productId,
                }
            }).then(function (response) {
               //console.log(response.data)
                var data = response.data
                ev.per2 = data.per_sq_ft;
            })

        },
        getPerQtyPrice3(size) {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getPerQtyPrice',
                    size: size,
                    pid: ev.productId,
                }
            }).then(function (response) {
               //console.log(response.data)
                var data = response.data
                ev.per3 = data.per_sq_ft;
            })

        },
        getPerQtyPrice4(size) {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getPerQtyPrice',
                    size: size,
                    pid: ev.productId,
                }
            }).then(function (response) {
               //console.log(response.data)
                var data = response.data
                ev.per4 = data.per_sq_ft;
            })

        },
        getPlyCategory() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getPlyCategory',
                }
            }).then(function (response) {
               //console.log(response.data);
                var data = response.data;
                ev.categoryList = data;

            })
        },
        getSubCategory() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getPlySubCategory',
                    cid: this.categoryType,
                }
            }).then(function (response) {
               //console.log(response.data);
                var data = response.data;
                ev.subCategoryList = data;

            })
        },
        getProduct() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getPlyProduct',
                    sid: this.subCategoryType,
                    cid: this.categoryType,
                }
            }).then(function (response) {
               //console.log(response.data);
                var data = response.data;
                ev.productList = data;

            })
        },

        getPlyCategory2() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getPlyCategory',
                }
            }).then(function (response) {
               //console.log(response.data);
                var data = response.data;
                ev.categoryList2 = data;
            })
        },
        getSubCategory2() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getPlySubCategory',
                    cid: this.categoryType2,
                }
            }).then(function (response) {
               //console.log(response.data);
                var data = response.data;
                ev.subCategoryList2 = data;

            })
        },
        getProduct2() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getPlyProduct',
                    sid: this.subCategoryType2,
                    cid: this.categoryType2,
                }
            }).then(function (response) {
               //console.log(response.data);
                var data = response.data;
                ev.productList2 = data;

            })
        },

        getVarientType() {
            if (this.furnitureType == 1) {
                this.varientAry = [{
                    id: 1,
                    name: 'TV CABINET (243.84cm x 91.44cm)',
                    value: 'TV CABINET (243.84cm x 91.44cm)',

                }]
            } else if (this.furnitureType == 2) {
                this.varientAry = [{
                    id: 2,
                    name: 'WARDROBE 8 FT HEIGHT X 4FT WIDTH (243.84cm x 121.92cm)',
                    value: 'WARDROBE 8 FT HEIGHT X 4FT WIDTH (243.84cm x 121.92cm)		'
                },
                {
                    id: 3,
                    name: 'WARDROBE 8 FT HEIGHT X 8FT WIDTH (243.84cm x 243.84cm)',
                    value: 'WARDROBE 8 FT HEIGHT X 8FT WIDTH (243.84cm x 243.84cm)'
                },
                {
                    id: 4,
                    name: 'WARDROBE 10 FT HEIGHT X 4FT WIDTH (304.8cm x 121.92cm)',
                    value: 'WARDROBE 10 FT HEIGHT X 4FT WIDTH (304.8cm x 121.92cm)'
                },
                {
                    id: 5,
                    name: 'WARDROBE 10 FT HEIGHT(304.8 cm x 243.84 cm)',
                    value: 'WARDROBE 10 FT HEIGHT(304.8 cm x 243.84 cm)'
                },
                {
                    id: 6,
                    name: 'WARDROBE 10 FT HEIGHT (304.8cm x 365.76cm)',
                    value: 'WARDROBE 10 FT HEIGHT (304.8cm x 365.76cm)'
                },
                {
                    id: 7,
                    name: 'WARDROBE 10 FT HEIGHT(365.76 cm x121 .92 cm)',
                    value: 'WARDROBE 10 FT HEIGHT(365.76 cm x121 .92 cm)'
                },
                {
                    id: 8,
                    name: 'WARDROBE 10 FT HEIGHT (365.76cm x 243.84cm)',
                    value: 'WARDROBE 10 FT HEIGHT (365.76cm x 243.84cm)'
                },
                    // {
                    //     id: 9,
                    //     name: 'WARDROBE 10 FT HEIGHT(365.76 cm x 365.76 cm)',
                    //     value: 'WARDROBE 10 FT HEIGHT(365.76 cm x 365.76 cm)'
                    // },

                ]

            } else if (this.furnitureType == 3) {
                this.varientAry = [{
                    id: 10,
                    name: 'KITCHEN CABINET (2 of 8FTx3FT + 2 of 8FTx2FT + 1 of 3FTx3FT)',
                    value: 'KITCHEN CABINET (2 of 8FTx3FT + 2 of 8FTx2FT + 1 of 3FTx3FT)',
                }]
            } else if (this.furnitureType == 4) {
                this.varientAry = [{
                    id: 11,
                    name: 'QUEEN SIZE BED 5ft WIDTH X 6ft LENGTH (152.4cm x 182.88cm)',
                    value: 'QUEEN SIZE BED 5ft WIDTH X 6ft LENGTH (152.4cm x 182.88cm)'
                },
                {
                    id: 12,
                    name: 'KING SIZE BED 6FT WIDTH X 6.6FT LENGTH (182.88cm x 198.12cm)',
                    value: 'KING SIZE BED 6FT WIDTH X 6.6FT LENGTH (182.88cm x 198.12cm)'
                },
                {
                    id: 13,
                    name: 'SINGLE BED 2.5FT WIDTH X 6FT LENGTH (76.2cm x 182.88cm)',
                    value: 'SINGLE BED 2.5FT WIDTH X 6FT LENGTH (76.2cm x 182.88cm)'
                }
                ]
            } else if (this.furnitureType == 5) {
                this.varientAry = [{
                    id: 14,
                    name: 'Dressing Table 10 FT WIDTH X 4 FT (304.8cm x 121.92cm)',
                    value: 'Dressing Table 10 FT WIDTH X 4 FT (304.8cm x 121.92cm)'
                }]
            }
        },
        getAbout() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getAboutus',
                }
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;
                ev.aboutData = data;

            })

        },
        getTestimonial() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'testimonial',
                }
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;
                ev.testimonialData = data.data;

            })

        },

        getCarpentryData(){
            // this.carpenterData=[];
            var ev=this;

            if(this.categoryType2=='' || this.categoryType2==null){
                ev.$toasted.global.error({
                    message: 'Please select a category'
                });
            }else if(this.subCategoryType2=='' || this.subCategoryType2==null){
                ev.$toasted.global.error({
                    message: 'Please select sub category'
                });
            }else if(this.productId2=='' || this.productId2==null){
                ev.$toasted.global.error({
                    message: 'Please select product'
                });
            }else if(this.thickness=='' || this.thickness==null){
                ev.$toasted.global.error({
                    message: 'Please enter thickness'
                });
            }else if(this.size=='' || this.size==null){
                ev.$toasted.global.error({
                    message: 'Please enter size'
                });
            }else if(this.qty=='' || this.qty==null || this.qty==0){
                ev.$toasted.global.error({
                    message: 'Please enter quantity'
                });
            }else{
                axios({
                        method: 'POST',
                        url: this.$root.URL_ROOT + 'api.php',
                        data: {
                            type: 'getResults',
                            pid:this.productId2,
                            thickness:this.thickness,
                            size:this.size,
                        }
                }).then(function (response) {
                    console.log(response.data);
                    var data = response.data;
                    if(data.status=='success'){
                        ev.resultStatus=true;
                        ev.carpenterData = data.data;
                        console.log(ev.carpenterData)
                        ev.resultNotFound=false;
                    }else{
                        ev.resultNotFound=true;
                        ev.$toasted.global.error({
                            message: 'Not Found'
                        });
                    }
                    
                    

                })

            }
        },

    }

};
</script>

<style scoped>
.mb-top-20{
    margin-top:20px;
}
.mb-botttom-40{
    margin-bottom:40px;
}
.btn-mk{
    background-color:#fff !important;
    bottom: 1px solid #fff;
    color:#442f1c !important;
    font-size: 18px;
    font-weight: bold;
    letter-spacing:1px;
}
.btn-active{
     background-color:#714726 !important;
     color:#fff !important;
}
.errorMsg{
    color: #e11414;
    font-weight: bold;
    font-size: 16px;
}
.content p {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.content .table-result th.master-heading {
    background-color: #d6a84c;
    font-size: 20px;
}

.content .table-result th {
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
    line-height: 1;
    text-align: center;
    padding: 10px;
    font-weight: 500;
    background-color: #442f1c;
}

.head {
    padding: 10px 0;
}

.sec-title h2:before,
.sec-title h2:after {
    content: unset;
}

.frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    border-radius: 2px;
    box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background: #00000059;
    color: #333;
    font-family: 'Open Sans', Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.circle1,
.circle2,
.circle3 {
    background: #fff;
    border-radius: 50%;
    filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.3));
}

.circle1 {
    top: 50px;
    left: 50%;
    width: 100px;
    height: 100px;
    animation: circle1-anim 8s ease-in-out infinite;
}

.circle2 {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 60px;
    height: 60px;
    animation: circle2-anim 8s ease-in-out infinite;
}

.circle3 {
    position: absolute;
    position: absolute;
    top: 26%;
    left: 30%;
    width: 25px;
    height: 25px;
    animation: circle3-anim 8s ease-in-out infinite;
}

@keyframes circle3-anim {
    0% {
        transform: scale(1);
    }

    10% {
        transform: scale(0);
    }

    90% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes circle2-anim {
    0% {
        transform: scale(1);
    }

    5% {
        transform: scale(1);
    }

    15% {
        transform: scale(0);
    }

    85% {
        transform: scale(0);
    }

    95% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    }

}

@keyframes circle1-anim {
    0% {
        transform: scale(1);
    }

    10% {
        transform: scale(1);
    }

    60% {
        transform: scale(0.2);
    }

    70% {
        transform: scale(0);
    }

    90% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    }
}
</style>
