<template>
<body class="style-simple button-stroke layout-full-width if-overlay if-border-hide no-content-padding no-shadows header-classic minimalist-header-no sticky-tb-color ab-hide subheader-both-center menu-line-below-80-1 menuo-sub-active menuo-no-borders menuo-right logo-valign-top logo-no-margin mobile-tb-hide mobile-side-slide mobile-mini-mr-ll mobile-tr-header tr-header tr-menu">
    <div id="Wrapper">
        <div id="Header_wrapper">
            <Header3 />
        </div>

        <section class="whites-bg mt-5">
            <div class="container">

                <div class=" product-range-right product-range-list">
                    <span id="posts_content">

                        <ul id="products-listing" class="row listings" v-if="checkData">
                            <template v-for="(pdata,index) in productData">
                                <li class="col-sm-4" :key="pdata.id">
                                    <div class="theme-border m-text-center bg-white">
                                        <div class="pro-img flip-box">

                                            <router-link :to="'/productdetails/'+pdata.id">
                                                <div class="flip-box-inner">
                                                    <div class="flip-box-front">
                                                        <img :src="$root.Product+'/'+pdata.front_image" class="change-hover-img" :alt="pdata.name" :title="pdata.name">
                                                    </div>
                                                    <div class="flip-box-back">
                                                        <img :src="$root.Product+'/'+pdata.front_image" class="change-hover-img" :alt="pdata.name" :title="pdata.name">
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="prod-code text-center">
                                            <h3>
                                                <router-link :to="'/productdetails/'+pdata.id">{{pdata.name}}</router-link>
                                            </h3>

                                        </div>
                                        <input type="hidden" :class="'cart_product_id'+pdata.id" :value="pdata.id">
                                        <input type="hidden" class="productrangedesc2" value="3874">
                                        <div class="prod-name text-center">
                                            <a>{{pdata.category_name}}
                                            </a></div>
                                        <div class="size-thik hidden-sm">

                                            <p> Thickness : {{pdata.thickness}}

                                            </p>

                                            <p> Size : {{pdata.size}}

                                            </p>

                                            <div class="select-error-size errorsize2" style="display:none;">
                                                Please select size</div>

                                        </div>

                                        <div id="pr2" class="">

                                            <div class="price-div">
                                                <div class="cut-price">
                                                    <i class="fa fa-inr" aria-hidden="true"></i>{{pdata.purchese_cost}}
                                                </div>
                                                <div class="price priceid2">
                                                    <i class=" fa fa-inr" aria-hidden="true"></i>{{pdata.selling_cost}}
                                                </div>

                                            </div>
                                            <div class="prod-price">
                                                <div class="sav-per">you save: <span> 0(0%
                                                        Off)</span></div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 col-6 text-center hover hover-clr" style="border-right: 2px solid #ffd7bb;">
                                                    <div class="add-com-p" @click="add_to_cart(pdata.id, pdata.name, 1, pdata.selling_cost)">
                                                        <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-6 text-center hover hover-clr">
                                                    <div class="add-com-p" @click="whishList(pdata.id)">
                                                        <i class="fa fa-heart" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="flex justify-space">
                                                    <div class="add-com-p" @click="add_to_cart(pdata.id, pdata.name, 1, pdata.selling_cost)">
                                                        <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                                    </div>

                                                    <div class="add-com-p" @click="whishList(pdata.id)" ><i class="fa fa-heart" aria-hidden="true"></i>
                                                    </div>
                                                    <div class="add-com-p">
                                                        <i class="fa fa-eye" aria-hidden="true"></i>
                                                    </div> 
                                                </div> -->
                                        </div>
                                    </div>
                                </li>
                            </template>

                        </ul>

                        <div class="not-found" v-else>
                            <h3>Product Not Found</h3>
                        </div>

                    </span>
                    <ul id=" pagination  " style="display: none">
                        <li><a class=" " href=" # "><i class=" fa fa-arrow-left " aria-hidden=" true "></i></a></li>
                        <li><a href=" # ">1</a></li>
                        <li><a href=" # " class=" active ">2</a></li>
                        <li><a href=" # ">3</a></li>
                        <li><a href=" # ">4</a></li>
                        <li><a href=" # ">5</a></li>
                        <li><a href=" # ">6</a></li>
                        <li><a href=" # ">7</a></li>
                        <li><a href=" # "><i class=" fa fa-arrow-right " aria-hidden=" true "></i></a></li>
                    </ul>
                </div>

            </div>

        </section>

        <!-- Main Footer -->
        <Footer />
        <!-- End Main Footer -->
    </div>

</body>
</template>

<script>
// @ is an alias to /src
import Header3 from '@/components/Header_3.vue'
import Footer from '@/components/Footer.vue'
import axios from 'axios'
import ProductSlider from '@/components/ProductSlider.vue'

import {
    Carousel,
    Slide
} from 'vue-carousel';

export default {
    name: 'CategoryView',
    components: {
        Header3,
        Footer,
        Carousel,
        Slide,
        ProductSlider
    },
    data() {
        return {
            productData: [],
            subCategoryData: [],
            pid: null,
            checkData: false,
            checkVariant: false,
            ip_address: JSON.parse(localStorage.getItem("ip_address")),
            uid: JSON.parse(localStorage.getItem("uid")),
        }
    },
    mounted() {

        this.getWishList();
        setTimeout(() => {
            window.scrollTo(0,0);
           
        }, 1000);
        
    },
    watch: {
        productData: function (vl) {
            this.productData = vl;

        },

    },
    methods: {
        getWishList() {
            var ev = this;

            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getMyWishList',
                    uid: this.uid,
                    ip: this.ip_address,

                }
            }).then(function (response) {
                console.log(response.data);
                var data = response.data;
                if (data.count > 0) {
                    ev.checkData = true;
                } else {
                    ev.checkData = false;
                }
                if (data.status == "success") {

                    ev.productData = data.data

                } else {
                    ev.$toasted.global.error({
                        message: data.msg
                    });
                }

            })

        },

    }
}
</script>

<style scoped>
.product-range-list li {
    width: 295px;
}
</style>
