<template>
<body class="style-simple button-stroke layout-full-width if-overlay if-border-hide no-content-padding no-shadows header-classic minimalist-header-no sticky-tb-color ab-hide subheader-both-center menu-line-below-80-1 menuo-sub-active menuo-no-borders menuo-right logo-valign-top logo-no-margin mobile-tb-hide mobile-side-slide mobile-mini-mr-ll mobile-tr-header tr-header tr-menu">
    <div id="Wrapper">
        <div id="Header_wrapper">
            <Header />
        </div>
        <!--Page Title-->
        <section class="page-title top-bg-img">
            <div class="auto-container clearfix">
                <h1>Testimonial</h1>
                <ul class="bread-crumb clearfix">
                     <li><router-link to="/">Home</router-link></li>
                    <li>Testimonial</li>
                </ul>
            </div>
        </section>
        <!--End Page Title-->

        <!-- About Us -->
        <section class="about-us container">
             <div class="testimonial-carousel-two ">
                    <carousel :autoplay='true' :perPageCustom="[[400,1],[500, 1],[768, 1], [1024, 1]]">
                        <template v-for="(tdata,index) in testimonialData">
                            <slide :key="tdata.id">
                                <div class="testimonial-block-two">
                                    <div class="inner-box">
                                        <div class="info-box">
                                            <div class="thumb"><img :src="$root.Images_URL+'Content/'+tdata.image" alt=""></div>
                                            <h5 class="name">{{tdata.heading}}</h5>
                                            <span class="address hide">Los Angeles</span>
                                        </div>
                                        <div class="text" v-html="tdata.description"></div>
                                    </div>
                                </div>
                            </slide>
                        </template>
                    </carousel>        
                </div>
        </section>
        <!-- End About Us -->

        <!-- Main Footer -->
        <Footer />
        <!-- End Main Footer -->
    </div>

</body>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header_3.vue";
import Footer from "@/components/Footer.vue";
import axios from 'axios';

import {
    Carousel,
    Slide
} from 'vue-carousel';

export default {
    name: "AboutUsView",
    components: {
        Header,
        Footer,
        Carousel,
        Slide
    },
    data() {
        return {
            testimonialData: [],

        }
    },
    mounted() {
        this.getTestimonial();
        setTimeout(() => {
            window.scrollTo(0,0);
           
        }, 1000);
        

    },
    methods: {
       
        getTestimonial() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'testimonial',
                }
            }).then(function (response) {
                console.log(response.data);
                var data = response.data;
                ev.testimonialData = data.data;

            })

        },
    }

};
</script>
