<template>

    <body
        class="style-simple button-stroke layout-full-width if-overlay if-border-hide no-content-padding no-shadows header-classic minimalist-header-no sticky-tb-color ab-hide subheader-both-center menu-line-below-80-1 menuo-sub-active menuo-no-borders menuo-right logo-valign-top logo-no-margin mobile-tb-hide mobile-side-slide mobile-mini-mr-ll mobile-tr-header tr-header tr-menu">
        <div id="Wrapper">
            <div id="Header_wrapper">
                <Header />
            </div>


            <!-- About Us -->
            <section class="about-us container">

                <!-- Call Back Section -->
                <section class="call-back-section contact-bg-img" style="">
                    <div class="auto-container">
                        <div class="outer-box">
                            <div class="row no-gutters">
                                <!-- Text Column -->

                                <!-- Form Column -->
                                <div class="col-lg-3 col-md-3 col-sm-12 form-group"></div>
                                <div class="form-column col-xl-6 col-lg-12 col-md-6 col-sm-12">
                                    <div class="inner-column">
                                        <div class="request-form">
                                            <h3>Forgot Password</h3>

                                            <!--Comment Form-->

                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <input type="text" name="username" v-model="email"
                                                        placeholder="Username" required="">
                                                </div>


                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <button class="theme-btn btn-style-four" type="submit"
                                                        @click="sendEmail" name="submit-form"><span
                                                            class="btn-title">Send OTP</span>
                                                        <span></span> <span></span> <span></span> <span></span>
                                                        <span></span></button>
                                                </div>


                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <!--End Call Back Section -->

            </section>
            <!-- End About Us -->

            <!-- Main Footer -->
            <Footer />
            <!-- End Main Footer -->
        </div>

    </body>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header_3.vue";
import Footer from "@/components/Footer.vue";
import axios from 'axios';

import {
    Carousel,
    Slide
} from 'vue-carousel';

export default {
    name: "AboutUsView",
    components: {
        Header,
        Footer,
        Carousel,
        Slide
    },
    data() {
        return {
            testimonialData: [],
            username: null,
            password: null,
            email: null,

        }
    },
    mounted() {

        setTimeout(() => {
            window.scrollTo(0, 0);

        }, 1000);

    },
    methods: {

        sendEmail() {
            var ev = this;

            if (ev.email == null || ev.email == '') {

                ev.$toasted.global.error({
                    message: 'Please Enter Register Email'
                });

            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'api.php',
                    data: {
                        type: 'forgot',
                        email: this.email,

                    }
                }).then(function (response) {
                    //console.log(response.data);
                    var data = response.data;
                    if (data.status == "success") {

                        localStorage.setItem('uid', data.session.uid);
                        localStorage.setItem('username', data.session.username);
                        //console.log(localStorage.getItem('uid'));
                        ev.$router.push('/');

                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }



                })

            }


        },
    }

};
</script>
