<template>

    <body
        class="style-simple button-stroke layout-full-width if-overlay if-border-hide no-content-padding no-shadows header-classic minimalist-header-no sticky-tb-color ab-hide subheader-both-center menu-line-below-80-1 menuo-sub-active menuo-no-borders menuo-right logo-valign-top logo-no-margin mobile-tb-hide mobile-side-slide mobile-mini-mr-ll mobile-tr-header tr-header tr-menu">
        <div id="Wrapper">
            <div id="Header_wrapper">
                <Header />
            </div>

            <!--Page Title-->
            <section class="page-title top-bg-img">
                <div class="auto-container clearfix">
                    <h1>Privacy Policy</h1>
                    <ul class="bread-crumb clearfix">
                         <li><router-link to="/">Home</router-link></li>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
            </section>
            <!--End Page Title-->


            <!-- About Us -->
            <section class="about-us container">

                <!-- Call Back Section -->
                <section class="call-back-section- " style="padding-top:0px;">
                    <div class="auto-container">
                        <div class="outer-box-">
                            <div class="terms-conditions"
                                style="padding: 20px; background: rgb(255, 255, 255); max-width: 1376px; margin: 0px auto;">
                                <p class="western"><span style="color: rgb(128, 128, 128);"><span
                                            class="terms-header">Privacy Policy</span></span></p>
                                <p class="western"><span style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;">This privacy policy is an electronic
                                            record in the form of an electronic contract formed under the information
                                            technology act, 2000 and the rules made there under and the amended
                                            provisions pertaining to electronic documents / records in various statutes
                                            as amended by the information technology act, 2000. This privacy policy does
                                            not require any physical, electronic or digital signature.</span></span></p>
                                <p class="western"><span style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;"><span lang="en-US">Ecogen
                                                Industries</span></span></span><span
                                        style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;">&nbsp;and its affiliates and Associate
                                            Companies is/are concerned about the privacy of the data and information of
                                            users (including sellers and buyers/customers whether registered or
                                            non-registered), offering, selling or purchasing products or services on
                                            websites, mobile sites or mobile applications (“Website”) on the Website and
                                            otherwise doing business with us. “Associate Companies” here shall have the
                                            same meaning as ascribed in Companies Act, 2013.</span></span></p>
                                <p class="western"><span style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;">This Privacy Policy is a contract
                                            between you and the respective entity whose website you use or access or you
                                            otherwise deal with. This Privacy Policy shall be read together with the
                                            respective Terms Of Use or other terms and condition of the respective
                                            entity and its respective website or nature of business of the
                                            Website.</span></span></p><br>
                                <p class="western" align="justify"><span style="color: rgb(128, 128, 128);"><span
                                            class="terms-header">Collection of Personally Identifiable
                                            Information</span></span></p>
                                <p class="western" align="justify"><span style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;">We collect information from you when you
                                            place an order or subscribe to our website. When ordering or registering on
                                            our site, as appropriate, you may be asked to enter your: name, e-mail
                                            address, mailing address, phone number or credit card
                                            information.</span></span></p>
                                <p class="western"><span style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;">Our primary goal in doing so is to
                                            provide you a safe, efficient, smooth and customized
                                            experience.</span></span></p>
                                <p class="western"><span style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;">The information we learn from customers
                                            helps us personalize and continually improve your experience of shopping
                                            from our web store.</span></span></p>
                                <p class="western">&nbsp;</p>
                                <p class="western" align="justify"><span style="color: rgb(128, 128, 128);"><span
                                            class="terms-header">Use of Demographic / Profile Data</span></span></p>
                                <p class="western" align="justify"><span style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;">We use personal information to provide
                                            the services you request. To the extent we use your personal information to
                                            market to you, we will provide you the ability to opt-out of such
                                            uses.</span></span></p>
                                <p class="western" align="justify"><span style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;">We use your personal information to
                                            resolve disputes; troubleshoot problems; help promote a safe service;
                                            collect money; measure consumer interest in our products and services,
                                            inform you about online and offline offers, products, services, and
                                            updates</span></span></p>
                                <p class="western" align="justify"><span style="color: rgb(128, 128, 128);"><span
                                            class="terms-header">In our efforts to continually improve our product and
                                            service offerings, we collect and&nbsp;</span></span><span
                                        style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;"><span
                                                lang="en-US">analyze</span></span></span><span
                                        style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;">&nbsp;demographic and profile data about
                                            our user</span></span><span style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;"><span
                                                lang="en-US">’s</span></span></span><span
                                        style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;">activity on our
                                            Website</span></span><span style="color: rgb(51, 51, 51);"><span
                                            style="font-family: Roboto, serif;">.</span></span></p>
                                <p class="western" align="justify">&nbsp;</p>
                                <p class="western" align="justify"><strong><span
                                            style="color: rgb(128, 128, 128);"><span
                                                class="terms-header">Cookies</span></span></strong></p>
                                <p class="western" align="justify"><span style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;">Yes, Cookies are small files that a site
                                            or its service provider transfers to your computer’s hard drive through your
                                            Web browser (if you allow it) that enables the sites or service providers
                                            systems to recognize your browser and capture and remember certain
                                            information. We use cookies to help us remember and process the items in
                                            your shopping cart</span></span><span
                                        style="color: rgb(128, 128, 128);"><span
                                            style="font-size: large;">.</span></span><span
                                        style="color: rgb(0, 0, 0);"><span
                                            style="font-size: large;">&nbsp;</span></span><span
                                        style="color: rgb(128, 128, 128);"><span style="font-family: Roboto, serif;">The
                                            cookies do not contain any of your personally identifiable
                                            information.</span></span></p>
                                <p class="western" align="justify">&nbsp;</p>
                                <p class="western" align="justify"><span style="color: rgb(128, 128, 128);"><span
                                            class="terms-header">Sharing of personal information</span></span></p>
                                <p class="western"><span style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;">Your providing the Information
                                            to</span></span><span style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;"><span lang="en-US">&nbsp;Ecogen
                                                Industries&nbsp;</span></span></span><span
                                        style="color: rgb(128, 128, 128);"><span style="font-family: Roboto, serif;">and
                                            it’s consequent storage, collection, usage, transfer, access or processing
                                            of the same shall</span></span><span
                                        style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;"><strong>&nbsp;not
                                                be&nbsp;</strong></span></span><span
                                        style="color: rgb(128, 128, 128);"><span style="font-family: Roboto, serif;">in
                                            violation of any third party agreement, laws, charter documents, judgments,
                                            orders and decrees.</span></span></p>
                                <p class="western"><span style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;">We may disclose personal information if
                                            required to do so by law or in the good faith belief that such disclosure is
                                            reasonably necessary to respond to subpoenas, court orders, or other legal
                                            process.</span></span></p>
                                <p class="western" align="justify">&nbsp;</p>
                                <p class="western" align="justify"><span style="color: rgb(128, 128, 128);"><span
                                            class="terms-header">Security Precautions</span></span></p>
                                <p class="western" align="justify"><span style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;">We strive to ensure the security of your
                                            Personal Information and to protect your personal information against
                                            unauthorized access or unauthorized alteration, disclosure or destruction.
                                            Whenever you change or access your account information, we offer the use of
                                            a secure server.</span></span></p>
                                <p class="western" align="justify">&nbsp;</p>
                                <p class="western" align="justify"><span style="color: rgb(128, 128, 128);"><span
                                            class="terms-header">Choice/Opt-Out</span></span></p>
                                <p class="western"><span style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;">We provide all users with the
                                            opportunity to opt-out of receiving non-essential (promotional,
                                            marketing-related) communications from us on behalf of our partners, and
                                            from us in general,after setting up an account.</span></span></p>
                                <p class="western" align="justify"><span style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;">If we decide to change our privacy
                                            policy, we will post those changes on this page.</span></span></p>
                                <p class="western" align="justify">&nbsp;</p>
                                <p class="western" align="justify"><span style="color: rgb(128, 128, 128);"><span
                                            class="terms-header">Permissions</span></span></p>
                                <ul class="permission-ul">
                                    <li>
                                        <p><span style="font-family: Roboto, serif;"><span
                                                    style="color: rgb(128, 128, 128);"><span
                                                        style="font-family: Roboto, serif;"><b>Identity
                                                            :</b></span></span><span
                                                    style="color: rgb(128, 128, 128);"><span
                                                        style="font-family: Roboto, serif;">The identity permission
                                                        allows the app to access all saved account on your device, as
                                                        well as access and change your personal information stored on
                                                        the device. By account we mean everything you will see if you go
                                                        to Settings &gt; Accounts. There you will probably find a list
                                                        that will probably contain your Google account, Facebook,
                                                        WhatsApp, Skype and many others.</span></span></span></p>
                                    </li>
                                    <li>
                                        <p><span style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;"><b>SMS :</b></span></span><span
                                                style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">Under certain circumstances, we
                                                    may send you emails and SMS messages when we feel we have an
                                                    important announcement to share regarding your Service. Please note
                                                    that your carrier may charge you to receive messages via
                                                    SMS.</span></span></p>
                                    </li>
                                    <li>
                                        <p><span style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;"><b>Photos/Media/Files
                                                        :</b></span></span><span
                                                style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">Apps</span></span><span
                                                style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">&nbsp;</span></span><span
                                                style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">usually
                                                    request</span></span><span style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">&nbsp;</span></span><span
                                                style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;"><strong>Media</strong></span></span><span
                                                style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">&nbsp;</span></span><span
                                                style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">and</span></span><span
                                                style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;"><strong>&nbsp;Photos</strong></span></span><span
                                                style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">&nbsp;</span></span><span
                                                style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">permission when
                                                    it</span></span><span style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">&nbsp;</span></span><span
                                                style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">needs</span></span><span
                                                style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">&nbsp;</span></span><span
                                                style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">External</span></span><span
                                                style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">&nbsp;</span></span><span
                                                style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">File</span></span><span
                                                style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">&nbsp;</span></span><span
                                                style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">Storage (either Internal or
                                                    External) for storing some data, or for some features like
                                                    sharing</span></span><span style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">&nbsp;</span></span><span
                                                style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">images</span></span><span
                                                style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">&nbsp;</span></span><span
                                                style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">etc. </span></span></p>
                                    </li>
                                    <li>
                                        <p><span style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;"><b>Camera and microphone
                                                        :</b></span></span><span
                                                style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">It needs access to camera in
                                                    order for you to post pictures of products without the picture have
                                                    being stored prior to posting (so for example, if you want to post
                                                    picture you can select an option to take a picture at that moment,
                                                    to do this you must allow to access your camera to avoid asking you
                                                    permission each time. The</span></span><span
                                                style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;"><strong>&nbsp;microphone&nbsp;</strong></span></span><span
                                                style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">would be needed for voice
                                                    search.</span></span></p>
                                    </li>
                                    <li>
                                        <p><span style="font-family: Roboto, serif;"><span
                                                    style="color: rgb(128, 128, 128);"><span
                                                        style="font-family: Roboto, serif;"><b>Phones
                                                            :</b></span></span><span
                                                    style="color: rgb(128, 128, 128);"><span
                                                        style="font-family: Roboto, serif;">The phone permission is
                                                        useful for the app that allows you to place and receive calls
                                                        within an app.</span></span></span></p>
                                    </li>
                                </ul>
                                <ul class="phone-ul">
                                    <li>
                                        <p class="western"><span style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">Call phone numbers (even without
                                                    your intervention)</span></span></p>
                                    </li>
                                    <li>
                                        <p class="western"><span style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">Read and write call
                                                    logs</span></span></p>
                                    </li>
                                    <li>
                                        <p class="western"><span style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">Reroute calls</span></span></p>
                                    </li>
                                    <li>
                                        <p class="western"><span style="color: rgb(128, 128, 128);"><span
                                                    style="font-family: Roboto, serif;">Modify phone state</span></span>
                                        </p>
                                    </li>
                                </ul>
                                <p class="western" align="justify">&nbsp;</p>
                                <p class="western" align="justify"><span style="color: rgb(128, 128, 128);"><span
                                            class="terms-header">Your Approval</span></span></p>
                                <p class="western" align="justify"><span style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;">By using the Website and/ or by
                                            providing your information, you consent to the collection and use of the
                                            information you disclose on the Website in accordance with this Privacy
                                            Policy.</span></span></p>
                                <p class="western"><span style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;">If we decide to change our privacy
                                            policy, we will post those changes on this page.</span></span></p>
                                <p class="western" align="justify">&nbsp;</p>
                                <p class="western"><strong><span style="color: rgb(128, 128, 128);"><span
                                                class="terms-header">Contact Us</span></span></strong><span
                                        style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;"></span></span><span
                                        style="color: rgb(128, 128, 128);"><span
                                            style="font-family: Roboto, serif;"><br></span></span><span
                                        style="color: rgb(128, 128, 128);"><span style="font-family: Roboto, serif;">If
                                            there are any questions regarding this privacy policy you may contact us
                                            using the information below.</span></span></p>
                            </div>
                        </div>
                    </div>
                </section>
                <!--End Call Back Section -->

            </section>
            <!-- End About Us -->

            <!-- Main Footer -->
            <Footer />
            <!-- End Main Footer -->
        </div>

    </body>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header_3.vue";
import Footer from "@/components/Footer.vue";
import axios from 'axios';

import {
    Carousel,
    Slide
} from 'vue-carousel';

export default {
    name: "AboutUsView",
    components: {
        Header,
        Footer,
        Carousel,
        Slide
    },
    data() {
        return {
            testimonialData: [],
            username: null,
            password: null,
            email: null,

        }
    },
    mounted() {

        setTimeout(() => {
            window.scrollTo(0, 0);

        }, 1000);

    },
    methods: {

        sendEmail() {
            var ev = this;

            if (ev.email == null || ev.email == '') {

                ev.$toasted.global.error({
                    message: 'Please Enter Register Email'
                });

            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'api.php',
                    data: {
                        type: 'forgot',
                        email: this.email,

                    }
                }).then(function (response) {
                    //console.log(response.data);
                    var data = response.data;
                    if (data.status == "success") {

                        localStorage.setItem('uid', data.session.uid);
                        localStorage.setItem('username', data.session.username);
                        //console.log(localStorage.getItem('uid'));
                        ev.$router.push('/');

                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }



                })

            }


        },
    }

};
</script>
