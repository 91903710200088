<template>
    <div>

        <section class="sec-space">
            <div class="sec-heading">
                <h1 class="h1"><span>Feature </span> Products</h1>
            </div>
            <div class="section mcb-section equal-height-wrap">
                <div class=" mcb-section-inner">
                    <!--  -->
                    <div class="container">
                        <div class="product-range-list feature-product">
                            <ul class="row feature-slider">
                                <!--<ProductSlider :product-data="productData.related"></ProductSlider>--->
                                <carousel :autoplay='true' :loop="true"
                                    :perPageCustom="[[300, 1], [400, 1], [500, 1], [768, 2], [1024, 3]]">
                                    <template v-for="(pdata, index) in productData">
                                        <slide :key="pdata.id">
                                            <li class="" style="padding:10px 10px">
                                                <div class="theme-border m-text-center bg-white">
                                                    <div class="pro-img flip-box">

                                                        <router-link :to="'/productdetails/' + pdata.id">
                                                            <div class="flip-box-inner">
                                                                <div class="flip-box-front home-product-list">
                                                                    <img :src="$root.Product + '/' + pdata.front_image"
                                                                        class="change-hover-img" :alt="pdata.name"
                                                                        :title="pdata.name">
                                                                </div>
                                                                <div class="flip-box-back home-product-list">
                                                                    <img :src="$root.Product + '/' + pdata.front_image"
                                                                        class="change-hover-img" :alt="pdata.name"
                                                                        :title="pdata.name">
                                                                </div>
                                                            </div>
                                                        </router-link>
                                                    </div>

                                                    <div class="prod-code text-center">
                                                        <h3>
                                                            <router-link :to="'/productdetails/' + pdata.id">
                                                                {{ pdata.name }}</router-link>
                                                        </h3>

                                                    </div>
                                                    <input type="hidden" :class="'cart_product_id' + pdata.id"
                                                        :value="pdata.id">
                                                    <input type="hidden" class="productrangedesc2" value="3874">
                                                    <div class="prod-name text-center">
                                                        <a>{{ pdata.category_name }}
                                                        </a>
                                                    </div>
                                                    <div class="size-thik hidden-sm"
                                                        v-if="pdata.size != 'null' || pdata.thickness != 'null'">
                                                        <!--<p>Select : {{pdata.size?'Size ':''}} {{pdata.thickness?', Thickness ':''}}

                            <select name="size" id="" class="size " tabindex="-1" @change="changeVariant(pdata.id,$event,index)">
                                <option value="" productdataid="2">Select {{pdata.size?'Size ':''}} {{pdata.thickness?', Thickness ':''}}
                                </option>
                                <template v-for="vdata in pdata.variants">

                                    <option :value="vdata.id" v-if="vdata.size==pdata.size && vdata.thickness==pdata.thickness" selected>{{vdata.size?'size : '+vdata.size:''}}{{vdata.thickness?', Thickness : '+vdata.thickness:''}}</option>
                                    <option :value="vdata.id" v-else>{{vdata.size?'size : '+vdata.size:''}}{{vdata.thickness?', Thickness : '+vdata.thickness:''}}</option>

                                </template>
                            </select>
                        </p>-->

                                                        <p>Select : Thickness

                                                            <select name="size" :id="'thickness' + index" class="size "
                                                                tabindex="-1"
                                                                @change="changeVariant(pdata.id, $event, index, 'thickness')">
                                                                <option value="">Select Thickness </option>
                                                                <template v-for="sdata in pdata.thicknesslist">

                                                                    <option :value="sdata.thickness"
                                                                        v-if="sdata.thickness == pdata.thickness"
                                                                        selected>{{ sdata.thickness }}</option>
                                                                    <option :value="sdata.thickness" v-else>
                                                                        {{ sdata.thickness }}</option>

                                                                </template>
                                                            </select>
                                                        </p>

                                                        <p>Select : Size

                                                            <select name="size" :id="'size' + index" class="size "
                                                                tabindex="-1"
                                                                @change="changeVariant(pdata.id, $event, index, 'size')">
                                                                <option value="">Select Size </option>
                                                                <template v-for="sdata in pdata.sizelist">

                                                                    <option :value="sdata.size"
                                                                        v-if="sdata.size == pdata.size" selected>
                                                                        {{ sdata.size }}</option>
                                                                    <option :value="sdata.size" v-else>{{ sdata.size }}
                                                                    </option>

                                                                </template>
                                                            </select>
                                                        </p>


                                                        <div class="select-error-size errorsize2" style="display:none;">
                                                            Please select size</div>

                                                    </div>

                                                    <div id="pr2" class="">

                                                        <div class="price-div row">
                                                            <div class="col-6" style="padding:unset;">
                                                                <div class="cut-price">
                                                                    <i class="fa fa-inr" aria-hidden="true"></i>{{
                                                                            pdata.purchese_cost
                                                                    }}
                                                                </div><br />
                                                                <div class="price priceid2">
                                                                    <i class=" fa fa-inr" aria-hidden="true"></i>{{
                                                                            pdata.selling_cost
                                                                    }}
                                                                </div><br />
                                                                <span style="font-size:12px;">incl. GST</span>
                                                                <br />

                                                                <span>&nbsp; <i class=" fa fa-inr"
                                                                        aria-hidden="true"></i>&nbsp;{{ getWithoutGst(pdata.selling_cost) }}</span><br />

                                                                <p style="font-size:12px;margin-top:-5px;">&nbsp; excl.
                                                                    GST</p>
                                                                <br />
                                                            </div>

                                                            <div class="col-6" style="padding:unset;">
                                                                <div class="price priceid2">
                                                                    <i class=" fa fa-inr" aria-hidden="true"></i>{{
                                                                            pdata.per_sq_ft
                                                                    }} / Sq.ft
                                                                </div>

                                                            </div>

                                                        </div>


                                                        <div class="prod-price">
                                                            <div class="sav-per">you save: <span> 0(0%
                                                                    Off)</span></div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-6 col-6 text-center hover hover-clr"
                                                                style="border-right: 2px solid #ffd7bb;">
                                                                <div class="add-com-p"
                                                                    @click="add_to_cart(pdata.id, pdata.name, 1, pdata.selling_cost)">
                                                                    <i class="fa fa-shopping-cart"
                                                                        aria-hidden="true"></i>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-6 text-center hover hover-clr">
                                                                <div class="add-com-p" @click="whishList(pdata.id)">
                                                                    <i class="fa fa-heart" aria-hidden="true"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </slide>
                                    </template>
                                </carousel>

                            </ul>

                        </div>
                    </div>
                    <!--  -->
                </div>
            </div>
        </section>

    </div>

</template>

<script>
// @ is an alias to /src
import Vue from 'vue'
import {
    Carousel,
    Slide
} from 'vue-carousel';
import axios from 'axios'

export default {
    name: 'HomeView',
    components: {
        Carousel,
        Slide


    },

    data() {
        return {
            productData: [],
        }
    },
    mounted() {
        this.getProduct();
    },
    watch: {
        productData: function (vl) {
            this.productData = vl;
        }
    },
    methods: {
        getWithoutGst(oc) {
            var amt = (oc * (100 / (100 + 18)));
            return amt.toFixed(2);
        },
        getProduct() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getFeaturedProduct',
                }
            }).then(function (response) {

                var data = response.data;

                if (data.status == "success") {

                    ev.productData = data.data

                } else {
                    ev.$toasted.global.error({
                        message: data.msg
                    });
                }

            })

        },
        changeVariant(pId, updateId, index, vname) {
            var ev = this;
            var vid = updateId.target.value;
            //console.log(index);
            if (vname == 'size') {
                var size = updateId.target.value;
                var thickness = document.getElementById("thickness" + index).value
            } else {
                var size = document.getElementById("size" + index).value;
                var thickness = updateId.target.value;
            }

            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getVariantSingle',
                    vid: vid,
                    size: size,
                    thickness: thickness,
                    pid: pId,
                    vname: vname,

                }
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;
                var newAry = [];
                if (data.status == "success") {
                    //console.log('dasdsadsad');
                    ev.checkData = false;
                    ev.productData[index] = data.data;
                    ev.checkData = true;
                    //console.log(ev.productData[index])
                } else {
                    ev.$toasted.global.error({
                        message: 'PLease Select Size'
                    });
                }

            })

        },
    },
}
</script>