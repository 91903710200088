<template>
<div class="home">
    <Header></Header>

    <div id="Content">
        <div class="content_wrapper clearfix">
            <div class="sections_group">
                <div class="entry-content">
                    <section class="sec-space">
                        <div class="container text-center">
                            <div class="sec-heading">
                                <h1 class="h1"><span>Our </span> Products</h1>
                            </div>
                            <div class="owl-carousel owl-theme owl-responsive-991 owl-center owl-loaded" v-if="categoryData.length > 0">

                                <carousel-3d :controlsVisible="true">
                                    <template v-for="(cdata,index) in categoryData">
                                        <slide :index="index">
                                            <router-link :to="'/category/'+cdata.id">
                                                <div class="text-center">
                                                    <h2>{{cdata.heading}}</h2>
                                                    <img :src="$root.Images_URL+'Category/'+cdata.photo" class="h-product" alt="">
                                                </div>
                                            </router-link>
                                        </slide>

                                    </template>
                                </carousel-3d>

                            </div>
                            <router-link to="category/132" class="btn p-btn text-center my-3">BOOK NOW</router-link>
                        </div>
                    </section>
                    <section class="sec-space hide">
                        <div class="sec-heading">
                            <h1 class="h1"><span>Our </span> Feature</h1>
                        </div>
                        <div class="section mcb-section mcb-section-3587fe317 equal-height-wrap" style="">
                            <div class="section_wrapper mcb-section-inner">
                                <div class="wrap mcb-wrap mcb-wrap-ed86274b1 one-third  valign-middle clearfix" style="height: 259px;">
                                    <div class="mcb-wrap-inner">
                                        <div class="column mcb-column mcb-item-45f9f3eca one column_column">
                                            <div class="column_attr clearfix" style="padding:20px 0px 15px 20px;border-left:5px solid #bc865b;">
                                                <div class="image_frame image_item no_link scale-with-grid no_border" style="margin-bottom:20px;">
                                                    <div class="image_wrapper">
                                                        <img class="scale-with-grid" src="https://themes.muffingroup.com/be/carpenter2/wp-content/uploads/2017/11/carpenter2-home-icon1.png" alt="carpenter2 home icon1" title="" width="76" height="76">
                                                    </div>
                                                </div>
                                                <h4>Great precision</h4> Lorem ipsum dolor sit amet, consectetur
                                                adipisicing elit, sed do eiusmod tempor
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="wrap mcb-wrap mcb-wrap-ae866b51a one-third  valign-middle clearfix" style="height: 259px;">
                                    <div class="mcb-wrap-inner">
                                        <div class="column mcb-column mcb-item-b6c867e41 one column_column">
                                            <div class="column_attr clearfix" style="padding:20px 0px 15px 20px;border-left:5px solid #bc865b;">
                                                <div class="image_frame image_item no_link scale-with-grid no_border" style="margin-bottom:20px;">
                                                    <div class="image_wrapper"><img class="scale-with-grid" src="https://themes.muffingroup.com/be/carpenter2/wp-content/uploads/2017/11/carpenter2-home-icon2.png" alt="carpenter2 home icon2" title="" width="76" height="76">
                                                    </div>
                                                </div>
                                                <h4>Experience</h4> Lorem ipsum dolor sit amet, consectetur adipisicing
                                                elit, sed do eiusmod tempor
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="wrap mcb-wrap mcb-wrap-2564593e6 one-third  valign-middle clearfix" style="height: 259px;">
                                    <div class="mcb-wrap-inner">
                                        <div class="column mcb-column mcb-item-6f1c56f3e one column_column">
                                            <div class="column_attr clearfix" style="padding:20px 0px 15px 20px;border-left:5px solid #bc865b;">
                                                <div class="image_frame image_item no_link scale-with-grid no_border" style="margin-bottom:20px;">
                                                    <div class="image_wrapper"><img class="scale-with-grid" src="https://themes.muffingroup.com/be/carpenter2/wp-content/uploads/2017/11/carpenter2-home-icon3.png" alt="carpenter2 home icon3" title="" width="81" height="79">
                                                    </div>
                                                </div>
                                                <h4>Individual sizing</h4> Lorem ipsum dolor sit amet, consectetur
                                                adipisicing elit, sed do eiusmod tempor
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="wrap mcb-wrap mcb-wrap-a84f6ee98 one-sixth  valign-top clearfix" style="height: 259px;">
                                    <div class="mcb-wrap-inner">
                                        <div class="column mcb-column mcb-item-cf6b5177f one column_placeholder">
                                            <div class="placeholder">&nbsp;</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="wrap mcb-wrap mcb-wrap-23e90cc49 one-third  valign-middle clearfix" style="height: 259px;">
                                    <div class="mcb-wrap-inner">
                                        <div class="column mcb-column mcb-item-0d16be464 one column_column">
                                            <div class="column_attr clearfix" style="padding:20px 0px 15px 20px;border-left:5px solid #bc865b;">
                                                <div class="image_frame image_item no_link scale-with-grid no_border" style="margin-bottom:20px;">
                                                    <div class="image_wrapper"><img class="scale-with-grid" src="https://themes.muffingroup.com/be/carpenter2/wp-content/uploads/2017/11/carpenter2-home-icon4.png" alt="carpenter2 home icon4" title="" width="76" height="59">
                                                    </div>
                                                </div>
                                                <h4>Lorem ipsum</h4> Lorem ipsum dolor sit amet, consectetur adipisicing
                                                elit, sed do eiusmod tempor
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="wrap mcb-wrap mcb-wrap-5211935fb one-third  valign-middle clearfix" style="height: 259px;">
                                    <div class="mcb-wrap-inner">
                                        <div class="column mcb-column mcb-item-52967540c one column_column">
                                            <div class="column_attr clearfix" style="padding:20px 0px 15px 20px;border-left:5px solid #bc865b;">
                                                <div class="image_frame image_item no_link scale-with-grid no_border" style="margin-bottom:20px;">
                                                    <div class="image_wrapper"><img class="scale-with-grid" src="https://themes.muffingroup.com/be/carpenter2/wp-content/uploads/2017/11/carpenter2-home-icon5.png" alt="carpenter2 home icon5" title="" width="59" height="76">
                                                    </div>
                                                </div>
                                                <h4>Lorem ipsum</h4> Lorem ipsum dolor sit amet, consectetur adipisicing
                                                elit, sed do eiusmod tempor
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div class="sec-heading">
                            <h1 class="h1"><span>About </span> Us</h1>
                        </div>
                        <div class="section mcb-section full-width no-margin-h" style="padding-top:0px; padding-bottom:0px;">
                            <div class="section_wrapper mcb-section-inner">
                                <div class="wrap mcb-wrap one-second valign-top clearfix" style="margin-top:-50px">
                                    <div class="mcb-wrap-inner">
                                        <div class="column mcb-column one column_image">
                                            <div class="image_frame image_item no_link scale-with-grid no_border">
                                                <div class="image_wrapper">
                                                    <img class="scale-with-grid" src="../assets/images/carpenter2-home-tiles.png">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="wrap mcb-wrap one-second valign-middle clearfix" style="padding:50px">
                                    <div class="mcb-wrap-inner">
                                        <div class="column mcb-column one column_column  column-margin-10px">
                                            <div class="column_attr clearfix align_center">

                                                <!-- <h2 style="margin-bottom:30px;">Choose
                                                    <br>
                                                    your material
                                                </h2> -->
                                                <div v-html="aboutData.pbody"></div>
                                            </div>
                                        </div>
                                        <div class="column mcb-column one column_image hide">
                                            <div class="image_frame image_item no_link scale-with-grid aligncenter no_border">
                                                <div class="image_wrapper">
                                                    <img class="scale-with-grid" src="../assets/images/carpenter2-home-vertical-logo.jpg">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <FeatureProduct v-if="productData.length>0"></FeatureProduct>


                    <!-- <section class="sec-space">
                        <div class="sec-heading">
                            <h1 class="h1"><span>Feature </span> Products</h1>
                        </div>
                        <div class="section mcb-section equal-height-wrap">
                            <div class=" mcb-section-inner">
                                
                                <div class="container">
                                    <div class="product-range-list feature-product">
                                        <ul id="products-listing" class=" listings" v-if="productData.length>0">
                                            <ProductSlider :product-data="productData"></ProductSlider>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> -->
                </div>
            </div>
        </div>
    </div>

    <Footer></Footer>
</div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import axios from 'axios'

import {
    Carousel3d,
    Slide
} from 'vue-carousel-3d';

import ProductSlider from '@/components/ProductSlider.vue'
import FeatureProduct from '@/components/Feature.vue'

export default {
    name: 'HomeView',
    components: {
        Header,
        Footer,
        Carousel3d,
        Slide,
        ProductSlider,
        FeatureProduct,

    },
    data() {
        return {
            productData: [],
            saleProductData: [],
            sliderData: [],
            categoryData: [],
            aboutData:[],
        }
    },
    mounted() {
        this.getProduct();

        this.getSlider();

        this.getCategories();

        this.getAboutData();
        setTimeout(() => {
            window.scrollTo(0,0);
           
        }, 1000);
        
    },
    watch: {
        categoryData: function (vl) {
            this.categoryData = vl;
        },
        productData: function (vl) {
            this.productData = vl;
        }
    },
    methods: {
        getProduct() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getFeaturedProduct',
                }
            }).then(function (response) {
               
                var data = response.data;

                if (data.status == "success") {

                    ev.productData = data.data

                } else {
                    ev.$toasted.global.error({
                        message: data.msg
                    });
                }

            })

        },

        getSlider() {
            var ev = this;

            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'site_slider',
                }
            }).then(function (response) {
                
                var data = response.data;
                // console.log('Test dta-->slider');
                // console.log(data)

                if (data.status == "success") {

                    ev.sliderData = data.data

                } else {
                    ev.$toasted.global.error({
                        message: data.msg
                    });
                }

            })

        },

        getCategories() {
            var ev = this;

            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getAllCategory',
                }
            }).then(function (response) {
                
                var data = response.data;
                ev.categoryData = data.category
                

            })

        },
        getAboutData(){
            var ev = this;

            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getAboutus',
                }
            }).then(function (response) {
                
                var data = response.data;
                console.log(data);
                ev.aboutData = data
                

            })
        }
       

    }
}
</script>
