<template>
<body class="style-simple button-stroke layout-full-width if-overlay if-border-hide no-content-padding no-shadows header-classic minimalist-header-no sticky-tb-color ab-hide subheader-both-center menu-line-below-80-1 menuo-sub-active menuo-no-borders menuo-right logo-valign-top logo-no-margin mobile-tb-hide mobile-side-slide mobile-mini-mr-ll mobile-tr-header tr-header tr-menu">
    <div id="Wrapper">
        <div id="Header_wrapper">
            <Header3 />
        </div>

        <section class="whites-bg mt-5">
            <div class="container">
                <div class="not-found">
                
                    <h2>Coming Soon</h2>

                </div>
            </div>
        </section>
       
      

        <!-- Main Footer -->
        <Footer />
        <!-- End Main Footer -->
    </div>

</body>
</template>

<script>
// @ is an alias to /src
import Header3 from '@/components/Header_3.vue'
import Footer from '@/components/Footer.vue'
import axios from 'axios'

import {
    Carousel,
    Slide
} from 'vue-carousel';

export default {
    name: 'ProductDetailsView',
    components: {
        Header3,
        Footer,
        Carousel,
        Slide
    },
    data() {
        return {
            productData: [],
            subCategoryData: [],
            pid: null,
            checkData:false,

        }
    },
    mounted() {
       
        
    },
    watch: {
        productData: function(vl){
            this.productData =vl;
            this.checkData = true;
        }
    },
    methods: {
        getProductDetail() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getProductDetail',
                    id: this.$route.params.pid,
                }
            }).then(function (response) {
                console.log(response.data);
                var data = response.data;
                if (data.status == "success") {
                    ev.productData = data.product
                } else {
                    ev.$toasted.global.error({
                        message: data.msg
                    });
                }
            })
        },
        getVariant() {
            var ev = this;

            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getVariantProduct',
                    pid: this.pid,

                }
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;

                if (data.status == "success") {

                    ev.subCategoryData = data.data

                } else {
                    ev.$toasted.global.error({
                        message: data.msg
                    });
                }

            })
        }

    }
}
</script>
