import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Toasted from 'vue-toasted'
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;
import cart from './mixins/cart'
Vue.mixin(cart)
import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);
Vue.use(Toasted)

const error_options = {
    type: 'error',
    position: 'bottom-center',
    singleton: true,
    theme: 'bubble',
    duration: 5000,
    onClick: (e, toastObject) => {
        toastObject.goAway(0)
    }
}
const success_options = {
    type: 'success',
    singleton: true,
    position: 'bottom-center',
    duration: 5000,
    onClick: (e, toastObject) => {
        toastObject.goAway(0)
    }
}
Vue.toasted.register('error',
    (payload) => {
        // if there is no message passed show default message
        if (!payload.message) {
            return 'Oops.. Something Went Wrong..'
        }
        // if there is a message show it with the message
        return payload.message
    },
    error_options
)
Vue.toasted.register('success',
    (payload) => {
        // if there is no message passed show default message
        if (!payload.message) {
            return 'Greate.. Proceed Successfully..'
        }
        // if there is a message show it with the message
        return payload.message
    },
    success_options
)



Vue.config.productionTip = false
new Vue({
    data: {
        URL_ROOT: 'https://calibreply.jurysoftprojects.com/backend/api/',
        Product: 'https://calibreply.jurysoftprojects.com/backend/api/uploads/Product',
        Images_URL: 'https://calibreply.jurysoftprojects.com/backend/api/uploads/',
        auth: { Authorization: localStorage.getItem('token') }
    },
    router,
    render: h => h(App)
}).$mount('#app')