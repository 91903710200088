<template>
<body class="style-simple button-stroke layout-full-width if-overlay if-border-hide no-content-padding no-shadows header-classic minimalist-header-no sticky-tb-color ab-hide subheader-both-center menu-line-below-80-1 menuo-sub-active menuo-no-borders menuo-right logo-valign-top logo-no-margin mobile-tb-hide mobile-side-slide mobile-mini-mr-ll mobile-tr-header tr-header tr-menu">
    <div id="Wrapper">
        <div id="Header_wrapper">
            <Header />
        </div>
        <!--Page Title-->
        <section class="page-title top-bg-img">
            <div class="auto-container clearfix">
                <h1>Shopping Cart</h1>
                <ul class="bread-crumb clearfix">
                    <li>
                        <router-link to="/">Home</router-link>
                    </li>
                    <li>View Cart</li>
                </ul>
            </div>
        </section>
        <!--End Page Title-->
        <!-- Start Cart Page -->
        <section class="gray-bg">
            <div class="container">
                <div class="wish-tab">
                    <div class="cs">
                        <router-link to="/category/132">Continue Shopping</router-link>
                    </div>
                    <div class="tab">
                        <ul class="main-ul">
                            <li class="active"><i class="fa fa-shopping-cart" aria-hidden="true"></i> my shopping cart <span>( {{cartCount.count}} item)</span></li>
                        </ul>
                        <div class="tab-container">
                            <div class="respon"><span class="span active"></span> my shopping cart ( {{cartCount.count}} item)</div>
                            <div class="contant s1" style="display: block;">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                    <tbody>
                                        <tr>
                                            <th width="450">PRODUCT DETAIL</th>
                                            <th width="100">PRICE</th>
                                            <th width="70">QUANTITY</th>
                                            <th width="155">TOTAL</th>
                                            <th width="155"></th>
                                        </tr>

                                        <tr v-for="cart in cartCount.data" :key="cart.id">
                                            <td>
                                                <div class="pro-im"><img :src="$root.URL_ROOT+'uploads/Product/'+cart.front_image" :alt="cart.front_image" :title="cart.front_image" width="100" height="100"></div>
                                                <div class="right-proim">
                                                    <h3> {{cart.name}} </h3>
                                                    <div class="size-thik">
                                                        <p v-if="cart.size"> Size: {{cart.size}} </p>
                                                        <p v-if="cart.thickness">thickness : {{cart.thickness}} </p>
                                                    </div>
                                                </div>
                                            </td>

                                            <td>
                                                <template v-if="cart.purchese_cost > cart.selling_cost && cart.purchese_cost != null && cart.purchese_cost != 0">
                                                    <div class="cut-price"><i class="fa fa-inr" aria-hidden="true"></i> {{cart.purchese_cost}} </div>
                                                </template>
                                                <div class="uncut-price"><i class="fa fa-inr" aria-hidden="true"></i> {{cart.selling_cost}} </div>
                                            </td>
                                            <td>
                                                <div class="qty" data="Adoni">

                                                    <div class="">
                                                        <div class="input-group bootstrap-touchspin align-center">

                                                            <button @click="addCart(cart.id,cart.qty)" class="btn btn-touchspin js-touchspin js-increase-product-quantity bootstrap-touchspin-up qty-btn" type="button">
                                                                <i class="fa fa-plus"></i></button>
                                                            <input class=" input-quentity js-cart-line-product-quantity form-control mt-20" type="text" :value="cart.qty" name="product-quantity-spin " min="1" style="display: block;background: #fff;" disabled>

                                                            <button @click="minusCart(cart.id,cart.qty)" :class="cart.qty==1 ? 'disabled' : ''" class="btn btn-touchspin qty-btn js-touchspin js-decrease-product-quantity bootstrap-touchspin-down" type="button">
                                                                <i class="fa fa-minus"></i></button>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="clear"></div>

                                            </td>
                                            <td align="center">
                                                <div class="uncut-price"><i class="fa fa-inr" aria-hidden="true"></i> {{cart.total_amount}}</div>
                                            </td>
                                            <td>
                                                <div class="cent-w-d">
                                                    <p><a href="#" @click="remove(cart.id)"><i class="fa fa-trash-o" aria-hidden="true"></i></a></p>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr class="lastrowresponsive">
                                            <td colspan="4">
                                                <h6 style="display:none;">Check Delivery Info(?)</h6>
                                                <div class="enter-pin-code" style="display:none;">
                                                    <input placeholder="Enter Pincode" type="tel" value="712409" name="pincode" id="pincode" maxlength="6">
                                                    <!-- <input type="submit" value="apply"> -->
                                                </div>
                                                <span id="pin">
                                                </span>
                                                <!--<span>Shipping Charges:</span> <h4>FREE</h4>-->
                                                <!--<span>Final Payment</span><br><div class="uncut-price"><i class="fa fa-inr" aria-hidden="true"></i> 1040.00</div>-->
                                            </td>
                                            <td class="shipping-char">
                                                <div class="buy-now">
                                                    <router-link :to="{name:'Login'}" v-if="!uid"><span>Log In</span><span class="icon"><i class="fa fa-arrow-right" aria-hidden="true"></i></span></router-link>
                                                    <router-link :to="{name:'Checkout'}" v-else><span>Order</span><span class="icon"><i class="fa fa-arrow-right" aria-hidden="true"></i></span></router-link>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>

                            <div class="respon"><span class="span"></span>my wishlist</div>
                            <div class="contant s2" style="display: none;">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                    <tbody>
                                        <tr class="lastrowresponsive">
                                            <td align="center" class="empty">Your wishlist is empty</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </section>
        <!-- End Cart Page -->
        <!-- Main Footer -->
        <Footer />
        <!-- End Main Footer -->
    </div>

</body>
</template>

<script>
import axios from 'axios'
import Header from '@/components/Header_3.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'CartView',
    components: {
        Header,
        Footer,

    },
    data() {
        return {
            isHas: false,
            data: "",
            cartCount: [],
            shipping: 0,
            card: "",
            uid: null,
            fields: {
                type: "update_profile",
                uid: "",
                fullname: "",
                mobile: "",
                address: "",
                Token: this.$root.auth,
            },
        };
    },
    watch: {
        cartCount: function (cartCount) {
            this.cartCount = cartCount;
            // this.getCart();
        },
    },
    mounted() {
        this.uid = localStorage.getItem("uid");
        this.goToTop();
        this.getCart();
        this.shippingCharge()
        setTimeout(() => {
            window.scrollTo(0,0);
           
        }, 1000);
        
    },
    methods: {
        addCart(id, qty) {
            var ev = this
            axios.post(this.$root.URL_ROOT + "api.php", {
                    type: 'addQty',
                    cart_id: id,
                    qty: qty
                })
                .then(function (response) {
                    //console.log(response);
                    if (response.data.status == "success") {
                        ev.$toasted.global.success({
                            message: response.data.msg
                        });
                        ev.getCart()
                    } else {
                        ev.$toasted.global.error({
                            message: response.data.msg
                        });
                    }
                });
        },
        minusCart(id, qty) {
            var ev = this
            axios.post(this.$root.URL_ROOT + "api.php", {
                    type: 'minQty',
                    cart_id: id,
                    qty: qty
                })
                .then(function (response) {
                    //console.log(response);
                    if (response.data.status == "success") {
                        ev.$toasted.global.success({
                            message: response.data.msg
                        });
                        ev.getCart()
                    } else {
                        ev.$toasted.global.error({
                            message: response.data.msg
                        });
                    }
                });
        },
        remove(id) {

            var ev = this;
            var param = {
                id: id,
                table: 'mss_cart',
                type: 'commonDelete'
            }
            this.$swal({
                title: "Delete this ?",
                text: "Are you sure? You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: "#f00",
                confirmButtonText: "Yes, Delete it!",
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    axios({
                        method: 'POST',
                        url: this.$root.URL_ROOT + 'api.php',
                        data: param
                    }).then(function (response) {
                        var data = response.data;
                        ev.getCart()
                        //console.log(data)
                        // this.deleted = data
                        ev.$toasted.global.success({
                            message: 'Deleted successfully'
                        })
                    })
                }
            })
        },
        goToTop() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        update_cart_pre(cid, pid, pname, qty, price, mode) {
            var t = this.update_cart(cid, pid, pname, qty, price, mode);
            //console.log(t)
            setTimeout(() => {
                if (t != null) {
                    this.getCart();
                }
            }, 1000);
        },
    },
};
</script>

<style scoped>
.wish-tab .cs {
    top: 28px;
}

.align-center {
    align-items: center;
}

.qty-btn {
    padding: 2px 5px;
    border: unset !important;
}

.mt-20 {
    margin-top: 20px;
}

.wish-tab td .qty {
    background: unset !important;
}
</style>
