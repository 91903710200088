<template>
<body class="style-simple button-stroke layout-full-width if-overlay if-border-hide no-content-padding no-shadows header-classic minimalist-header-no sticky-tb-color ab-hide subheader-both-center menu-line-below-80-1 menuo-sub-active menuo-no-borders menuo-right logo-valign-top logo-no-margin mobile-tb-hide mobile-side-slide mobile-mini-mr-ll mobile-tr-header tr-header tr-menu">
    <div id="Wrapper">
        <div id="Header_wrapper">
            <Header />
        </div>

        <!-- About Us -->
        <section class="about-us container">

            <!-- Call Back Section -->
            <section class="call-back-section contact-bg-img" style="">
                <div class="auto-container">
                    <div class="outer-box">
                        <div class="row no-gutters">
                            <!-- Text Column -->

                            <!-- Form Column -->
                            <div class="col-lg-3 col-md-3 col-sm-12 form-group"></div>
                            <div class="form-column col-xl-6 col-lg-12 col-md-6 col-sm-12">
                                <div class="inner-column">
                                    <div class="request-form">
                                        <h3>Signup</h3>

                                        <!--Comment Form-->

                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
                                                <input type="text" name="fname" v-model="fname" placeholder="First Name" autocomplete="off" required="">
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
                                                <input type="text" name="lname" v-model="lname" placeholder="Last Name" autocomplete="off" required="">
                                            </div>

                                            <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                <input type="email" name="email" v-model="email" placeholder="Email" autocomplete="off" required="">
                                            </div>

                                            <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                <input type="text" class="w-100" name="mobile" v-model="mobile" placeholder="Mobile No." autocomplete="off" required="">
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
                                                <input type="password" name="password" v-model="password" placeholder="Password" required="" autocomplete="off" style="width: 100%">
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-sm-6 form-group">
                                                <input type="password" name="password" v-model="cpassword" placeholder="Confirm Password" required="" autocomplete="off" style="width: 100%">
                                            </div>

                                            <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                <button class="theme-btn btn-style-four" type="submit" @click="signup" name="submit-form"><span class="btn-title">Signup</span>
                                                    <span></span> <span></span> <span></span> <span></span>
                                                    <span></span></button>
                                            </div>

                                         
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!--End Call Back Section -->

        </section>
        <!-- End About Us -->

        <!-- Main Footer -->
        <Footer />
        <!-- End Main Footer -->
    </div>

</body>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header_3.vue";
import Footer from "@/components/Footer.vue";
import axios from 'axios';

import {
    Carousel,
    Slide
} from 'vue-carousel';

export default {
    name: "AboutUsView",
    components: {
        Header,
        Footer,
        Carousel,
        Slide
    },
    data() {
        return {
            
            testimonialData: [],
            username: null,
            password: null,

            fname: null,
            lname: null,
            email: null,
            mobile: null,
            cpassword: null,

        }
    },
    mounted() {
        setTimeout(() => {
            window.scrollTo(0,0);
           
        }, 1000);
        
    },
    methods: {

        signup() {
            var ev = this;

            if (this.fname == null || this.fname == '') {
                ev.$toasted.global.error({
                    message: 'Please enter first name'
                });
            } else if (this.lname == null || this.lname == '') {
                ev.$toasted.global.error({
                    message: 'Please enter last name'
                });
            } else if (this.email == null || this.email == '') {
                4
                ev.$toasted.global.error({
                    message: 'Please enter email address'
                });
            } else if (this.password == null || this.password == '') {
                ev.$toasted.global.error({
                    message: 'Please enter password'
                });
            } else if (this.cpassword == null || this.cpassword == '') {
                ev.$toasted.global.error({
                    message: 'Please enter confirm password'
                });
            } else if (this.password !== this.cpassword) {
                ev.$toasted.global.error({
                    message: 'Password and confirm password in not same'
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'api.php',
                    data: {
                        type: 'signup',
                        username: this.fname+' '+this.lname,
                        fullname: this.fname+' '+this.lname,
                        email: this.email,
                        password: this.password,
                        mobile: this.mobile,
                        

                    }
                }).then(function (response) {
                    console.log(response.data);
                    var data = response.data;
                    if (data.status == "success") {
                         ev.$toasted.global.success({
                            message: data.msg
                        });
                        // localStorage.setItem('uid', data.session.uid);
                        // localStorage.setItem('username', data.session.username);
                        //console.log(localStorage.getItem('uid'));
                        ev.$router.push('/login');

                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }

                })
            }

        },
    }

};
</script>

<style>
.request-form .form-group input[type="email"],
input[type="text"],
input[type="number"],
input[type="password"] {
    position: relative;
    display: block;
    width: 100%;
    font-size: 16px;
    color: #ffffff;
    line-height: 20px;
    padding: 9px 20px;
    border: 1px solid transparent;
    background-color: rgba(0, 0, 0, .15) !important;
    font-weight: 400;
    border-radius: 2px;
    height: 40px;
    transition: all 300ms ease;
}
</style>
