<template>

    <body
        class="style-simple button-stroke layout-full-width if-overlay if-border-hide no-content-padding no-shadows header-classic minimalist-header-no sticky-tb-color ab-hide subheader-both-center menu-line-below-80-1 menuo-sub-active menuo-no-borders menuo-right logo-valign-top logo-no-margin mobile-tb-hide mobile-side-slide mobile-mini-mr-ll mobile-tr-header tr-header tr-menu">
        <div id="Wrapper">
            <div id="Header_wrapper">
                <Header3 />
            </div>
            <section class="page-title top-bg-img">
                <div class="auto-container clearfix">
                    <h1>{{ productData.name }}</h1>
                    <ul class="bread-crumb clearfix">
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>{{ productData.name }}</li>
                    </ul>
                </div>
            </section>
            <section class="whites-bg mt-5">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-5 col-xl-5">
                            <div class="row border-3 pd-20 flex-reverse">
                                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4 flexslider ">
                                    <div class="flex-viewport" style="overflow: hidden; position: relative;">
                                        <ul class="slides m-d-flex">
                                            <li class="m-multi-img" @click="changeImage(productData.front_image)"
                                                style="width: 80px; margin-right: 5px;margin-bottom: 10px; float: left; display: block;">
                                                <img class="red-border"
                                                    :src="$root.URL_ROOT + 'uploads/Product/' + productData.front_image"
                                                    :alt="productData.name" :title="productData.name" draggable="false">
                                            </li>
                                            <li class="m-multi-img" @click="changeImage(image)"
                                                v-for="(image, key) in allimages" :key="key"
                                                style="width: 80px; margin-right: 5px;margin-bottom: 10px; float: left; display: block;">
                                                <img class="red-border"
                                                    :src="$root.URL_ROOT + 'uploads/Product/' + image"
                                                    :alt="productData.name" :title="productData.name" draggable="false">
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-8 col-xl-8 flexslider">

                                    <div class="flex-viewport" style=" position: relative;">
                                        <ul class="slides"
                                            style="width: 200%; transition-duration: 0s; transform: translate3d(0px, 0px, 0px);">
                                            <li class="flex-active-slide zomm-img-div">
                                                <!---- style="width: 468px; margin-right: 0px; float: left; display: block;"--->
                                                <div class="easyzoom easyzoom--adjacent is-ready">
                                                    <a href="#" data-fancybox="gallery"
                                                        :data-caption="productData.name">
                                                        <zoom-on-hover
                                                            :img-normal="$root.URL_ROOT + 'uploads/Product/' + mainImage"
                                                            :img-zoom="$root.URL_ROOT + 'uploads/Product/' + mainImage"
                                                            class="w-65 img-responsive" :scale="2"></zoom-on-hover>
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-7 col-xl-7">
                            <div class="row">
                                <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 right-border z-index-9">
                                    <div style="prl-20">
                                        <div class="heading">{{ productData.category_name }}</div>
                                        <div class="sku-code"> {{ productData.name }}</div>
                                        <div class="rating hide"> 4.9 <i class="fa fa-star yellow-color"
                                                aria-hidden="true"></i>
                                            Based on 22 Ratings
                                        </div>

                                        <div class="row">
                                            <div class="col-6">
                                                <div class="d-price">

                                                    <div class="cut-price" style="font-size: 14px;">
                                                        <i class="fa fa-inr" aria-hidden="true"></i>{{
                                                                productData.purchese_cost
                                                        }}
                                                    </div>
                                                    <br />
                                                    <i class="fa fa-inr" aria-hidden="true"></i>
                                                    {{ productData.selling_cost }}
                                                    <br />
                                                    <span style="font-size:12px;color:black;">incl. GST</span>

                                                    <span class="dark-bg hide">33% Off</span>

                                                </div>

                                                <i class="fa fa-inr" aria-hidden="true"></i>
                                                {{ getWithoutGst(productData.selling_cost) }}
                                                <br />
                                                <span style="font-size:12px;color:black;">excl. GST</span>

                                            </div>
                                            <div class="col-6">
                                                <div class="d-price">
                                                    <i class="fa fa-inr" aria-hidden="true"></i>
                                                    {{ getWithoutGst(productData.per_sq_ft) }}
                                                    <br />
                                                    <span style="font-size:12px;color:black;">/ Sq.ft</span>
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                    <div class="size-thik font-18 my-3">

                                        <p>Select : Thickness

                                            <select name="size" :id="'thickness0'" class="size " tabindex="-1"
                                                @change="changeVariant(productData.id, $event, 0, 'thickness')">
                                                <option value="">Select Thickness </option>
                                                <template v-for="sdata in productData.thicknesslist">
                                                    <option :value="sdata.thickness"
                                                        v-if="sdata.thickness == productData.thickness" selected>
                                                        {{ sdata.thickness }}</option>
                                                    <option :value="sdata.thickness" v-else>{{ sdata.thickness }}
                                                    </option>
                                                </template>
                                            </select>
                                        </p>

                                        <p>Select : Size

                                            <select name="size" :id="'size0'" class="size " tabindex="-1"
                                                @change="changeVariant(productData.id, $event, 0, 'size')">
                                                <option value="">Select Size </option>
                                                <template v-for="sdata in productData.sizelist">
                                                    <option :value="sdata.size" v-if="sdata.size == productData.size"
                                                        selected>{{ sdata.size }}</option>
                                                    <option :value="sdata.size" v-else>{{ sdata.size }}</option>
                                                </template>
                                            </select>
                                        </p>
                                        <p class="my-3">Quantity:
                                            <!-- <select name="thickness" id="thick2" class="thick" v-model="qtyMain"
                                                tabindex="-1" style="background-color:#fff;color:#000 !important;">
                                                <option value="1">1 </option>
                                               
                                            </select> -->
                                            <input type="number" class="thick"
                                                style="background-color:#ca8f65 !important;" v-model="qtyMain" />
                                        </p>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 add-cart-area">
                                    <h3 class="d-price hide"> <i class="fa fa-inr"></i> 1,000 </h3>
                                    <p class="delivery"> Delivery: <span>Pincode Check</span> </p>
                                    <div class="pincode">
                                        <div class="form-group">
                                            <input type="text" class="input-form " v-model="checkpincode"
                                                placeholder="Enter Pincode" />
                                            <button class="btn theme-btn max-50" @click="checkPin()">Check</button>
                                        </div>
                                    </div>
                                    <div class="status-pincode">
                                        <span v-if="checkpin == 1" class="status-green">Delivery Available</span>
                                        <span v-if="checkpin == 2" class="status-red">Currently Not Available</span>
                                    </div>
                                    <span class="stock">In Stock</span>
                                    <div class="cart-btn">
                                        <a class="btn buy-now-btn"
                                            @click="buyNow(productData.id, productData.name, qtyMain, productData.selling_cost)">BUY
                                            NOW</a>
                                        <a class="btn add-to-cart"
                                            @click="add_to_cart(productData.id, productData.name, qtyMain, productData.selling_cost)">ADD
                                            TO CART</a>
                                    </div>
                                </div>
                            </div>

                            <!-- <div>
                    <img src="assets/images/detail.jpg" class="w-100" />
                </div> -->
                            <div class="clear"></div>

                            <div class="row detail-icon-area">
                                <template v-for="fdata in featureData">
                                    <div class="col-lg-2 col-xl-2 col-md-6 col-sm-6 d-icons">
                                        <img v-if="fdata.image == '1.381472c7.jpg'"
                                            src="../assets/images/icons/1.jpg" />
                                        <img v-if="fdata.image == '2.c4f100b8.jpg'"
                                            src="../assets/images/icons/2.jpg" />
                                        <img v-if="fdata.image == '3.79c0628c.jpg'"
                                            src="../assets/images/icons/1.jpg" />
                                        <img v-if="fdata.image == '4.671b177d.jpg'"
                                            src="../assets/images/icons/1.jpg" />
                                        <img v-if="fdata.image == '5.61714a44.jpg'"
                                            src="../assets/images/icons/1.jpg" />

                                        <p>{{ fdata.name }}
                                        </p>
                                    </div>
                                    <!-- <div class="col-lg-2 col-xl-2 col-md-6 col-sm-6 d-icons">
                                <img src="../assets/images/icons/2.jpg" />
                                <p>High Resistance to All <br />
                                    Climatic Conditions
                                </p>
                            </div>
                            <div class="col-lg-2 col-xl-2 col-md-6 col-sm-6 d-icons">
                                <img src="../assets/images/icons/3.jpg" />
                                <p>Bonded with Melamine <br />
                                    FortifiedUrea Formaldehyde <br />
                                    Synthetic Adhesive
                                </p>
                            </div>
                            <div class="col-lg-2 col-xl-2 col-md-6 col-sm-6 d-icons">
                                <img src="../assets/images/icons/4.jpg" />
                                <p>Lifetime Warranty
                                    <br /> On Premium Offerings
                                </p>
                            </div>
                            <div class="col-lg-2 col-xl-2 col-md-6 col-sm-6 d-icons">
                                <img src="../assets/images/icons/5.jpg" />
                                <p>Lifetime Warranty
                                    <br /> On Premium Offerings
                                </p>
                            </div> -->
                                </template>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="hidden-sm" style="padding-top:0px;">
                <div class="container">
                    <div class="product-tab">
                        <div class="tab">

                            <ul class="main-ul">
                                <li :class="boxId == 1 ? 'tab-active mb-10px' : ''" @click="boxChange(1)">Description
                                </li>
                                <li :class="boxId == 2 ? 'tab-active mb-10px' : ''" @click="boxChange(2)">Specifications
                                </li>
                                <li :class="boxId == 3 ? 'tab-active mb-10px' : ''" @click="boxChange(3)">Reviews </li>
                                <!--<li>Enquiry Form</li> -->
                            </ul>
                            <div class="tab-container shadow">

                                <!--<div class="respon"><span class="span active"></span>Description </div>--->

                                <div class="contant s1 fontchange" v-if="boxId == 1" style="display: block;padding: 3%;"
                                    v-html="productData.description">
                                </div>

                                <div class="contant s1" v-else-if="boxId == 2" style="display: block;padding: 3%;">

                                    <table class="table"
                                        v-if="productData.items != 'null' || productData.items != null || productData.items != ''">
                                        <template v-for="idata in JSON.parse(productData.items)">
                                            <tr>
                                                <td class="name-td">
                                                    {{ idata.name }}
                                                </td>
                                                <td class="value-td">
                                                    {{ idata.value }}
                                                </td>
                                            </tr>
                                        </template>
                                    </table>
                                </div>

                                <div class="contant s1" v-else-if="boxId == 3" style="display: block;padding: 3%;">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <span class="rate-heading">Rating : </span>
                                            </br></br>
                                            <star-rating v-model="ratings" v-if="hideSection == true"
                                                :read-only="true" />
                                            <star-rating v-model="ratings" v-else />
                                            </br></br>
                                            <span class="rate-heading">Review : </span></br></br>

                                            <textarea v-if="hideCmd == true" class="review-text-area" disabled
                                                name="cmd" rows="5"></textarea>
                                            <textarea v-else class="review-text-area" v-model="cmd" name="cmd"
                                                rows="5"></textarea>

                                            <button class="btn buy-now-btn" @click="saveReview()">Submit</button>

                                        </div>

                                        <div class="col-md-8">
                                            <div class="comment-area">
                                                <template v-for="rdata in reviewData">

                                                    <div class="comment-box" :key="rdata.id">
                                                        <h5>{{ rdata.username }}</h5>
                                                        <star-rating :star-size="20" :rating="rdata.rating"
                                                            :read-only="true" />

                                                        <p class="comment-p">{{ rdata.comment }}</p>

                                                    </div>

                                                </template>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section class="hidden-lg">
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Description
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <strong>This is the first item's accordion body.</strong> It is shown by default, until
                                the collapse plugin adds the appropriate classes that we use to style each element.
                                These classes control the overall appearance, as well as the showing and hiding via CSS
                                transitions. You can modify any of this with custom CSS or overriding our default
                                variables. It's also worth noting that just about any HTML can go within the
                                <code>.accordion-body</code>, though the transition does limit overflow.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Specifications
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <strong>This is the second item's accordion body.</strong> It is hidden by default,
                                until the collapse plugin adds the appropriate classes that we use to style each
                                element. These classes control the overall appearance, as well as the showing and hiding
                                via CSS transitions. You can modify any of this with custom CSS or overriding our
                                default variables. It's also worth noting that just about any HTML can go within the
                                <code>.accordion-body</code>, though the transition does limit overflow.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Reviews
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <strong>This is the third item's accordion body.</strong> It is hidden by default, until
                                the collapse plugin adds the appropriate classes that we use to style each element.
                                These classes control the overall appearance, as well as the showing and hiding via CSS
                                transitions. You can modify any of this with custom CSS or overriding our default
                                variables. It's also worth noting that just about any HTML can go within the
                                <code>.accordion-body</code>, though the transition does limit overflow.
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="sec-space">
                <div class="sec-heading">
                    <h1 class="h1"><span>Similar </span> Products</h1>
                </div>
                <div class="section mcb-section equal-height-wrap">
                    <div class=" mcb-section-inner">
                        <!--  -->
                        <div class="container">
                            <div class="product-range-list feature-product">
                                <ul class="row feature-slider hidden-sm">
                                    <!--<ProductSlider :product-data="productData.related"></ProductSlider>--->
                                    <carousel :autoplay='true' :loop="true"
                                        :perPageCustom="[[300, 1], [400, 1], [500, 1], [768, 2], [1024, 3]]">
                                        <template v-for="(pdata, index) in productData.related">
                                            <slide :key="pdata.id">
                                                <li class="" style="padding:10px 10px">
                                                    <div class="theme-border m-text-center bg-white">
                                                        <div class="pro-img flip-box">

                                                            <router-link :to="'/productdetails/' + pdata.id">
                                                                <div class="flip-box-inner">
                                                                    <div class="flip-box-front home-product-list">
                                                                        <img :src="$root.Product + '/' + pdata.front_image"
                                                                            class="change-hover-img" :alt="pdata.name"
                                                                            :title="pdata.name">
                                                                    </div>
                                                                    <div class="flip-box-back home-product-list">
                                                                        <img :src="$root.Product + '/' + pdata.front_image"
                                                                            class="change-hover-img" :alt="pdata.name"
                                                                            :title="pdata.name">
                                                                    </div>
                                                                </div>
                                                            </router-link>
                                                        </div>

                                                        <div class="prod-code text-center">
                                                            <h3>
                                                                <router-link :to="'/productdetails/' + pdata.id">
                                                                    {{ pdata.name }}</router-link>
                                                            </h3>

                                                        </div>
                                                        <input type="hidden" :class="'cart_product_id' + pdata.id"
                                                            :value="pdata.id">
                                                        <input type="hidden" class="productrangedesc2" value="3874">
                                                        <div class="prod-name text-center">
                                                            <a>{{ pdata.category_name }}
                                                            </a>
                                                        </div>
                                                        <div class="size-thik hidden-sm"
                                                            v-if="pdata.size != 'null' || pdata.thickness != 'null'">


                                                            <p> Thickness : {{ pdata.thickness }}


                                                            </p>

                                                            <p> Size : {{ pdata.size }}


                                                            </p>



                                                            <div class="select-error-size errorsize2"
                                                                style="display:none;">
                                                                Please select size</div>

                                                        </div>

                                                        <div id="pr2" class="">

                                                            <!-- <div class="price-div">
                                                                <div class="cut-price">
                                                                    <i class="fa fa-inr" aria-hidden="true"></i>{{
                                                                            pdata.purchese_cost
                                                                    }}
                                                                </div>
                                                                <div class="price priceid2">
                                                                    <i class=" fa fa-inr" aria-hidden="true"></i>{{
                                                                            pdata.selling_cost
                                                                    }}
                                                                </div>

                                                            </div>  -->

                                                            <div class="row" style="padding:20px;">
                                                                <div class="col-6">
                                                                    <div class="d-price">

                                                                        <div class="cut-price" style="font-size: 14px;">
                                                                            <i class="fa fa-inr"
                                                                                aria-hidden="true"></i>{{
                                                                                        pdata.purchese_cost
                                                                                }}
                                                                        </div>
                                                                        <br />
                                                                        <i class="fa fa-inr" aria-hidden="true"></i>
                                                                        {{ pdata.selling_cost }}
                                                                        <br />
                                                                        <span style="font-size:12px;color:black;">incl.
                                                                            GST</span>

                                                                        <span class="dark-bg hide">33% Off</span>

                                                                    </div>

                                                                    <i class="fa fa-inr" aria-hidden="true"></i>
                                                                    {{ getWithoutGst(pdata.selling_cost) }}
                                                                    <br />
                                                                    <span style="font-size:12px;color:black;">excl.
                                                                        GST</span>

                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="d-price">
                                                                        <i class="fa fa-inr" aria-hidden="true"></i>
                                                                        {{ getWithoutGst(pdata.per_sq_ft) }}
                                                                        <br />
                                                                        <span style="font-size:12px;color:black;">/
                                                                            Sq.ft</span>
                                                                    </div>
                                                                </div>

                                                            </div>


                                                            <div class="prod-price">
                                                                <div class="sav-per">you save: <span> 0(0%
                                                                        Off)</span></div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-6 col-6 text-center hover hover-clr"
                                                                    style="border-right: 2px solid #ffd7bb;">
                                                                    <div class="add-com-p"
                                                                        @click="add_to_cart(pdata.id, pdata.name, 1, pdata.selling_cost)">
                                                                        <i class="fa fa-shopping-cart"
                                                                            aria-hidden="true"></i>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6 col-6 text-center hover hover-clr">
                                                                    <div class="add-com-p" @click="whishList(pdata.id)">
                                                                        <i class="fa fa-heart" aria-hidden="true"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </slide>
                                        </template>
                                    </carousel>

                                </ul>

                            </div>
                        </div>
                        <!--  -->
                    </div>
                </div>
            </section>
            <!-- Main Footer -->
            <Footer />
            <!-- End Main Footer -->
        </div>

    </body>
</template>

<script>
// @ is an alias to /src
import Vue from 'vue'
import ProductSlider from '@/components/ProductSlider.vue'
import Header3 from '@/components/Header_3.vue'
import Footer from '@/components/Footer.vue'
import axios from 'axios'
import imageZoom from 'vue-image-zoomer';
import StarRating from 'vue-star-rating'

import ZoomOnHover from "vue-zoom-on-hover";
Vue.use(ZoomOnHover);

import {
    Carousel,
    Slide
} from 'vue-carousel';

export default {
    name: 'ProductDetailsView',
    components: {
        Header3,
        Footer,
        Carousel,
        imageZoom,
        Slide,
        ProductSlider,
        StarRating,

    },
    data() {
        return {
            ip_address: JSON.parse(localStorage.getItem('ip_address')),
            uid: localStorage.getItem("uid"),
            productData: [],
            subCategoryData: [],
            pid: null,
            checkData: false,
            mainImage: "",
            allimages: "",
            boxId: 1,
            ratings: 0,
            cmd: null,
            hideSection: false,
            hideCmd: false,
            reviewData: [],
            checkpincode: null,
            checkpin: 0,
            qtyMain: 1,
            featureData: [],

        }
    },
    mounted() {

        this.getProductDetail();
        this.getVariant();
        if (this.uid > 0) {
            this.checkRating();
        }


        this.getReviewRating();

        setTimeout(() => {
            window.scrollTo(0, 0);

        }, 1000);


    },
    watch: {
        productData: function (vl) {
            this.productData = vl;

            this.checkData = true;
        },
        '$route.params.pid': function (vl) {
            this.getProductDetail();
            this.getVariant();
            window.scrollTo(0, 0);
        },
        hideSection: function (vl) {
            this.hideSection = vl;
        },
        ratings: function (vl) {
            if (vl != 0 || vl != '0') {
                this.saveRating();

            }
            //console.log(vl);

        },
        qtyMain: function (vl) {
            this.qtyMain = vl;
            console.log(vl);
        }

    },
    methods: {
        getWithoutGst(oc) {
            var amt = (oc * (100 / (100 + 18)));
            return amt.toFixed(2);
        },
        changeImage(img) {
            this.myVideo = false
            this.mainImage = img;
        },
        getProductDetail() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getProductDetail',
                    id: this.$route.params.pid,
                }
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;
                ev.featureData = JSON.parse(data.product.feature);
                // console.log(featureData)
                if (data.status == "success") {
                    ev.productData = data.product
                    ev.mainImage = ev.productData.front_image;
                    if (ev.productData.image_collection) {
                        ev.allimages = JSON.parse(ev.productData.image_collection);
                    }
                    //console.log(JSON.parse(ev.productData.items));
                } else {
                    ev.$toasted.global.error({
                        message: data.msg
                    });
                }
            })
        },
        getVariant() {
            var ev = this;

            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getVariantProduct',
                    pid: this.pid,

                }
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;

                if (data.status == "success") {

                    ev.subCategoryData = data.data

                } else {
                    ev.$toasted.global.error({
                        message: data.msg
                    });
                }

            })
        },
        changeVariant(pId, updateId, index, vname) {
            var ev = this;
            var vid = updateId.target.value;

            if (vname == 'size') {
                var size = updateId.target.value;
                var thickness = document.getElementById("thickness" + index).value
            } else {
                var size = document.getElementById("size" + index).value;
                var thickness = updateId.target.value;
            }

            //console.log(size+'--->'+thickness);

            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getVariantSingle',
                    vid: vid,
                    size: size,
                    thickness: thickness,
                    pid: pId,
                    vname: vname,

                }
            }).then(function (response) {
                console.log(response.data);
                var data = response.data;
                var newAry = [];
                if (data.status == "success") {
                    ev.checkData = false;
                    ev.productData = data.data;
                    ev.checkData = true;

                } else {
                    ev.$toasted.global.error({
                        message: 'PLease Select Size'
                    });
                }

            })

        },
        // changeVariant(pId, updateId, index, vname) {
        //     var ev = this;
        //     var vid = updateId.target.value;

        //     axios({
        //         method: 'POST',
        //         url: this.$root.URL_ROOT + 'api.php',
        //         data: {
        //             type: 'getVariantSingle',
        //             vid: vid,

        //         }
        //     }).then(function (response) {
        //         //console.log(response.data);
        //         var data = response.data;
        //         //console.log(data.status);
        //         var newAry = [];
        //         if (data.status == "success") {
        //             ev.checkData = false;
        //             ev.productData = data.data;
        //             ev.checkData = true;
        //             //console.log(ev.productData);

        //         } else {
        //             ev.$toasted.global.error({
        //                 message: 'PLease Select Size'
        //             });
        //         }

        //     })

        // },
        boxChange(ids) {
            this.boxId = ids;
        },
        saveReview() {
            var ev = this;
            if (this.uid == 'null' || this.uid == '' || this.uid == null) {
                ev.$toasted.global.error({
                    message: 'PLease login first'
                });
                console.log('error');
            } else if (this.cmd == '' || this.cmd == null || this.cmd == 'null') {
                ev.$toasted.global.error({
                    message: 'PLease enter review'
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'api.php',
                    data: {
                        type: 'saveReview',
                        uid: this.uid,
                        cmd: this.cmd,
                        pid: this.$route.params.pid,

                    }
                }).then(function (response) {
                    //console.log(response.data);
                    var data = response.data;

                    var newAry = [];
                    if (data.status == "success") {

                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.hideSection = true;
                        ev.checkRating();
                        ev.getReviewRating();

                    } else {
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }

                })
            }
        },
        checkRating() {
            var ev = this;

            if (this.uid == 'null' || this.uid == '' || this.uid == null) {
                ev.$toasted.global.error({
                    message: 'PLease login first'
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'api.php',
                    data: {
                        type: 'checkRating',
                        uid: this.uid,
                        pid: this.$route.params.pid,
                        rating: this.ratings,

                    }
                }).then(function (response) {
                    //console.log(response.data);
                    var data = response.data;

                    var newAry = [];
                    if (data.status == "success") {
                        ev.ratings = data.data.rating;
                        if (data.data.rating == '1' || data.data.rating == 1) {
                            ev.ratings = 1
                        } else if (data.data.rating == '2' || data.data.rating == 2) {
                            ev.ratings = 2
                        } else if (data.data.rating == '3' || data.data.rating == 3) {
                            ev.ratings = 3
                        } else if (data.data.rating == '4' || data.data.rating == 4) {
                            ev.ratings = 4
                        } else if (data.data.rating == '5' || data.data.rating == 5) {
                            ev.ratings = 5
                        }

                        if (data.data.rating != 0 || data.data.rating != '0') {
                            ev.hideSection = true;
                        } else {
                            ev.hideSection = false;

                        }

                        if (data.data.comment == '' || data.data.comment == null || data.data.comment == 'null') {
                            ev.hideCmd = false;
                        } else {
                            ev.hideCmd = true;
                        }

                    }

                })
            }
        },
        saveRating() {
            var ev = this;
            //console.log(this.rating);
            if (this.ratings == 'null' || this.ratings == '' || this.ratings == null) {
                ev.$toasted.global.error({
                    message: 'PLease select rating'
                });
            } else if (this.uid == null || this.uid == '' || this.uid == 'null') {
                ev.$toasted.global.error({
                    message: 'PLease login first'
                });
                //this.ratings=0;
            }
            else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'api.php',
                    data: {
                        type: 'saveRatings',
                        uid: this.uid,
                        rating: this.ratings,
                        pid: this.$route.params.pid,

                    }
                }).then(function (response) {
                    console.log(response.data);
                    var data = response.data;

                    var newAry = [];
                    if (data.status == "success") {

                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.hideSection = true;
                        ev.checkRating();
                        ev.getReviewRating();

                    } else {
                        // ev.$toasted.global.error({
                        //     message: data.msg
                        // });
                    }

                })
            }
        },
        getReviewRating() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getProductReviewRating',
                    pid: this.$route.params.pid,
                }
            }).then(function (response) {

                var data = response.data;
                if (data.status == "success") {
                    ev.reviewData = data.data;
                }
            })
        },
        checkPin() {
            var ev = this;
            if (this.checkpincode == null || this.checkpincode == '') {
                ev.$toasted.global.error({
                    message: 'PLease enter pincode'
                });
            } else {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'api.php',
                    data: {
                        type: 'pincodeCheck',
                        pincode: this.checkpincode,
                    }
                }).then(function (response) {

                    var data = response.data;
                    console.log(data);
                    if (data.status == 'success') {
                        ev.checkpin = 1;
                    } else {
                        ev.checkpin = 2;
                    }
                })
            }

        }

    }
}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@900&display=swap');

.fontchange {
    font-family: 'Ubuntu', sans-serif;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 30px;
}
</style>