<template>

    <body
        class="style-simple button-stroke layout-full-width if-overlay if-border-hide no-content-padding no-shadows header-classic minimalist-header-no sticky-tb-color ab-hide subheader-both-center menu-line-below-80-1 menuo-sub-active menuo-no-borders menuo-right logo-valign-top logo-no-margin mobile-tb-hide mobile-side-slide mobile-mini-mr-ll mobile-tr-header tr-header tr-menu">
        <div id="Wrapper">
            <div id="Header_wrapper">
                <Header />
            </div>
            <!--Page Title-->
            <section class="page-title top-bg-img">
                <div class="auto-container clearfix">
                    <h1>Gallery</h1>
                    <ul class="bread-crumb clearfix">
                         <li><router-link to="/">Home</router-link></li>
                        <li>Gallery</li>
                    </ul>
                </div>
            </section>
            <!--End Page Title-->

            <!-- About Us -->
            <section class="about-us1">
                <div class="auto-container">
                    <div class="row clearfix">
                        <!-- Content Column -->
                        <template v-for="(gdata,index) in galleryData">
                            <div class="col-md-4 pic" :key="index"  @click="showImg(index)">
                                <!-- <img :src="$root.Images_URL+'Gallery/'+gdata.image" class="img-responsive gallery-img" > -->
                              
                                    <img :src="gdata"  class="img-responsive gallery-img"  @click="showImg(index)">
                                
                            </div>
                        </template>

                    </div>
                </div>
            </section>

            <vue-easy-lightbox :visible="visible" :imgs="galleryData" :index="index" @hide="handleHide"></vue-easy-lightbox>
            <!-- End About Us -->


            <!-- Main Footer -->
            <Footer />
            <!-- End Main Footer -->
        </div>

    </body>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header_3.vue";
import Footer from "@/components/Footer.vue";
import axios from 'axios';
import VueEasyLightbox from 'vue-easy-lightbox'
//import 'vue-easy-lightbox/external-css/vue-easy-lightbox.css'

import {
    Carousel,
    Slide
} from 'vue-carousel';

export default {
    name: "AboutUsView",
    components: {
        Header,
        Footer,
        Carousel,
        Slide,
        VueEasyLightbox,
    },
    data() {
        return {
            galleryData: [],
            visible: false,
            index: 0,
        }
    },
    mounted() {
        this.getGallery();
        setTimeout(() => {
            window.scrollTo(0, 0);

        }, 1000);


    },
    methods: {
        showImg(ins) {
            this.index = ins
            this.visible = true
            console.log(ins)
        },
        handleHide() {
            this.visible = false
        },
        getGallery() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getGallery',
                }
            }).then(function (response) {
                console.log(response.data);
               
                var data = response.data.data;
               
                for(var i=0;i< data.length;i++) {
                      ev.galleryData.push(ev.$root.Images_URL+'Gallery/'+data[i].image);
                     // console.log(i)
                }
                  
               
                

            })

        },
    }

};
</script>

<style>
.gallery-img {
    height: 300px;
    width: 100%;
    margin: 5px;
}
</style>
