<template>
<body class="style-simple button-stroke layout-full-width if-overlay if-border-hide no-content-padding no-shadows header-classic minimalist-header-no sticky-tb-color ab-hide subheader-both-center menu-line-below-80-1 menuo-sub-active menuo-no-borders menuo-right logo-valign-top logo-no-margin mobile-tb-hide mobile-side-slide mobile-mini-mr-ll mobile-tr-header tr-header tr-menu">
    <div id="Wrapper">
        <div id="Header_wrapper">
            <Header />
        </div>
       

        <!-- About Us -->
        <section class="about-us container">

            <!-- Call Back Section -->
            <section class="call-back-section contact-bg-img" style="">
                <div class="auto-container">
                    <div class="outer-box">
                        <div class="row no-gutters">
                            <!-- Text Column -->
                          
                            <!-- Form Column -->
                            <div class="col-lg-3 col-md-3 col-sm-12 form-group"></div>
                            <div class="form-column col-xl-6 col-lg-12 col-md-6 col-sm-12">
                                <div class="inner-column">
                                    <div class="request-form">
                                        <h3>Login</h3>

                                        <!--Comment Form-->
                                       
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <input type="text" name="username" v-model="username" placeholder="Username" required="">
                                                </div>

                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <input type="password" name="password" v-model="password" placeholder="Password" required="" style="width: 100%">
                                                </div>


                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <button class="theme-btn btn-style-four" type="submit" @click="login" name="submit-form"><span class="btn-title">Login</span>
                                                        <span></span> <span></span> <span></span> <span></span>
                                                        <span></span></button>
                                                </div>

                                                <div class="col-md-12">
                                                    <div class="txt-center ">
                                                        <h6 class="clr-white"><router-link class="clr-white" to="/register">Create New Account</router-link></h6>
                                                    </div>

                                                    <div class="txt-center frg-pass">
                                                        <h6 class="mrg-t-15">
                                                            <router-link class="frg-pass-link" to="/forgot">Forgot Password</router-link>
                                                        </h6>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                           
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!--End Call Back Section -->

        </section>
        <!-- End About Us -->

        <!-- Main Footer -->
        <Footer />
        <!-- End Main Footer -->
    </div>

</body>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header_3.vue";
import Footer from "@/components/Footer.vue";
import axios from 'axios';

import {
    Carousel,
    Slide
} from 'vue-carousel';

export default {
    name: "AboutUsView",
    components: {
        Header,
        Footer,
        Carousel,
        Slide
    },
    data() {
        return {
            testimonialData: [],
            username: null,
            password:null,

        }
    },
    mounted() {
       
        setTimeout(() => {
            window.scrollTo(0,0);
           
        }, 1000);
        
    },
    methods: {

        login() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'userlogin',
                    username: this.username,
                    password: this.password,
                }
            }).then(function (response) {
                console.log(response.data);
                var data = response.data;
                if (data.status == "success") {

                   localStorage.setItem('uid',data.session.uid);
                   localStorage.setItem('username',data.session.username);
                   //console.log(localStorage.getItem('uid'));
                   ev.$router.push('/');

                } else {
                    ev.$toasted.global.error({
                        message: data.msg
                    });
                }
                

            })

        },
    }

};
</script>
<style>

    .frg-pass-link{
        color: #200b00 !important;
        letter-spacing: 0px;
        text-decoration: underline;
        margin-top:10px;
    }
    .mrg-t-15{
        margin-top: 15px;
    }

</style>