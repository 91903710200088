import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/HomeView.vue'
import Category from '../views/Category.vue'
import About from '../views/AboutUs.vue'
import ProductDetails from '../views/ProductDetails.vue'
import ComingSoon from '../views/Coming.vue'
import Tools from '../views/Tools.vue'
import Gallery from '../views/Gallery.vue'
import Testimonial from '../views/Testimonial.vue'
// import Product from '../views/Product.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Cart from '../views/Cart.vue'
import Checkout from '../views/Checkout.vue'
import Contact from '../views/Contact.vue'
import MyProfile from '../views/MyAccount.vue'
import Invoice from '../views/Invoice.vue'
import Success from '../views/Success.vue'
import Success_COD from '../views/Success_COD.vue'
import WishList from '../views/WishList.vue'
import Forgot from '../views/Forgot.vue'
import Privacy from '../views/Privacy.vue'
// import MyOrder from '../views/MyOrder.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,

    },
    {
        path: '/category/:cid',
        name: 'Category',
        component: Category,

    },
    {
        path: '/aboutus',
        name: 'About',
        component: About
    },
    {
        path: '/productdetails/:pid',
        name: 'ProductDetails',
        component: ProductDetails
    },
    {
        path: '/tools',
        name: 'Tools',
        component: Tools
    },
    {
        path: '/gallery',
        name: 'Gallery',
        component: Gallery
    },
    {
        path: '/testimonial',
        name: 'Testimonial',
        component: Testimonial
    },
    {
        path: '/blog',
        name: 'Blog',
        component: ComingSoon
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/invoice/:id',
        name: 'Invoice',
        component: Invoice
    },
    {
        path: '/success_cod',
        name: 'Success_COD',
        component: Success_COD
    },
    {
        path: '/success',
        name: 'Success',
        component: Success
    },
    {
        path: '/wishlist',
        name: 'WishList',
        component: WishList
    },


    // {
    //     path: '/product/:id',
    //     name: 'Product',
    //     component: Product
    // },

    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: '/cart',
        name: 'Cart',
        component: Cart
    },
    // {
    //     path: '/mycart',
    //     name: 'Cart',
    //     component: Cart
    // },
    {
        path: '/checkout',
        name: 'Checkout',
        component: Checkout
    },
    // {
    //     path: '/contact',
    //     name: 'Contact',
    //     component: Contact
    // },
    {
        path: '/myprofile',
        name: 'MyProfile',
        component: MyProfile
    },
    {
        path: '/forgot',
        name: 'Forgot',
        component: Forgot
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy
    },
    // {
    //     path: '/myorder',
    //     name: 'MyOrder',
    //     component: MyOrder
    // },


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router