<template>
    <footer id="Footer" class="clearfix">
        <div class="widgets_wrapper" style="padding:10px 0 10px;">
            <div class="container">
                <div class="column one-fourth">
                    <aside class="widget_text widget widget_custom_html">
                        <div class="textwidget custom-html-widget">
                            <div class="image_frame image_item no_link scale-with-grid no_border"
                                style="margin-bottom:30px;">
                                <div class="image_wrapper">
                                    <img class="scale-with-grid" src="../assets/images/logo.webp">
                                </div>
                            </div>
                            <!-- <p>
                                Tabore et dolore
                                <br> magna aliqua.
                                <br> Ut enim ad minim veniam, quis nostrud
                            </p> -->
                            <a href="https://www.facebook.com/calibreply/" style="font-size:20px;"><i class="icon-facebook"></i></a>
                           <a href="https://twitter.com/EcogenIndustri2" style="font-size:20px;"><i class="icon-twitter"></i></a>
                            <a href="https://in.pinterest.com/calibreply/" style="font-size:20px;"><i class="icon-pinterest"></i></a>
                            <a href="https://www.linkedin.com/in/harish-venkatesh-01a98a1a3/" style="font-size:20px;"><i class="icon-linkedin"></i></a>
                        </div>
                    </aside>
                </div>
                <div class="column one-third">
                    <aside class="widget_text widget widget_custom_html">
                        <div class="textwidget custom-html-widget">
                            <ul>
                                <li>
                                    <router-link to="/"><img src="../assets/images/arrow.png"> <b>Home</b>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/aboutus"><img src="../assets/images/arrow.png"> <b>About Us</b>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/tools"><img src="../assets/images/arrow.png"> <b>Tools</b>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/gallery"><img src="../assets/images/arrow.png"> <b>Gallery</b>
                                    </router-link>
                                </li>


                            </ul>
                        </div>
                    </aside>
                </div>
                <div class="column one-third">
                    <aside class="widget_text widget widget_custom_html">
                        <div class="textwidget custom-html-widget">
                            <ul>
                                <li>
                                    <router-link to="/testimonial"><img src="../assets/images/arrow.png">
                                        <b>Testimonials</b>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/blog"><img src="../assets/images/arrow.png"> <b>Blog</b>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/contact"><img src="../assets/images/arrow.png"> <b>Contact Us</b>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/privacy"><img src="../assets/images/arrow.png"> <b>privacy
                                            policy</b>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </aside>
                </div>

            </div>
        </div>
        <div class="footer_copy">
            <div class="container">
                <div class="column one1" style="margin-bottom:10px;">
                    <div class="copyright">
                        © 2022 Calibreply <a target="_blank" rel="nofollow" href="#">5inewebsolutions</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import axios from 'axios'
import {
    Carousel,
    Slide
} from 'vue-carousel';

export default {
    // mixins: [MyMixin],
    components: {
        Carousel,
        Slide
    },
    data() {
        return {
            chknav: this.$route.name,
            ip_address: JSON.parse(localStorage.getItem('ip_address')),
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),


        }
    },
    mounted() {

        this.myIpData();
    },
    watch: {

    },
    methods: {

        // myIpData() {
        //     var ip_address = JSON.parse(localStorage.getItem('ip_address'))

        //     if (ip_address == null) {
        //         var min = 10000000000
        //         var max = 99999999999
        //         var num = Math.floor(Math.random() * (max - min + 1)) + min
        //         localStorage.setItem('ip_address', JSON.stringify(num))

        //     } else {

        //     }
        // },

        mouseoverFun(vl) {

            this.showP1 = vl
        },
        subMenu(status, id) {

            if (status == 'true') {
                this.subMenuActiveId = id;
            } else if (status == 'false') {
                this.subMenuActiveId = 0;
            }

        },



    },

}
</script>
