<template>
<header id="Header">
    <div id="mySidenav" class="sidenav hidden-lg">
        <a href="javascript:void(0)" class="closebtn" @click="closeNav()">&times;</a>
        <ul id="menu-menu" class="menu menu-main header-menu">
                                <li :class="chknav=='Home'?'m-menu current-menu-item':'m-menu'">
                                    <router-link to="/"><span>Home</span></router-link>
                                </li>
                                <li :class="chknav=='Category'?'m-menu current-menu-item':'m-menu'" @mouseover="upHere = true" @mouseleave="upHere = false">
                                    <a><span>Products</span></a>
                                     <ul class="sub-menu show-sub-menu" v-if="upHere">
                                            <template v-for="mdata in menuData">
                                                <li class="submenu-li" :key="mdata.id" @mouseover="subMenu('true',mdata.id)"  @mouseleave="subMenu('false',mdata.id)" >
                                                    <router-link class="submenu-link" :to="'/category/'+mdata.id"> {{mdata.heading}}</router-link>
                                                    <ul :class="mdata.id==subMenuActiveId?'super-show-sub-menu':''" v-if="mdata.id==subMenuActiveId">
                                                    <template v-for="sdata in mdata.menus">
                                                        <li :key="sdata.id">
                                                            <router-link class="submenu-link" :to="'/category/'+sdata.id">{{sdata.heading}}</router-link>
                                                        </li>
                                                    </template>
                                                    </ul>
                                                </li>
                                            </template>
                                        </ul>
                                </li>
                                <li :class="chknav=='Tools'?'m-menu current-menu-item':'m-menu'">
                                    <router-link to="/tools"><span>Tools</span></router-link>
                                </li>
                                <li :class="chknav=='Gallery'?'m-menu current-menu-item':'m-menu'">
                                    <router-link to="/gallery"><span>Gallery</span></router-link>
                                </li>
                                 <li :class="chknav=='About'?'m-menu current-menu-item':'m-menu'">
                                    <router-link to="/aboutus"><span>About Us</span></router-link>
                                </li>
                                <li :class="chknav=='Testimonial'?'m-menu current-menu-item':'m-menu'">
                                    <router-link to="/testimonial"><span>Testimonials</span></router-link>
                                </li>
                               
                                <li :class="chknav=='Blog'?'m-menu current-menu-item':'m-menu'">
                                    <router-link to="/blog"><span>Blog</span></router-link>
                                </li>
                                <li :class="chknav=='Contact'?'m-menu current-menu-item':'m-menu'">
                                    <router-link to="/contact"><span>Contact Us</span></router-link>
                                </li>
                                 <li  v-if="uid" :class="chknav=='MyProfile'?'m-menu current-menu-item':'m-menu'">
                                    <router-link to="/myprofile"><span>My Profile</span></router-link>
                                </li>
                                <li v-else :class="chknav=='Login'?'m-menu current-menu-item':'m-menu'">
                                    <router-link to="/login"><span>Login</span></router-link>
                                </li>
                               
                            </ul>
    </div>
    <div class="header_placeholder"></div>
    <div id="Top_bar" class="hidden-sm">
        <div class="">
            <div class="column unser-column one card calib-header-inner">
                <div class="row">
                    <div class="logo-pd col-sm-6 col-md-2 col-lg-2">
                        <router-link id="logo" to="/" title="BeCarpenter 2 - BeTheme" data-height="68" data-padding="0">
                            <img class="logo-main scale-with-grid header-3-logo" src="../assets/images/logo.webp" data-height="68">
                        </router-link>
                    </div>
                    <div class="menu_wrapper col-sm-6 col-md-8 col-lg-8 d-flex">
                        <nav id="menu" class="h-100 ml-auto">
                            <ul id="menu-menu" class="menu menu-main header-menu">
                                <li :class="chknav=='Home'?'m-menu current-menu-item':'m-menu'">
                                    <router-link to="/"><span>Home</span></router-link>
                                </li>
                                <li :class="chknav=='Category'?'m-menu current-menu-item':'m-menu'" @mouseover="upHere = true" @mouseleave="upHere = false">
                                    <a><span>Products</span></a>
                                     <ul class="sub-menu show-sub-menu" v-if="upHere">
                                            <template v-for="mdata in menuData">
                                                <li class="submenu-li" :key="mdata.id" @mouseover="subMenu('true',mdata.id)"  @mouseleave="subMenu('false',mdata.id)" >
                                                    <router-link class="submenu-link" :to="'/category/'+mdata.id"> {{mdata.heading}}</router-link>
                                                    <ul :class="mdata.id==subMenuActiveId?'super-show-sub-menu':''" v-if="mdata.id==subMenuActiveId">
                                                    <template v-for="sdata in mdata.menus">
                                                        <li :key="sdata.id">
                                                            <router-link class="submenu-link" :to="'/category/'+sdata.id">{{sdata.heading}}</router-link>
                                                        </li>
                                                    </template>
                                                    </ul>
                                                </li>
                                            </template>
                                        </ul>
                                </li>
                                <li :class="chknav=='Tools'?'m-menu current-menu-item':'m-menu'">
                                    <router-link to="/tools"><span>Tools</span></router-link>
                                </li>
                                <li :class="chknav=='Gallery'?'m-menu current-menu-item':'m-menu'">
                                    <router-link to="/gallery"><span>Gallery</span></router-link>
                                </li>
                                 <li :class="chknav=='About'?'m-menu current-menu-item':'m-menu'">
                                    <router-link to="/aboutus"><span>About Us</span></router-link>
                                </li>
                                <li :class="chknav=='Testimonial'?'m-menu current-menu-item':'m-menu'">
                                    <router-link to="/testimonial"><span>Testimonials</span></router-link>
                                </li>
                               
                                <li :class="chknav=='Blog'?'m-menu current-menu-item':'m-menu'">
                                    <router-link to="/blog"><span>Blog</span></router-link>
                                </li>
                                <li :class="chknav=='Contact'?'m-menu current-menu-item':'m-menu'">
                                    <router-link to="/contact"><span>Contact Us</span></router-link>
                                </li>
                                 <li  v-if="uid" :class="chknav=='MyProfile'?'m-menu current-menu-item':'m-menu'">
                                    <router-link to="/myprofile"><span>My Profile</span></router-link>
                                </li>
                                <li v-else :class="chknav=='Login'?'m-menu current-menu-item':'m-menu'">
                                    <router-link to="/login"><span>Login</span></router-link>
                                </li>
                               
                            </ul>
                        </nav>
                        <a class="responsive-menu-toggle" href="#"><i class="icon-menu-fine"></i></a>
                    </div>
                    <div class="menu_wrapper col-sm-6 col-md-2 col-lg-2">
                        <nav id="menu" class="h-100">
                            <ul id="menu-menu" class="menu menu-main header-menu">
                                <li class="">
                                    <router-link to="/cart">
                                        <span > <i class="fa fa-shopping-cart"></i></span>
                                        <span class="counter-cart" id="cartcount">{{cartCount}}</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/wishlist">
                                        <span class="top-icon" ><i class="fa fa-heart"></i></span>
                                    </router-link>
                                </li>
                            </ul>
                        </nav>
                        <a class="responsive-menu-toggle" href="#"><i class="icon-menu-fine"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hidden-lg">
        <div class="container-fluid">
            <div class=" mobile-row">
                <div class="col-sm-6">
                   <img class="logo-main scale-with-grid m-logo-with" src="../assets/images/logo.webp">
                </div>
                <div class="col-sm-6">
                    <span style="font-size:30px;cursor:pointer;margin-left: 20px;" @click="openNav()">&#9776; </span>
                </div>
            </div>
        </div>
    </div>
</header>
</template>

<script>
 import axios from 'axios'
export default {
    // mixins: [MyMixin],
    components: {},
    data() {
        return {
            chknav: this.$route.name,
            ip_address: JSON.parse(localStorage.getItem('ip_address')),
            uid: localStorage.getItem("uid"),
            role: localStorage.getItem("role"),
            client_id: '22c039caab94b46eb86fc0c0fc1b56ff09d69399',
            client_secret: 'bUM1dWdx+TfemjEVctAKqszthGvQipDOPRerwbVqiOu9hljdJKhsfI5sX+MMDsgHzvvdmJol9wzouzvKkMlG8jBv3eq/T4ehlpilxE68elsw5D3U5xm+frmcA4xIDGk0',
            access_token: 'b10bf31ef34983f1d8e9cdf248eded5a',
            showP1: false,
            checkMenuMobile: false,
            showSubMenuMobile: false,
            productData: [],
            upHere: false,
            menuData: [],
            subMenuActiveId: null,
            cartCount:null,
        }
    },
    mounted() {
        
        this.getCategory();
         this.getCart();
        
    },
    watch: {
        chknav: function (val) {
            this.chknav = val
        },
        showP1: function (val) {
            this.showP1 = val
        }
    },
    methods: {
        openNav() {
            document.getElementById("mySidenav").style.width = "250px";
        },
        closeNav() {
            document.getElementById("mySidenav").style.width = "0";
        },
        mouseoverFun(vl) {
            this.showP1 = vl
        },
        subMenu(status, id) {
            if (status == 'true') {
                this.subMenuActiveId = id;
            } else if (status == 'false') {
                this.subMenuActiveId = 0;
            }
        },
        getCategory() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getMyCategory',
                }
            }).then(function (response) {
                var data = response.data;
                
                if (data.status == 'success') {
                    ev.menuData = data.category;
                }
            })
        },

    },
}
</script>

<style scoped>
body {
    padding: 0px !important;
}

#Top_bar {
    left: 0px !important;
}
#Top_bar #logo {
    display: block;
     height: 60px !important; 
     
     padding: unset !important;
}
.unser-column{
    width: 100% !important;
    margin: auto !important;
    float: unset !important;
}
</style>
