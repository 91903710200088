<template>
<carousel :autoplay='false' :perPageCustom="[[300,1],[400,1],[500, 1],[768, 2], [1024, 3]]" v-if="checkData">
    <template v-for="(pdata,index) in productData">
        <slide :key="pdata.id">
            <li class="" style="padding:10px 10px">
                <div class="theme-border m-text-center bg-white">
                    <div class="pro-img flip-box">

                        <router-link :to="'/productdetails/'+pdata.id">
                            <div class="flip-box-inner">
                                <div class="flip-box-front home-product-list">
                                    <img :src="$root.Product+'/'+pdata.front_image" class="change-hover-img" :alt="pdata.name" :title="pdata.name">
                                </div>
                                <div class="flip-box-back home-product-list">
                                    <img :src="$root.Product+'/'+pdata.front_image" class="change-hover-img" :alt="pdata.name" :title="pdata.name">
                                </div>
                            </div>
                        </router-link>

                    </div>

                    <div class="prod-code text-center">
                        <h3>
                            <router-link :to="'/productdetails/'+pdata.id">{{pdata.name}}</router-link>
                        </h3>

                    </div>
                    <input type="hidden" :class="'cart_product_id'+pdata.id" :value="pdata.id">
                    <input type="hidden" class="productrangedesc2" value="3874">
                    <div class="prod-name text-center">
                        <a>{{pdata.category_name}}
                        </a></div>
                    <div class="size-thik hidden-sm" v-if="pdata.size!='null'|| pdata.thickness!='null'">
                        <!--<p>Select : {{pdata.size?'Size ':''}} {{pdata.thickness?', Thickness ':''}}

                            <select name="size" id="" class="size " tabindex="-1" @change="changeVariant(pdata.id,$event,index)">
                                <option value="" productdataid="2">Select {{pdata.size?'Size ':''}} {{pdata.thickness?', Thickness ':''}}
                                </option>
                                <template v-for="vdata in pdata.variants">

                                    <option :value="vdata.id" v-if="vdata.size==pdata.size && vdata.thickness==pdata.thickness" selected>{{vdata.size?'size : '+vdata.size:''}}{{vdata.thickness?', Thickness : '+vdata.thickness:''}}</option>
                                    <option :value="vdata.id" v-else>{{vdata.size?'size : '+vdata.size:''}}{{vdata.thickness?', Thickness : '+vdata.thickness:''}}</option>

                                </template>
                            </select>
                        </p>-->

                         <p>Select : Thickness 

                            <select name="size" :id="'thickness'+index" class="size " tabindex="-1" @change="changeVariant(pdata.id,$event,index,'thickness')">
                                <option value=""  >Select Thickness </option>
                                <template v-for="sdata in pdata.thicknesslist">

                                    <option :value="sdata.thickness" v-if="sdata.thickness==pdata.thickness" selected>{{sdata.thickness}}</option>
                                    <option :value="sdata.thickness" v-else>{{sdata.thickness}}</option>

                                </template>
                            </select>
                        </p>

                          <p>Select : Size 

                            <select name="size" :id="'size'+index" class="size " tabindex="-1" @change="changeVariant(pdata.id,$event,index,'size')">
                                <option value=""  >Select Size </option>
                                <template v-for="sdata in pdata.sizelist">

                                    <option :value="sdata.size" v-if="sdata.size==pdata.size" selected>{{sdata.size}}</option>
                                    <option :value="sdata.size" v-else>{{sdata.size}}</option>

                                </template>
                            </select>
                        </p>


                        <div class="select-error-size errorsize2" style="display:none;">
                            Please select size</div>

                    </div>

                    

                    <div id="pr2" class="">

                        <div class="price-div">
                            <div class="cut-price">
                                <i class="fa fa-inr" aria-hidden="true"></i>{{pdata.purchese_cost}}
                            </div>
                            <div class="price priceid2">
                                <i class=" fa fa-inr" aria-hidden="true"></i>{{pdata.selling_cost}} - per sq ft
                            </div>

                        </div>
                        <div class="prod-price">
                            <div class="sav-per">you save: <span> 0(0% Off)</span></div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-6 text-center hover hover-clr" style="border-right: 2px solid #ffd7bb;">
                                <div class="add-com-p" @click="add_to_cart(pdata.id, pdata.name, 1, pdata.selling_cost)">
                                    <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="col-md-6 col-6 text-center hover hover-clr">
                                <div class="add-com-p" @click="whishList(pdata.id)">
                                    <i class="fa fa-heart" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </slide>
    </template>
</carousel>
</template>

<script>
import axios from 'axios'

import {
    Carousel,
    Slide
} from 'vue-carousel';

export default {
    name: 'HomeView',
    components: {

        Slide,
        Carousel,

    },
    props: [
        "productData"
    ],
    data() {
        return {
            //productData: [],
            checkData: true,

        }
    },
    mounted() {
        //console.log(this.productData);
    },
    watch: {

        productData: function (vl) {
            this.productData = vl;
            //console.log(vl);
        }
    },
    methods: {
        changeVariant(pId, updateId, index, vname) {
            var ev = this;
            var vid = updateId.target.value;
            //console.log(index);
            if(vname=='size'){
                var size=updateId.target.value;
                var thickness=document.getElementById("thickness"+index).value 
            }else{
                 var size=document.getElementById("size"+index).value;
                 var thickness=updateId.target.value;
            }

            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'getVariantSingle',
                    vid: vid,
                    size: size,
                    thickness: thickness,
                    pid:pId,
                    vname: vname,

                }
            }).then(function (response) {
                //console.log(response.data);
                var data = response.data;
                var newAry = [];
                if (data.status == "success") {
                    //console.log('dasdsadsad');
                    ev.checkData = false;
                    ev.productData[index] = data.data;
                    ev.checkData = true;
                    //console.log(ev.productData[index])
                } else {
                    ev.$toasted.global.error({
                        message: 'PLease Select Size'
                    });
                }

            })

        },
      
    }
}
</script>

<style scoped>
.product-range-list li {
    width: 100%;
}
.VueCarousel-slide{
    flex-basis:unset !important;
}
</style>
