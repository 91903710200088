<template>
<body class="style-simple button-stroke layout-full-width if-overlay if-border-hide no-content-padding no-shadows header-classic minimalist-header-no sticky-tb-color ab-hide subheader-both-center menu-line-below-80-1 menuo-sub-active menuo-no-borders menuo-right logo-valign-top logo-no-margin mobile-tb-hide mobile-side-slide mobile-mini-mr-ll mobile-tr-header tr-header tr-menu">
    <div id="Wrapper">
        <div id="Header_wrapper">
            <Header />
        </div>
        <!--Page Title-->
        <section class="page-title top-bg-img">
            <div class="auto-container clearfix">
                <h1>Checkout</h1>
                <ul class="bread-crumb clearfix">
                    <li><a to="/">Home</a></li>
                    <li>Checkout</li>
                </ul>
            </div>
        </section>
        <!--End Page Title-->

        <!-- About Us -->
        <div class="cart-box-container check-out" v-if="!NoRecordFound">
            <div class="container container-ver2">
                <div class="row">
                    <div class="col-md-7">
                        <!-- <h3 class="title-brand"></h3> -->
                        <div class="caupon-wrap s2 shadow border" style="background:#fff;">
                            <div class="biling-item">
                                <div class="coupons coupon-3">
                                    <label id="toggle2" class="mb-20">
                                        Your Cart Items</label>
                                </div>
                                <div class="billing-adress" id="open1">
                                    <div class="contact-form form-style">
                                        <div class="row">
                                            <div class="box">
                                                <div class="info-order">
                                                    <div class="product-name">
                                                        <table class="table table-bordered">
                                                            <tr class="gift-area-list" v-for="(cart,keys) in cartCount.data" :key="keys">
                                                                <td class="names"> <img :src="$root.URL_ROOT+'uploads/Product/'+cart.front_image" :alt="cart.front_image" width="50" /></td>
                                                                <td class="names">
                                                                    <router-link :to="{name: 'productDetail', params:{id:cart.pid}}" class="a" title=""> {{cart.name}} </router-link>
                                                                </td>
                                                                <td class="names" v-if="cart.gift>0">
                                                                    <div>
                                                                        <a :href="'#openModal-about'+cart.id" class="up-img">Uploaded Image</a>
                                                                        <div :id="'openModal-about'+cart.id" class="modalDialog">
                                                                            <div>
                                                                                <a href="#close" title="Close" class="close">X</a>
                                                                                <img :src="$root.URL_ROOT+'uploads/Cart/'+cart.cart_image" :alt="cart.cart_image" width="300" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="names" v-if="cart.gift_text>0">
                                                                    {{cart.cart_content}}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <!--  -->
                        <div class="caupon-wrap s2" v-if="addressDatas.length">
                            <div class="biling-item">
                                <div @click="open_dashboard2" class="coupons coupon-3" :class="dashboard_box == 'step-1' ? 'active' : ''">
                                    <label id="toggle2">
                                        <i class="fa fa-check color-green" v-if="add_id>0" aria-hidden="true"></i>
                                        Select Address</label>
                                    <label v-if="dashboard_box == 'step-1'" class="pull-right">
                                        <i class="fa fa-eye font-26" aria-hidden="true"></i>
                                    </label>
                                    <label v-else class="pull-right">
                                        <i class="fa fa-eye-slash font-26" aria-hidden="true"></i>
                                    </label>
                                </div>
                                <div class="billing-adress" id="open1" v-if="dashboard_box == 'step-1'">
                                    <div class="contact-form form-style">
                                        <div class="row">
                                            <div :class="userAddress.shippingBilling==true ? 'col-md-12' : 'col-md-6'">
                                                <label>Select Shipping Address</label>
                                                <select v-model="add_id" @change="getSelectedAddress(add_id)" class="form-control">
                                                    <option value="0">Select Shipping Address</option>
                                                    <option :value="getShipping.add_id" v-for="getShipping in getShippingAddressData" :key="getShipping.add_id"> {{getShipping.add1}} </option>
                                                </select>
                                            </div>
                                            <div class="col-md-6" v-if="!userAddress.shippingBilling">
                                                <label>Select Billing Address</label>
                                                <select v-model="billing_id" @change="getSelectedBillingAddress(billing_id)" class="form-control">
                                                    <option value="0">Select Billing address</option>
                                                    <option :value="getShipping.add_id" v-for="getShipping in getBillingAddressData" :key="getShipping.add_id"> {{getShipping.add1}} </option>
                                                </select>
                                            </div>
                                            <div class="col-md-6" v-if="add_id != 0">
                                                <div class="col-md-12 foundation card">
                                                    <h3> Shipping Address </h3>
                                                    <div class=" radio-btn">
                                                        <input type="radio" name="shipping" :id="'radio'+selectedAddress.add_id" class="radio visiblity-none" v-model="add_id" :value="selectedAddress.add_id" />
                                                        <label class="label" :for="'radio'+selectedAddress.add_id"></label>
                                                    </div>
                                                    <div class="col-md-12 foundation_sm">
                                                        <ul>

                                                            <li> {{selectedAddress.add_title}} </li>
                                                            <li><i class="fa fa-envelope-o" aria-hidden="true"></i>{{selectedAddress.email}}</li>
                                                            <li><i class="fa fa-phone" aria-hidden="true"></i> {{selectedAddress.mobile_no}} </li>
                                                            <li><i class="fi flaticon-pin" aria-hidden="true"></i> {{selectedAddress.add1}} </li>
                                                            <li> {{selectedAddress.add2}} </li>
                                                            <li> {{selectedAddress.city}}, {{selectedAddress.c_state}}, <br /> {{selectedAddress.country}}, {{selectedAddress.zipcode}} </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6" v-if="!userAddress.shippingBilling && billing_id != 0">
                                                <div class="col-md-12 foundation card">
                                                    <h3> Billing Address </h3>
                                                    <div class=" radio-btn">
                                                        <input type="radio" name="billing" :id="'radio'+selectedBillingAddress.add_id" class="radio visiblity-none" v-model="billing_id" :value="selectedBillingAddress.add_id" />
                                                        <label class="label" :for="'radio'+selectedBillingAddress.add_id"></label>
                                                    </div>
                                                    <div class="col-md-12 foundation_sm">
                                                        <ul>
                                                            <li> {{selectedBillingAddress.add_title}} </li>
                                                            <li><i class="fa fa-envelope-o" aria-hidden="true"></i>{{selectedBillingAddress.email}}</li>
                                                            <li><i class="fa fa-phone" aria-hidden="true"></i> {{selectedBillingAddress.mobile_no}} </li>
                                                            <li><i class="fi flaticon-pin" aria-hidden="true"></i> {{selectedBillingAddress.add1}} </li>
                                                            <li> {{selectedBillingAddress.add2}} </li>
                                                            <li> {{selectedBillingAddress.city}}, {{selectedBillingAddress.c_state}}, <br /> {{selectedBillingAddress.country}}, {{selectedBillingAddress.zipcode}} </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12 col-md-12 col-12" style="margin-bottom: 20px;align-items: center;display: flex;">
                                                <input class="form-control mb-25" type="checkbox" placeholder="" style="width: 5%;height: 26px;margin: 15px 5px;" @change="checkBillingShipping" v-model="userAddress.shippingBilling" id="sel" name="sel">
                                                <label for="sel">Select Shipping address as billing address</label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4 col-md-4 col-4">
                                                <button class="theme-btn" @click="add_select" v-if="add_id">Continue</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="caupon-wrap s2">
                            <div class="biling-item">
                                <div class="coupon coupon-3" @click="open_dashboard('step-2')" :class="dashboard_box == 'step-2' ? 'active' : ''">
                                    <label id="toggle2">Add Shipping Address</label>
                                </div>
                                <div class="billing-adress" id="open2" v-if="dashboard_box == 'step-2'">
                                    <div class="contact-form form-style">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-12 col-12">
                                                <label for="fname1">Fullname</label>
                                                <input class="form-control mb-25" type="hidden" v-model="userAddress.add_type" placeholder="" value="shipping">
                                                <input class="form-control mb-25" type="text" v-model="userAddress.add_title" placeholder="" id="fname1" name="Fullname">
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-12">
                                                <label for="fname2">Mobile</label>
                                                <input class="form-control mb-25" type="text" placeholder="" v-model="userAddress.mobile_no" id="mobile" name="Number">
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-12">
                                                <label for="fname2">Email</label>
                                                <input class="form-control mb-25" type="text" placeholder="" v-model="userAddress.email" id="email" name="email">
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-12">
                                                <label for="Country">Country</label>
                                                <select name="address" v-model="userAddress.country" id="Country" class="form-control">
                                                    <option value="india">India</option>
                                                </select>
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-12">
                                                <label for="City">State</label>
                                                <input class="form-control mb-25" type="text" placeholder="" v-model="userAddress.c_state" id="state" name="state">
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-12">
                                                <label for="City">City</label>

                                                <input class="form-control mb-25" type="text" placeholder="" v-model="userAddress.city" id="City" name="City">
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-12">
                                                <label for="City">Address Line 1</label>
                                                <input class="form-control mb-25" type="text" placeholder="" v-model="userAddress.add1" id="Address line 1" name="Address line 1">
                                            </div>

                                            <div class="col-lg-6 col-md-12 col-12">
                                                <label for="Adress">Address Line 2</label>
                                                <input class="form-control mb-25" type="text" placeholder="" id="Address line 2" v-model="userAddress.add2" name="Address line 2">
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-12">
                                                <label for="Post2">Pin Code</label>
                                                <input class="form-control mb-25" type="text" placeholder="" v-model="userAddress.zipcode" id="Post2" name="Post">
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12" style="margin-bottom: 20px;align-items: center;display: flex;">
                                                <input class="form-control mb-25" type="checkbox" placeholder="" style="width: 5%;height: 26px;margin: 15px 5px;" v-model="userAddress.shippingBilling" id="sel" name="sel">
                                                <label for="sel">Select Shipping address as billing address</label>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <button class="theme-btn" type="button" @click="switchLoc">Continue</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <!-- Billing Address -->
                        <div class="caupon-wrap s2" v-if="!userAddress.shippingBilling">
                            <div class="biling-item">
                                <div class="coupon coupon-3" @click="open_dashboard('step-5')" :class="dashboard_box == 'step-5' ? 'active' : ''">
                                    <label id="toggle2">Add Billing Address</label>
                                </div>
                                <div class="billing-adress" id="open2" v-if="dashboard_box == 'step-5'">
                                    <div class="contact-form form-style">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-12 col-12">
                                                <label for="fname1">Fullname</label>
                                                <input class="form-control mb-25" type="text" v-model="userAddressBilling.add_title" placeholder="" id="fname1" name="Fullname">
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-12">
                                                <label for="fname2">Mobile</label>
                                                <input class="form-control mb-25" type="text" placeholder="" v-model="userAddressBilling.mobile_no" id="mobile" name="Number">
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-12">
                                                <label for="fname2">Email</label>
                                                <input class="form-control mb-25" type="text" placeholder="" v-model="userAddressBilling.email" id="email" name="email">
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-12">
                                                <label for="Country">Country</label>
                                                <select name="address" v-model="userAddressBilling.country" id="Country" class="form-control">
                                                    <option value="india">India</option>
                                                </select>
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-12">
                                                <label for="City">State</label>
                                                <input class="form-control mb-25" type="text" placeholder="" v-model="userAddressBilling.c_state" id="state" name="state">
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-12">
                                                <label for="City">City</label>
                                                <input class="form-control mb-25" type="text" placeholder="" v-model="userAddressBilling.city" id="City" name="City">
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-12">
                                                <label for="City">Address Line 1</label>
                                                <input class="form-control mb-25" type="text" placeholder="" v-model="userAddressBilling.add1" id="Address line 1" name="Address line 1">
                                            </div>

                                            <div class="col-lg-6 col-md-12 col-12">
                                                <label for="Adress">Address Line 2</label>
                                                <input class="form-control mb-25" type="text" placeholder="" id="Address line 2" v-model="userAddressBilling.add2" name="Address line 2">
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-12">
                                                <label for="Post2">Pin Code</label>

                                                <input class="form-control mb-25" type="text" placeholder="" v-model="userAddressBilling.zipcode" id="Post2" name="Post">
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <button class="theme-btn" type="button" @click="switchLocBilling">Continue</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <!-- End Billing Address -->

                    </div>
                    <!-- End col-md-8 -->
                    <div class="col-md-5 space-30">
                        <div class="box">
                            <h3 class="title-brand">YOUR ORDER</h3>
                            <div class="info-order">
                                <div class="product-name">
                                    <ul>
                                        <li class="head">
                                            <span class="name">PRODUCTS NAME</span>
                                            <span class="qty"><b>QTY</b></span>
                                            <span class="total"><b>SUB TOTAL</b></span>
                                        </li>
                                        <li class="head content" v-for="cart in cartCount.data" :key="cart.id">
                                            <span class="name"> {{cart.name}} </span>
                                            <span class="qty ml-15"> {{cart.qty}} </span>
                                            <span class="total"> INR {{cart.selling_cost}} </span>
                                        </li>

                                    </ul>
                                </div>
                                <!-- End product-name -->
                                <ul class="product-order">
                                    <li>
                                        <span class="left">CART SUBTOTAL</span>
                                        <span class="right">INR {{cartCount.total_amount}} </span>
                                    </li>
                                    <li>
                                        <span class="left">SHIPPING & HANDLING</span>
                                        <span class="right">INR{{shipping}} </span>
                                    </li>
                                    <li>
                                        <span class="left">ORDER TOTAL</span>
                                        <span class="right bold"> INR {{cartCount.total_amount+shipping}} </span>
                                    </li>

                                </ul>
                            </div>
                            <!-- End info-order -->
                            <div class="payment-order box float-left">
                                <h3 class="title-brand">PAYMENT METHOD</h3>
                                <p> <i class="fa fa-shield text-success"></i> Safe and Secure Payments. Easy returns. 100% Authentic products.</p>

                                <ul class="tabs pd-0">

                                    <li class="d-flex">
                                        <input type="radio" id="paymenttype" name="paymenttype" value="2" v-model="paymentMethod" class="mr-15" />

                                        <h4>Online</h4>
                                    </li>
                                    <li class="d-flex">
                                        <input type="radio" id="paymenttype" name="paymenttype" value="1" v-model="paymentMethod" class="mr-15" />
                                        <h4>Pay On Delivery</h4>

                                    </li>
                                </ul>
                            </div>
                            <button class="theme-btn btn-style-four" type="button" name="submit-form" @click="orderGenerate"><span class="btn-title">PLACE ORDER</span>
                                <span></span> <span></span> <span></span> <span></span>
                                <span></span></button>
                            <!-- <a class="link-v1 box lh-50 rt full pointer" title="PLACE ORDER" @click="orderGenerate">PLACE ORDER</a> -->
                        </div>
                    </div>
                </div>
                <!-- End row -->
            </div>
            <!-- End container -->
        </div>

        <div class="container" v-if="NoRecordFound">
            <div class="row">
                <div class="col-md-12 text-center">
                    <h1>No Record Found</h1>
                </div>
            </div>
        </div>
        <!-- End About Us -->
        <!-- Main Footer -->
        <Footer />
        <!-- End Main Footer -->
    </div>

</body>
</template>

<script>
import axios from "axios";
import Header from "@/components/Header_3.vue";
import Footer from "@/components/Footer.vue";
// import {
//     ValidationProvider,
//     ValidationObserver
// } from "vee-validate";

export default {
    props: ["catedatas"],
    components: {
        Header,
        Footer,
        // ValidationProvider,
        // ValidationObserver,
    },
    data() {
        return {
            NoRecordFound: false,
            FinalTotalAmount: 0,
            add_id: 0,
            cgstLess: 0,
            sgstLess: 0,
            withTextAmount: 0,
            withoutTextAmount: 0,
            totalwithoutgst: 0,
            ttotalwithoutgst: 0,
            billing_id: 0,
            paymentMethod: 2,
            giftCat: localStorage.getItem("giftCat") ? localStorage.getItem("giftCat") : 0,
            couponCode: "",
            getCounts: "",
            content: "",
            myCouponMoney: localStorage.getItem("coupon") ?
                localStorage.getItem("coupon") : 0,
            isHas: false,
            isAdd: 0,
            shipping: 0,
            min: 0,
            thisImg: '',
            giftData: localStorage.getItem("giftData") ? JSON.parse(localStorage.getItem("giftData")) : [],
            dashboard_box: "step-1",
            ip_address: JSON.parse(localStorage.getItem("ip_address")),
            uid: localStorage.getItem("uid"),
            userData: [],
            cartCount: [],
            amount: 100,
            addressDatas: [],
            paymenttype: "ppd",
            awbNumber: "",
            selectedAddress: [],
            getShippingAddressData: [],
            getBillingAddressData: [],
            selectedBillingAddress: [],
            edate: '',
            usercomment: '',
            preview: 'gift.jpeg',
            previewImgs: [],
            image: null,
            userAddress: {
                add_title: "",
                mobile_no: "",
                add1: "",
                add2: "",
                c_state: "",
                city: "",
                zipcode: "",
                add_type: "Shipping",
                shippingBilling: false,
            },
            userAddressBilling: {
                add_title: "",
                mobile_no: "",
                add1: "",
                add2: "",
                c_state: "",
                city: "",
                zipcode: "",
                add_type: "",
            },
        };
    },
    watch: {
        add_id: function (id, oldId) {
            if (this.add_id > 0 && this.add_id != oldId) {
                this.getSelectedAddress(id);
                // if(this.userAddress.shippingBilling==true){
                //      this.billing_id = this.add_id
                // }
            }
        },
        // giftData: function (newVal, oldVal) {
        //     if (oldVal.id != newVal.id) {
        //         this.giftData = newVal
        //     }
        // },
        billing_id: function (id, oldId) {
            if (this.billing_id > 0 && this.billing_id != oldId) {
                this.getSelectedBillingAddress(id);
            }
        },
        // userAddress: function(id){
        //     if(this.userAddress.shippingBilling==true){
        //         this.billing_id = this.add_id
        //     }
        // },
        //         selectedAddress: function(){
        //  if (this.add_id > 0) {
        //             this.getSelectedAddress(this.add_id);
        //         }
        //         },
        cartCount: function () {
            if (this.cartCount.count == 0) {
                this.$toasted.global.error({
                    message: "Your cart is empty please add some product",
                });
                this.NoRecordFound = true
            }
        },
    },
    mounted() {
        setTimeout(() => {
            window.scrollTo(0,0);
           
        }, 1000);
        
        this.includeStripe("checkout.razorpay.com/v1/checkout.js");
        this.uid = localStorage.getItem("uid");
        (this.ip_address = JSON.parse(localStorage.getItem("ip_address"))),
        this.userDetail();
        this.getAddress();
        this.getState();
        var ev = this;
        if (this.add_id > 0) {
            this.getSelectedAddress(this.add_id);
        }
        setTimeout(function () {
            ev.getSelectedAddress(this.add_id);
            // ev.getSelectedBillingAddress(this.billing_id);
        }, 2000);
        this.goToTop();
        this.getCart();
        this.shippingCharge();
        // this.checkfirstSigup();
        this.getShippingAddress();
        this.getBillingAddress();
    },
    methods: {
        previewImage: function (event, id) {
            var input = event.target;
            if (input.files) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.previewImgs[id] = e.target.result;
                }
                this.image = input.files[0];
                reader.readAsDataURL(input.files[0]);
            }
        },
        giftContent() {
            var ev = this
            this.file = this.$refs.giftImage.files[0];

            if (this.content == "") {
                this.$toasted.global.error({
                    message: "Please Enter Content",
                });
            } else if (this.file == undefined) {
                this.$toasted.global.error({
                    message: "Please Select Image",
                });
            } else {
                console.log(this.file);
                let formData = new FormData();
                formData.append('giftImage', this.file);
                formData.append('content', this.content);
                formData.append('uid', this.uid);
                formData.append('type', 'giftContent');
                axios({
                    method: "POST",
                    url: this.$root.URL_ROOT + "api.php",
                    data: formData,
                }).then(function (response) {
                    var data = response.data;
                    if (data.status == 'success') {
                        ev.giftData = data.data
                        localStorage.setItem("giftData", JSON.stringify(data.data));
                        this.$toasted.global.success({
                            message: "Save Successfully",
                        });

                    } else {
                        this.$toasted.global.error({
                            message: "Somthing went wrong",
                        });
                    }
                });
            }

        },
        checkBillingShipping() {
            if (this.userAddress.shippingBilling == true) {
                this.billing_id = this.add_id;
            }
        },
        getShippingAddress() {
            var user_session = JSON.parse(localStorage.getItem("uid"));
            var uid = "";
            if (user_session != null) {
                uid = user_session;
            } else {
                uid = null;
            }
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "getShippingAddress",
                    uid: uid,
                },
            }).then(function (response) {
                // console.log('getShippingAddressData',response);
                var data = response.data;
                ev.add_id = data[0].add_id;
                ev.getShippingAddressData = data;
                ev.dashboard_box = "step-1"
            });
        },
        getBillingAddress() {
            var user_session = JSON.parse(localStorage.getItem("uid"));
            var uid = "";
            if (user_session != null) {
                uid = user_session;
            } else {
                uid = null;
            }
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "getBillingAddress",
                    uid: uid,
                },
            }).then(function (response) {
                var data = response.data;
                // ev.add_id = ev.addressDatas[0].add_id
                if (data.length) {
                    ev.billing_id = data[0].add_id;
                    ev.getBillingAddressData = data;
                }

            });
        },
        getSelectedBillingAddress(id) {
            if (id == 0) {} else {
                var user_session = JSON.parse(localStorage.getItem("uid"));
                var uid = "";
                if (user_session != null) {
                    uid = user_session;
                } else {
                    uid = null;
                }
                var ev = this;
                axios({
                    method: "POST",
                    url: this.$root.URL_ROOT + "api.php",
                    data: {
                        type: "getSelectedBillingAddress",
                        uid: uid,
                        aid: id,
                    },
                }).then(function (response) {
                    var data = response.data;
                    ev.selectedBillingAddress = data;
                    ev.billing_id = data.add_id;
                });
            }
        },
        getSelectedAddress() {
            if (this.add_id == 0) {} else {
                var user_session = JSON.parse(localStorage.getItem("uid"));
                var uid = "";
                if (user_session != null) {
                    uid = user_session;
                } else {
                    uid = null;
                }
                var ev = this;
                axios({
                    method: "POST",
                    url: this.$root.URL_ROOT + "api.php",
                    data: {
                        type: "getSelectedAddress",
                        uid: uid,
                        aid: ev.add_id,
                    },
                }).then(function (response) {
                    var data = response.data;
                    ev.selectedAddress = data;
                    ev.add_id = data.add_id;
                });
            }
        },
        checkfirstSigup() {
            var self = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "checkFirstSignup",
                    uid: self.uid,
                },
            }).then(function (response) {
                var data = response.data;
                if (data.data) {} else {
                    self.couponCode = "FIRST";
                    // self.applyCoupon();
                }
            });
        },
        getCount(id) {
            var count = "";
            var data = count.push(id);
            this.getCounts = data;
        },
        applyCoupon() {
            var self = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "couponapply",
                    coupon: self.couponCode,
                    uid: self.uid,
                },
            }).then(function (response) {
                var data = response.data;
                if (data.status == "success") {
                    self.myCouponMoney = data.data;
                    localStorage.setItem("coupon", data.data);
                    localStorage.setItem("coupon_name", self.couponCode);
                    self.$toasted.global.success({
                        message: data.msg,
                    });
                } else {
                    self.$toasted.global.error({
                        message: data.msg,
                    });
                }
            });
        },
        switchLoc() {
            var iv = this;
            // var vali = this.$refs.form.validate();
            // vali.then((val) => {
            //     if (val == true) {
            iv.save_address();
            // }
            // });
        },
        switchLocBilling() {
            var iv = this;
            // var vali = this.$refs.formBilling.validate();
            // vali.then((val) => {
            //     if (val == true) {
            iv.save_billing_address();
            // }
            // });
        },
        add_select() {
            if (this.add_id > 0) {
                // this.dashboard_box = "step-3";
            } else {
                this.$toasted.global.error({
                    message: "Please select address ",
                });
            }
        },
        includeStripe(URL, callback) {
            let documentTag = document,
                tag = "script",
                object = documentTag.createElement(tag),
                scriptTag = documentTag.getElementsByTagName(tag)[0];
            object.src = "https://" + URL;
            if (callback) {
                object.addEventListener(
                    "load",
                    function (e) {
                        callback(null, e);
                    },
                    false
                );
            }
            scriptTag.parentNode.insertBefore(object, scriptTag);
        },
        makePayment: function (ev) {
            if (this.add_id == 0) {
                this.$toasted.global.error({
                    message: "Please add address"
                });
            } else {
                var ev = this;
                var getcouponMoney = parseFloat(this.myCouponMoney);
                if (ev.min > this.cartCount.total_amount) {
                    this.shipping = 0;
                }
                var t = this.cartCount.total_amount + this.shipping;
                var amount = (t).toFixed(2);
                //console.log(amount*100);
                var pay_amount = (amount * 100).toFixed(2);
                console.log(pay_amount)
                var rzp1 = new Razorpay({
                    key: "rzp_test_1U8yAuzPHDQxVK',",
                    amount: pay_amount,
                    currency: "INR",
                    handler: function (response) {
                        ev.pay_id = response.razorpay_payment_id;
                        localStorage.setItem("successData", response);
                        ev.updatePayment(response);
                    },
                    ondismiss: function () {
                        window.location.reload();
                    },
                    prefill: {},
                    notes: {
                        // address: this.address, //this.$store.state.profile.address
                    },
                    theme: {
                        color: "#00FFFF",
                    },
                    //order_id: 1,
                    redirect: false,
                });
                rzp1.open();
            }
        },
        updatePayment(payment_detail) {
            var self = this;
            var oid = localStorage.getItem("oid");
            var uid = localStorage.getItem("uid");
            var coupon_name = localStorage.getItem("coupon_name");
            var couponMoney = localStorage.getItem("coupon");
            axios
                .post(this.$root.URL_ROOT + "api.php", {
                    type: "payment_success",
                    uid: uid,
                    oid: oid,
                    payment_detail: payment_detail,
                    coupon_name: coupon_name,
                    coupon: couponMoney,
                })
                .then(function (response) {
                    var data = response.data;
                    if (data.status == "success") {
                        if (self.paymentMethod == 1) {
                            self.$router.push("/success_cod");
                        } else {
                            self.$router.push("/success");
                        }
                    }
                });
        },
        goToTop() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        open_dashboard(vl) {
            this.dashboard_box = vl;
            window.scrollBy(100, 0);
        },
        open_dashboard2() {
            if (this.dashboard_box == "step-1") {
                // this.dashboard_box = "step-3";
            } else {
                this.dashboard_box = "step-1";
            }
        },
        userDetail() {
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "userDetail",
                    uid: ev.uid,
                },
            }).then(function (response) {
                var data = response.data;
                if (data.data) {
                    ev.userData = data.data;
                } else {
                    ev.logout();
                    ev.$toasted.global.error({
                        message: "Your Account is Deactivated",
                    });
                    setTimeout(function () {
                        ev.$router.push({
                            name: "Home",
                        });
                    }, 1000);
                }
            });
        },
        orderGenerate() {
            if (!this.add_id) {
                this.$toasted.global.error({
                    message: "Please add your address",
                });
            } else {
                var ev = this;
                var paymentType = "Online";
                if (this.paymentMethod == 1) {
                    paymentType = "COD";
                }
                var user_session = localStorage.getItem("uid");
                var uid = "";
                if (user_session != null) {
                    uid = user_session;
                } else {
                    uid = null;
                }
                var gid = 0;
                if (ev.giftData.id > 0) {
                    gid = ev.giftData.id;
                } else {
                    gid = 0;
                }
                var amount = parseFloat(this.cartCount.total_amount + this.shipping)
                var shipping = this.shipping;
                axios({
                    method: "POST",
                    url: this.$root.URL_ROOT + "api.php",
                    data: {
                        type: "order",
                        uid: uid,
                        address_id: ev.add_id,
                        billing_id: ev.billing_id,
                        gid: gid,
                        total: amount,
                        payment_type: paymentType,
                        shipping_amount: shipping,
                    },
                }).then(function (response) {
                    var data = response.data;
                    if (data.status == "success") {
                        localStorage.setItem("oid", data.oid);
                        ev.isAdd = data.add_id;
                        if (ev.paymentMethod == 2) {
                            ev.makePayment();
                        } else {
                            ev.updatePayment();
                        }
                        ev.getAddress();
                    }
                });
            }

        },
        getAddress() {
            var ip_address = JSON.parse(localStorage.getItem("ip_address"));
            var user_session = JSON.parse(localStorage.getItem("uid"));
            var uid = "";
            if (user_session != null) {
                uid = user_session;
            } else {
                uid = null;
            }
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "getAllAddress",
                    uid: uid,
                    ip_address: ip_address,
                },
            }).then(function (response) {
                var data = response.data;
                if (data.status == "success") {
                    ev.addressDatas = data.data;
                    if (ev.addressDatas.length == 0) {
                        ev.dashboard_box = "step-2";
                    }
                    // ev.add_id = ev.addressDatas[0].add_id
                    // ev.billing_id = ev.addressDatas[0].add_id
                    ev.getSelectedAddress(ev.addressDatas[0].add_id);
                }
            });
        },
        save_address() {
            var ev = this;
            if (this.userAddress.add_title == "") {
                ev.$toasted.global.error({
                    message: "please enter FullName ",
                });
            } else if (this.userAddress.mobile_no == "") {
                ev.$toasted.global.error({
                    message: "please enter mobile number ",
                });
            } else if (this.userAddress.email == "") {
                ev.$toasted.global.error({
                    message: "please enter Email ",
                });
            } else if (this.userAddress.add1 == "") {
                ev.$toasted.global.error({
                    message: "please enter address 1 ",
                });
            } else if (this.userAddress.city == "") {
                ev.$toasted.global.error({
                    message: "please enter city ",
                });
            } else if (this.userAddress.c_state == "") {
                ev.$toasted.global.error({
                    message: "please select state ",
                });
            } else if (this.userAddress.add1 == "") {
                ev.$toasted.global.error({
                    message: "please Enter address line 1 ",
                });
            } else if (this.userAddress.add2 == "") {
                ev.$toasted.global.error({
                    message: "please Enter address line 2",
                });
            } else if (this.userAddress.zipcode == "") {
                ev.$toasted.global.error({
                    message: "please Enter zipcode",
                });
            } else {
                var user_session = localStorage.getItem("uid");
                var uid = "";
                if (user_session != null) {
                    uid = user_session;
                } else {
                    uid = null;
                }
                if (ev.userAddress.shippingBilling == true) {
                    ev.userAddress.add_type = "Both";
                }
                axios({
                    method: "POST",
                    url: this.$root.URL_ROOT + "api.php",
                    data: {
                        type: "add_address",
                        uid: uid,
                        add_title: ev.userAddress.add_title,
                        city: ev.userAddress.city,
                        email: ev.userAddress.email,
                        mobile: ev.userAddress.mobile_no,
                        add1: ev.userAddress.add1,
                        add2: ev.userAddress.add2,
                        pincode: ev.userAddress.zipcode,
                        state: ev.userAddress.c_state,
                        country: ev.userAddress.country,
                        add_type: ev.userAddress.add_type,
                    },
                }).then(function (response) {
                    var data = response.data;
                    if (data.status == "success") {
                        ev.$toasted.global.success({
                            message: data.msg,
                        });
                        ev.getShippingAddress();
                        ev.getBillingAddress();
                        ev.add_id = data.add_id;
                        ev.isAdd = data.add_id;
                        // ev.open_dashboard("step-3");
                        // ev.getAddress();
                    }
                });
            }
        },
        save_billing_address() {
            var ev = this;
            if (this.userAddressBilling.add_title == "") {
                ev.$toasted.global.error({
                    message: "please enter FullName ",
                });
            } else if (this.userAddressBilling.mobile_no == "") {
                ev.$toasted.global.error({
                    message: "please enter mobile number ",
                });
            } else if (this.userAddressBilling.email == "") {
                ev.$toasted.global.error({
                    message: "please enter Email ",
                });
            } else if (this.userAddressBilling.add1 == "") {
                ev.$toasted.global.error({
                    message: "please enter address 1 ",
                });
            } else if (this.userAddressBilling.city == "") {
                ev.$toasted.global.error({
                    message: "please enter city ",
                });
            } else if (this.userAddressBilling.c_state == "") {
                ev.$toasted.global.error({
                    message: "please select state ",
                });
            } else if (this.userAddressBilling.add1 == "") {
                ev.$toasted.global.error({
                    message: "please Enter address line 1 ",
                });
            } else if (this.userAddressBilling.add2 == "") {
                ev.$toasted.global.error({
                    message: "please Enter address line 2",
                });
            } else if (this.userAddressBilling.zipcode == "") {
                ev.$toasted.global.error({
                    message: "please Enter zipcode",
                });
            } else {
                var user_session = localStorage.getItem("uid");
                var uid = "";
                if (user_session != null) {
                    uid = user_session;
                } else {
                    uid = null;
                }

                axios({
                    method: "POST",
                    url: this.$root.URL_ROOT + "api.php",
                    data: {
                        type: "add_address",
                        uid: uid,
                        add_title: ev.userAddressBilling.add_title,
                        city: ev.userAddressBilling.city,
                        email: ev.userAddressBilling.email,
                        mobile: ev.userAddressBilling.mobile_no,
                        add1: ev.userAddressBilling.add1,
                        add2: ev.userAddressBilling.add2,
                        pincode: ev.userAddressBilling.zipcode,
                        state: ev.userAddressBilling.c_state,
                        country: ev.userAddressBilling.country,
                        add_type: "Billing",
                    },
                }).then(function (response) {
                    var data = response.data;
                    if (data.status == "success") {
                        ev.$toasted.global.success({
                            message: data.msg,
                        });
                        ev.getShippingAddress();
                        ev.getBillingAddress();
                        ev.add_id = data.add_id;
                        ev.isAdd = data.add_id;
                        // ev.open_dashboard("step-3");
                        ev.getAddress();
                    }
                });
            }
        },
        getState() {
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "getState",
                },
            }).then(function (response) {
                var data = response.data;
                ev.stateData = data;
            });
        },
        checked_add(id) {
            this.aid = id;
            this.getSelectedAddress();
            this.address_box = true;
        },
    },
};
</script>

<style scoped>
.active {
    transform: unset;
}

.cart-box-container.check-out {
    margin: 75px auto;
}

.caupon-wrap.s2 {
    font-size: 22px;
    background: #f1f1f1;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
    border-radius: 10px;
}

.request-form .form-group input[type="email"],
input[type="text"],
input[type="number"],
input[type="password"],
select {
    background-color: rgb(255 255 255) !important;
    color: #000 !important;
}

.contact-form {
    background: unset;
}

.coupon:before {
    position: absolute;
    right: 30px;
    top: 21px;
    font-size: 26px;
    content: "\f044";
    font-family: FontAwesome;
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 18px !important;
}

.box {
    display: inline-block;
    width: 100%;
}

.title-brand {
    color: #222;
    font: 700 30px Poppins;
    margin-bottom: 15px;
}

.info-order .product-order {
    margin-bottom: 30px;
    padding-bottom: 30px;
    width: 100%;
    float: left;
    padding-left: 0;
}

.info-order .product-order li {
    border-top: 1px solid #e6e6e6;
    display: block;
    float: left;
    width: 100%;
    line-height: 50px;
}

.payment-order ul.tabs li {
    padding: 20px 0 20px 20px;
    list-style: none;
    position: relative;
    align-items: center;
}

.mr-15 {
    margin: 15px;
}

.d-flex {
    display: flex;
}

.payment-order ul.tabs li h4 {
    font: 600 18px/20px Poppins;
    color: #000;
}

.info-order .product-name {
    text-align: left;
}

.info-order .product-name ul .head {
    border-bottom: 1px solid #e6e6e6;
}

.info-order .product-name ul .head .name {
    font: 700 16px/50px Poppins;
    color: #565656;
    text-transform: uppercase;
    float: left;
    width: 300px;
}

.info-order .product-name ul .head .qty {
    font: 400 16px/50px Poppins;
    color: #565656;
}

.info-order .product-name ul .head .total {
    font: 400 16px/50px Poppins;
    float: right;
    color: #565656;
    text-align: right;
}

.info-order .product-name ul .head.content .name {
    font: normal 16px/50px Poppins;
}

.info-order .product-name .name,
.info-order .product-name .qty,
.info-order .product-name .total {
    font: 400 16px/50px Poppins;
    color: #a7a7a7;
}

.info-order .product-name .name,
.info-order .product-name .qty,
.info-order .product-name .total {
    font: 400 16px/50px Poppins;
    color: #a7a7a7;
}

.info-order .product-name .total {
    float: right;
    text-align: right;
}

.info-order .product-order {
    margin-bottom: 30px;
    padding-bottom: 30px;
    width: 100%;
    float: left;
    padding-left: 0;
}

.info-order .product-order .left {
    font: 700 16px Poppins;
    color: #565656;
    text-transform: uppercase;
    float: left;
    line-height: 50px;
}

.info-order .product-order .right {
    float: right;
    font: 400 16px/50px Poppins;
    text-transform: uppercase;
}

.ml-15 {
    margin-left: 15px;
}

.fa-check:before {
    color: green;
}

.col-md-12.foundation.card {
    background: #fff;
}
</style>
