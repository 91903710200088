<template>
<body class="style-simple button-stroke layout-full-width if-overlay if-border-hide no-content-padding no-shadows header-classic minimalist-header-no sticky-tb-color ab-hide subheader-both-center menu-line-below-80-1 menuo-sub-active menuo-no-borders menuo-right logo-valign-top logo-no-margin mobile-tb-hide mobile-side-slide mobile-mini-mr-ll mobile-tr-header tr-header tr-menu">
    <div id="Wrapper">
        <div id="Header_wrapper">
            <Header />
        </div>
        <!--Page Title-->
        <section class="page-title top-bg-img">
            <div class="auto-container clearfix">
                <h1>Contact</h1>
                <ul class="bread-crumb clearfix">
                    <li><router-link to="/">Home</router-link></li>
                    <li>Contact</li>
                </ul>
            </div>
        </section>
        <!--End Page Title-->

        <!-- About Us -->
        <section class="about-us container">

            <!-- Call Back Section -->
            <section class="call-back-section contact-bg-img" style="">
                <div class="auto-container">
                    <div class="outer-box">
                        <div class="row no-gutters">
                            <!-- Text Column -->
                            <div class="text-column col-xl-8 col-lg-12 col-md-12 col-sm-12 order-2">
                                <div class="inner-column">
                                    <div class="inner">
                                        <div class="message"><strong>20 Years of Experience </strong><br> in Woodworks
                                            Business
                                            Services</div>
                                        <div class="text"> send us a email and we’ll get in touch shortly, or phone between 8:00AM to 9:00PM Monday to sunday- We would be delighted to speak.</div>

                                        <div class="row">
                                            <!-- Info Block -->
                                            <div class="info-block col-lg-6 col-md-6 col-sm-12">
                                                <div class="inner-box">
                                                    <div class="icon flaticon-phone"></div>
                                                    <ul>
                                                        <li><strong>Phone</strong></li>
                                                        <li><a href="#">{{settingData.mobile}}</a></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <!-- Info Block -->
                                            <div class="info-block col-lg-6 col-md-6 col-sm-12">
                                                <div class="inner-box">
                                                    <div class="icon flaticon-email"></div>
                                                    <ul>
                                                        <li><strong>Email</strong></li>
                                                        <li><a :href="'mailto:'+settingData.email">{{settingData.email}}</a></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <!-- Info Block -->
                                            <div class="info-block col-lg-12 col-md-12 col-sm-12">
                                                <div class="inner-box">
                                                    <div class="icon flaticon-worldwide"></div>
                                                    <ul>
                                                        <li><strong>Location</strong></li>
                                                        <li>{{settingData.address}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Form Column -->
                            <div class="form-column col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                <div class="inner-column">
                                    <div class="request-form">
                                        <h3>Request A Quote</h3>

                                        <!--Comment Form-->
                                        
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <input type="text" name="username" v-model="name" placeholder="Name" required="">
                                                </div>

                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <input type="email" name="email" v-model="email" placeholder="Email" required="">
                                                </div>

                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <input type="text" name="phone" v-model="mobile" placeholder="Phone" required="">
                                                </div>

                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <textarea name="message" v-model="msg" placeholder="Message"></textarea>
                                                </div>

                                                <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                    <button class="theme-btn btn-style-four" type="submit" @click="saveContact" name="submit-form">
                                                    <span class="btn-title">Submit Now</span>
                                                        <span></span> <span></span> <span></span> <span></span>
                                                        <span></span></button>
                                                </div>
                                            </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!--End Call Back Section -->

        </section>
        <!-- End About Us -->

        <!-- Main Footer -->
        <Footer />
        <!-- End Main Footer -->
    </div>

</body>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header_3.vue";
import Footer from "@/components/Footer.vue";
import axios from 'axios';

import {
    Carousel,
    Slide
} from 'vue-carousel';

export default {
    name: "AboutUsView",
    components: {
        Header,
        Footer,
        Carousel,
        Slide
    },
    data() {
        return {
            testimonialData: [],
            settingData:[],
            name:null,
            email:null,
            mobile:null,
            msg:null,

        }
    },
    mounted() {
        this.getTestimonial();
        this.getSetting();
        setTimeout(() => {
            window.scrollTo(0,0);
           
        }, 1000);
        
    },
    methods: {

        getTestimonial() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'testimonial',
                }
            }).then(function (response) {
                console.log(response.data);
                var data = response.data;
                ev.testimonialData = data.data;

            })

        },
        getSetting(){
            var ev=this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT+'api.php',
                data: {
                    type: 'site_setting',
                }
            }).then(function (response){
                console.log(response.data);
                var data=response.data;
              
                    ev.settingData=data
                    console.log(ev.settingData);
                    
                
            })
        },
        saveContact(){
            var ev=this;
            if(this.name==null || this.name==''){
                ev.$toasted.global.error({
                        message: 'PLease enter your name'
                });
            }else if(this.email==null || this.email==''){
                ev.$toasted.global.error({
                        message: 'PLease enter your email'
                });
            }else if(this.mobile==null || this.mobile==''){
                ev.$toasted.global.error({
                        message: 'PLease enter your mobile no.'
                });
            }else if(this.msg==null || this.msg==''){
                ev.$toasted.global.error({
                        message: 'PLease enter message'
                });
            }else{
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT+'api.php',
                    data: {
                        type: 'contact_submit',
                        name: this.name,
                        email: this.email,
                        mobile: this.mobile,
                        msg: this.msg,
                    }
                }).then(function (response){
                    console.log(response.data);
                    var data=response.data;
                    if(data.status=='success'){
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.name=null;
                        ev.email=null;
                        ev.mobile=null;
                        ev.msg=null;
                    }else{
                        ev.$toasted.global.error({
                            message: data.msg
                        });
                    }
                })
            }    
        }
    }

};
</script>
