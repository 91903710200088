<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
 require("./assets/css3/structure.css")
 require("./assets/css3/global.css")
 require("./assets/css3/carpenter2.css")
 require("./assets/css2/global.css")
 require("./assets/css/carpenter2.css")
 require("./assets/css/bootstrap.min.css")
 require("./assets/css/style.css")
 require("./assets/css/slick.css")
 require("./assets/css/product-style.css")
 require("./assets/css/settings.css")
 require("./assets/css/font-awesome.css")
 require("./assets/css/owl.corousel.min.css")
 require("./assets/css/owl.theme.css")
 require("./assets/css/style-inner.css")
 require("./assets/css/slick-theme.css")
 require("./assets/css/structure.css")
//  require("./assets/js/jquery.js")
//  //require("./assets/js/owl.js")
</script>
<style>
.input-group {
    flex-wrap: unset; 
}
.input-quentity {
  color:#000 !important;
  padding:0px !important;
}
.info-block .inner-box{
  height:150px;
  margin-bottom: 15px;
}
    .header-menu {
      justify-content: center;
    }


    @media only screen and (max-width: 600px) {
      .header-menu {
       
        height: 10px !important;
        display: inline-grid !important; 
        align-items: center;
      }
      .sub-menu li {
        width: 100% !important;
      }
      .header-menu li a{
        font-size: 14px !important;
      }
      .container-ver2{
        max-width: unset !important;
        width: 100% !important;
      }
      .zomm-img-div{
        width: 80% !important; 
        margin-right: 0px; float: left; display: block;
      }
    }
    .zomm-img-div{
        width: 468px; 
        margin-right: 0px; float: left; display: block;
      }
</style>
